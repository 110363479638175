import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { ImageAsset, TextEllipsis } from '@/components/common'
import { GlobalColorEnum } from '@/constants/global-color.enum'

type SelectBoxProps = {
  onClick: () => void
  value?: string
  placeholder?: string
}

const SelectBox: FC<SelectBoxProps> = ({ onClick, value, placeholder }) => {
  return (
    <StyledSection
      justify="space-between"
      align="middle"
      className="button"
      wrap={false}
      onClick={() => {
        setTimeout(() => {
          onClick()
        }, 150)
      }}
    >
      <Col>
        <TextEllipsis lineNumber={1} color={GlobalColorEnum.Gray700} size={14}>
          {value || placeholder}
        </TextEllipsis>
      </Col>
      <Col>
        <ImageAsset name="ic_chevron_down_gray700_24" size={24} svg />
      </Col>
    </StyledSection>
  )
}

const StyledSection = styled(Row)`
  height: 50px;
  border: 1px solid ${GlobalColorEnum.Gray100};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  background-color: ${(props) => props.theme.color.grayWhite};
`

export default SelectBox
