import { FC, useMemo } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { TextLegacy, TextEllipsis } from '@/components/common'
import { PlainBorderButtonLegacy } from '@/components/common/buttons'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { useCustomRouter } from '@/containers/hooks'
import { IModelCustomerTemplateAddress } from '@/containers/models/modelCustomerTemplateAddress'
import { formatPhoneNumber } from '@/utils/utilString'

type ListItemDeliveryAddressProps = {
  address: IModelCustomerTemplateAddress
  updateRoute: string
  selected?: boolean
  onSelect?: (address: IModelCustomerTemplateAddress) => void
}

const ListItemDeliveryAddress: FC<ListItemDeliveryAddressProps> = ({
  address,
  updateRoute,
  selected,
  onSelect = () => {},
}) => {
  const { push } = useCustomRouter()
  const { id, name, basicAddress, detailedAddress, mobileNumber, isDefault } = address

  const ableToSelect = useMemo(() => typeof onSelect === 'function', [onSelect])
  const addressName = useMemo(() => (isDefault ? `${name} (기본배송지)` : name), [isDefault])
  const fullAddress = useMemo(
    () => (detailedAddress ? `${basicAddress} ${detailedAddress}` : basicAddress),
    [detailedAddress]
  )

  const goUpdate = () => {
    push({
      pathname: updateRoute,
      query: {
        customerTemplateAddressId: id,
      },
    })
  }

  return (
    <StyledContainer
      selected={selected ? 1 : 0}
      className={ableToSelect ? 'button' : ''}
      onClick={() => {
        // eslint-disable-next-line no-unused-expressions
        ableToSelect && onSelect(address)
      }}
    >
      <Row align="middle">
        {ableToSelect && <SelectCol>{selected ? <SelectCircleSelected /> : <SelectCircle />}</SelectCol>}
        <Col flex="1">
          <div>
            <StyledRow>
              <TextLegacy size={{ xs: 16, md: 18 }} weight="bold">
                {addressName}
              </TextLegacy>
            </StyledRow>
            <StyledRow>
              <TextEllipsis lineNumber={2}>{fullAddress}</TextEllipsis>
            </StyledRow>
            <StyledRow>
              <TextLegacy>{formatPhoneNumber(mobileNumber)}</TextLegacy>
            </StyledRow>
          </div>

          <Row justify="end" style={{ zIndex: 1 }}>
            <Col>
              <PlainBorderButtonLegacy text="수정" onClick={goUpdate} />
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  )
}

const StyledContainer = styled.div<{ selected: number }>`
  background-color: ${(props) => props.theme.color.grayWhite};
  border-radius: 4px;
  /* border: 1px solid ${GlobalColorEnum.Gray70}; */
  border: ${({ selected }) =>
    selected ? `2px solid ${GlobalColorEnum.Blue500}` : `1px solid ${GlobalColorEnum.Gray70}`};
  margin-bottom: 12px;
  padding: 20px 12px 12px;
`

const SelectCol = styled(Col)`
  padding-right: 12px;
`

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`

const SelectCircle = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${GlobalColorEnum.Gray200};
  border-radius: 50%;
`

const SelectCircleSelected = styled.div`
  width: 20px;
  height: 20px;
  border: 6px solid ${GlobalColorEnum.Blue500};
  border-radius: 50%;
`

export default ListItemDeliveryAddress
