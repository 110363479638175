import ProductRecentlySliderWithAddToCart from '@/components/domains/search/ProductRecentlySlider'
import {
  PopularBrands,
  PopularKeywords,
  RecentSearchKeywords,
  RecommendKeywords,
} from '@/components/domains/search/index'
import type { GraphQLProductType } from '@/utils/product/product-card.util'

interface SearchBodyContentsProps {
  recentlyProducts: GraphQLProductType[]
}

export const SearchBodyContents = ({ recentlyProducts }: SearchBodyContentsProps) => {
  return (
    <>
      <section>
        <RecentSearchKeywords />
      </section>
      {!!recentlyProducts?.length && (
        <section>
          <ProductRecentlySliderWithAddToCart data={recentlyProducts} />
        </section>
      )}
      <section>
        <RecommendKeywords />
      </section>
      <section>
        <PopularKeywords />
      </section>
      <section>
        <PopularBrands />
      </section>
    </>
  )
}
