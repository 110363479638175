import React, { FC } from 'react'
import styled, { useTheme } from 'styled-components'
import { Typo, TypoVariant } from '@/components/common/typography'
import { LAYOUT_UNITS } from '@/constants/legacy/constLayout'
import { currencyText } from '@/utils/utilNumber'

type CartOrderButtonProps = {
  totalPaymentAmount?: number
  handleOrderClick: () => void
  checkedProductOptionsCount: number
}

const CartOrderButton: FC<CartOrderButtonProps> = ({
  totalPaymentAmount,
  handleOrderClick,
  checkedProductOptionsCount,
}) => {
  const { color } = useTheme()

  if (totalPaymentAmount === undefined) {
    return null
  }

  const buttonText =
    checkedProductOptionsCount === 0 ? '상품을 선택해 주세요' : `${currencyText(totalPaymentAmount)} 결제하기`

  return (
    <StyledWrapper>
      <div className="button-wrapper">
        <button
          type="button"
          className="button-wrapper__button"
          disabled={checkedProductOptionsCount === 0}
          onClick={handleOrderClick}
        >
          <Typo as="span" variant={TypoVariant.Body1Bold} color={color.grayWhite}>
            {buttonText}
          </Typo>
        </button>
      </div>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  padding-bottom: calc(env(safe-area-inset-bottom) + 10rem);
  background-color: ${({ theme }) => theme.color.grayWhite};

  .button-wrapper {
    z-index: 5;
    position: fixed;
    width: 100%;
    max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
    padding: 1.2rem 1.6rem calc(env(safe-area-inset-bottom) + 1.6rem);
    bottom: 0;
    background-color: ${({
      theme: {
        color: { grayWhite },
      },
    }) => grayWhite};
    border-top: 1px solid
      ${({
        theme: {
          color: { gray70 },
        },
      }) => gray70};
    &__empty-cart-text {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8rem;
      height: 2rem;
    }
    &__nudge-text {
      display: flex;
      justify-content: center;
      margin-bottom: 0.8rem;
      height: 2rem;
    }
    &__button {
      cursor: pointer;
      width: 100%;
      border-radius: 0.8rem;
      background-color: ${({
        theme: {
          color: { blue500 },
        },
      }) => blue500};
      border: none;
      padding: 1.3rem 1.6rem;
      text-align: center;
      z-index: 100;
      :disabled {
        background-color: ${({ theme }) => theme.color.gray200};
      }
    }
  }
`

export default CartOrderButton
