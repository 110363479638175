import { FC, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { FIXED_HEIGHT } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, usePetType } from '@/containers/hooks'
import useAppCouponDownload from '@/containers/hooks/useAppCouponDownload'
import { hasSignedIn } from '@/containers/hooks/useUserInfo'
import { UserContainer } from '@/containers/users/UserContainer'
import { MeFragment } from '@/graphql/generated/operations'
import { doSetIsShowTopSlimBanner } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'
import { AppStorageDataType, Bridge } from '@/utils/bridge/bridge'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import appBridgeProvider, { isAppTopSlimBannerClose } from '@/utils/utilBridge'
import { localPopupBannerHiddenDate } from '@/utils/utilLocalStorage'

enum BannerType {
  '100wonDeal' = '100wonDeal',
  appCoupon = 'appCoupon',
}

enum IsAppTopBannerCloseStateEnum {
  INITIAL = 'initial',
  OPENED = 'opened',
  CLOSED = 'closed',
}

const BANNER_INIT = {
  '100wonDeal': {
    textObjectList: [
      { id: 1, text: '핏펫이 처음이라면 ', color: '#FFFFFF' as const, variant: TypoVariant.Body3Bold },
      { id: 2, text: '웰컴딜 1원 ', color: '#FFD633' as const, variant: TypoVariant.Body3Bold },
      { id: 3, text: '부터~', color: '#FFFFFF' as const, variant: TypoVariant.Body3Bold },
    ],
    linkPath: ROUTES.EVENTS_100_DEAL,
    name: '100wondeal',
  },
  appCoupon: {
    textObjectList: [
      {
        id: 1,
        text: '매달 풍성한 할인, APP 쿠폰 다운받기!',
        color: '#FFFFFF' as const,
        variant: TypoVariant.Body3Bold,
      },
    ],
    linkPath: '/events/app-only',
    name: 'appcoupon',
  },
}

const BannerTopSlim: FC = () => {
  const tracker = useTracker()
  const { getPetType } = usePetType()
  const { pathname, ableToShowTopSlimBanner, basePath, asPath } = useCustomRouter()
  const { me } = UserContainer.useContainer()
  const dispatch = useDispatch()
  const isShowTopSlimBanner = useSelector((state: RootState) => state.reduxUIReducers.isShowTopSlimBanner)
  const [bannerType, setBannerType] = useState<BannerType>(BannerType['100wonDeal'])
  const [isInAppBannerClosed, setIsInAppBannerClosed] = useState(IsAppTopBannerCloseStateEnum.INITIAL) // isInAppBannerClosed가 initial 일땐 아무 동작도 하지 않음.
  const bannerRef = useRef<HTMLDivElement>(null)
  const isTrackedBanner = useRef(false)
  const currentPath = basePath + asPath
  const { isAppCouponDownload } = useAppCouponDownload() // TODO 유저정보 query 리덕스 저장 시 같이 호출 되어야함

  const showingBannerTracking = () => {
    if (bannerRef.current!.getBoundingClientRect().y === 0 && !isTrackedBanner.current) {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ViewHomeFloatingbannerTop, {
        path: pathname,
        banner_name: BANNER_INIT[bannerType].name,
        banner_text: BANNER_INIT[bannerType].textObjectList.map((textObject) => textObject.text).join(''),
      })
      isTrackedBanner.current = true
    }
  }

  const checkCanBuyDeal100 = (user: MeFragment): void => {
    if (user?.canBuyDeal100) {
      setBannerType(BannerType['100wonDeal'])
      return
    }

    setBannerType(BannerType.appCoupon)
  }

  const hideToday = () => {
    const today = moment().format('YYYY-MM-DD')
    localPopupBannerHiddenDate.save(today)
    // eslint-disable-next-line no-unused-expressions
    isAppTopSlimBannerClose &&
      appBridgeProvider((bridge: Bridge) => bridge.storageSetItem('topLineBannerCloseDate', today))
  }

  const ableToShowToday = () => {
    const hiddenDate = localPopupBannerHiddenDate.load()
    const today = moment().format('YYYY-MM-DD')
    return hiddenDate !== today
  }

  const isInAppClosedBanner = () => {
    /**
     * App에서 띠배너가 닫힌 날짜가 오늘 날짜와 같다면 isInAppBannerClosed를 true로 토글.
     * App 버전이 4.2.11 이하라면 아무 동작도 하지 않습니다.
     */
    if (isAppTopSlimBannerClose) {
      appBridgeProvider((bridge: Bridge) =>
        bridge.storageGetItem('topLineBannerCloseDate').then((data: AppStorageDataType<string | null>) => {
          if (!data.data) {
            setIsInAppBannerClosed(IsAppTopBannerCloseStateEnum.OPENED)
            return
          }

          const today = moment().format('YYYY-MM-DD')

          const topLineBannerCloseDate = data.data // 앱스토리지에 저장되는 띠배너를 닫은 날짜 -> format: 'YYYY-MM-DD' | null

          const topBannerStatus =
            today === topLineBannerCloseDate ? IsAppTopBannerCloseStateEnum.CLOSED : IsAppTopBannerCloseStateEnum.OPENED
          setIsInAppBannerClosed(topBannerStatus)
        })
      )
    } else {
      setIsInAppBannerClosed(IsAppTopBannerCloseStateEnum.OPENED)
    }
  }

  const close = () => {
    dispatch(doSetIsShowTopSlimBanner(false))
    hideToday()
  }

  const isAvailableBanner = () => {
    // 토큰이 있고 유저정보가 없으면 로딩중이므로 배너를 띄우지 않음
    const hasUserInfo = !(hasSignedIn() && !me)
    const usedWelcomeDeal = !me?.canBuyDeal100

    return (
      hasUserInfo &&
      ableToShowTopSlimBanner() &&
      ableToShowToday() &&
      isInAppBannerClosed === IsAppTopBannerCloseStateEnum.OPENED &&
      !(usedWelcomeDeal && isAppCouponDownload) // 100원딜 이용 유저는 앱쿠폰 배너를 띄우지만 앱쿠폰이 다운로드 된 상태라면 배너를 띄우지 않음
    )
  }

  const setTopSlimBannerState = () => {
    if (isAvailableBanner()) {
      showingBannerTracking()
      setTimeout(() => {
        dispatch(doSetIsShowTopSlimBanner(true))
      }, 500)
    } else {
      dispatch(doSetIsShowTopSlimBanner(false))
    }
  }

  useEffect(() => {
    isTrackedBanner.current = false
    if (me) {
      // 유저 정보 확인 후 canBuyDeal100 플래그에 따라 배너 유형 변경
      checkCanBuyDeal100(me)
    }
    setTopSlimBannerState()
  }, [pathname, isInAppBannerClosed, me])

  useEffect(() => {
    isInAppClosedBanner()
    return () => {
      dispatch(doSetIsShowTopSlimBanner(false))
    }
  }, [])

  return (
    <StyledContainer showtopbanner={isShowTopSlimBanner ? 1 : 0} ref={bannerRef}>
      <div className="top-banner-contents">
        <LinkButton
          url={{
            pathname: BANNER_INIT[bannerType].linkPath,
            query: {
              [QueryStringKeyEnum.PrevPath]: currentPath,
              ...(bannerType === BannerType['100wonDeal'] && {
                pagePetType: getPetType(),
              }),
            },
          }}
          onClick={() => {
            if (bannerType === 'appCoupon') {
              close()
            }
            tracker.triggerCustomEvent(FitpetMallEventEnum.ClickHomeFloatingbannerTop, {
              path: pathname,
              banner_name: BANNER_INIT[bannerType].name,
              banner_text: BANNER_INIT[bannerType].textObjectList.map((textObject) => textObject.text).join(''),
            })
          }}
        >
          {BANNER_INIT[bannerType].textObjectList.map((textObject) => (
            <Typo as="span" key={textObject.id} variant={textObject.variant} color={textObject.color}>
              {textObject.text}
            </Typo>
          ))}
        </LinkButton>
      </div>
      <CloseButton onClick={close}>
        <FlatIcon type={IconNameEnum.IcClose} color={GlobalColorEnum.Gray500} size={IconSizeEnum.Size20} />
      </CloseButton>
    </StyledContainer>
  )
}

const StyledContainer = styled.div<{ showtopbanner: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.gray900};
  height: ${({ showtopbanner }) => (showtopbanner ? `${FIXED_HEIGHT.TOP_SLIM_BANNER_HEIGHT / 10}rem` : '0')};
  padding: 0 1.6rem;
  transition: height 0.2s;
  -webkit-transition: height 0.2s;
  overflow: hidden;

  .top-banner-contents {
    flex: 1;
    text-align: center;
    a {
      display: flex;
      justify-content: center;
      gap: 0.4rem;
    }
  }
`

const CloseButton = styled.div`
  cursor: pointer;
`

export default BannerTopSlim
