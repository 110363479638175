import { createContext, CSSProperties, ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'
import { match } from 'ts-pattern'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import {
  CARD_BODY_PRICE_INFO_TYPES,
  CardBodyPriceInfoType,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { useCardComponentSeoHook } from '@/containers/hooks/seo/use-card-component-seo.hook'
import { currencyText, percentText } from '@/utils/utilNumber'

interface CardBodyHorizontalProps {
  children: ReactNode
}

interface PriceInfoProps {
  type: CardBodyPriceInfoType
  hasDiscount: boolean
  discountRate: number
  price: number
  salePrice: number
}

interface PriceInfoPropsWithoutType extends Omit<PriceInfoProps, 'type'> {}

const CardBodyHorizontalContext = createContext({} as {})

export const CardBodyHorizontal = ({ children }: CardBodyHorizontalProps) => {
  return (
    <CardBodyHorizontalContext.Provider value={{}}>
      <CardBodyHorizontalWrapper>{children}</CardBodyHorizontalWrapper>
    </CardBodyHorizontalContext.Provider>
  )
}

const CardBodyHorizontalWrapper = ({ children }: { children: ReactNode }) => {
  return <StyledCardBodyHorizontalContainer>{children}</StyledCardBodyHorizontalContainer>
}

const StyledCardBodyHorizontalContainer = styled.div`
  width: 100%;
`

const Name = ({ children }: { children: ReactNode }) => {
  const { isSeoH2TagByRoute } = useCardComponentSeoHook()
  const { color } = useTheme()

  return (
    <StyledNameWrapper>
      <Typo
        as={isSeoH2TagByRoute() ? 'h2' : 'h3'}
        variant="Body3 Regular"
        color={color.gray700}
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: 'break-all',
        }}
      >
        {children}
      </Typo>
    </StyledNameWrapper>
  )
}

const StyledNameWrapper = styled.div`
  display: flex;
  margin-bottom: 0.25rem;
`

const DefaultOriginalPrice = ({ children }: { children: ReactNode }) => {
  const { color } = useTheme()

  return (
    <StyledDefaultOriginalPriceWrapper variant={{ marginBottom: 0 }}>
      <Typo
        variant={TypoVariant.Body5Regular}
        color={color.gray300}
        style={{
          textDecoration: 'line-through',
        }}
      >
        {children}
      </Typo>
    </StyledDefaultOriginalPriceWrapper>
  )
}

const StyledDefaultOriginalPriceWrapper = styled.div<{ variant: CSSProperties }>`
  display: flex;
  margin-bottom: ${({ variant: { marginBottom } }) => marginBottom};
`

const DefaultDiscountInfo = ({ children }: { children: ReactNode }) => {
  return <StyledDefaultDiscountInfoWrapper variant={{}}>{children}</StyledDefaultDiscountInfoWrapper>
}

const StyledDefaultDiscountInfoWrapper = styled.div<{ variant: CSSProperties }>`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 0.2rem;
`

const DefaultDiscountRate = ({ children }: { children: ReactNode }) => {
  const { color } = useTheme()

  return (
    <Typo variant="Body1 Bold" color={color.red500}>
      {children}
    </Typo>
  )
}

const DefaultDiscountPrice = ({ children }: { children: ReactNode }) => {
  const { color } = useTheme()

  return (
    <Typo variant="Body1 Bold" color={color.gray900}>
      {children}
    </Typo>
  )
}

const DiscountedBulkPrice = ({ children }: { children: ReactNode }) => {
  const { color } = useTheme()

  return (
    <StyledDiscountedBulkPriceWrapper variant={{ marginBottom: '0.4rem' }}>
      <Typo variant="Body5 Regular" color={color.blue800}>
        {children}
      </Typo>
    </StyledDiscountedBulkPriceWrapper>
  )
}

const StyledDiscountedBulkPriceWrapper = styled.div<{ variant: CSSProperties }>`
  display: flex;
  margin-bottom: ${({ variant: { marginBottom } }) => marginBottom};
`

const ReviewInfo = ({ children }: { children: ReactNode }) => {
  return <StyledReviewInfoWrapper>{children}</StyledReviewInfoWrapper>
}

const StyledReviewInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ReviewScore = ({ children }: { children: ReactNode }) => {
  const { color } = useTheme()
  return (
    <StyledReviewScoreWrapper>
      <FlatIcon type={IconNameEnum.IcStar} color={color.yellow500} size="1.2rem" />
      <Typo variant={TypoVariant.Body5Medium} color={color.gray700}>
        {children}
      </Typo>
    </StyledReviewScoreWrapper>
  )
}

const StyledReviewScoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
`

const ReviewCount = ({ children }: { children: ReactNode }) => {
  const { color } = useTheme()
  return (
    <StyledReviewCountWrapper>
      <Typo variant={TypoVariant.Body5Regular} color={color.gray400}>
        {children}
      </Typo>
    </StyledReviewCountWrapper>
  )
}

const StyledReviewCountWrapper = styled.div`
  display: flex;
  margin-left: 0.2rem;
`

const CouponDiscountInfo = ({ children }: { children: ReactNode }) => {
  return <StyledCouponInfoWrapper variant={{ gap: '0.2rem' }}>{children}</StyledCouponInfoWrapper>
}

const StyledCouponInfoWrapper = styled.div<{ variant: CSSProperties }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ variant: { gap } }) => gap};
`

const CouponDiscountRate = ({ children }: { children: ReactNode }) => {
  const { color } = useTheme()

  return (
    <StyledCouponRateWrapper>
      <Typo variant="Body5 Medium" color={color.gray500}>
        {children}
      </Typo>
    </StyledCouponRateWrapper>
  )
}

const StyledCouponRateWrapper = styled.div`
  display: flex;
`

const CouponOriginalPrice = ({ children }: { children: ReactNode }) => {
  const { color } = useTheme()

  return (
    <Typo
      variant={TypoVariant.Body5Regular}
      color={color.gray300}
      style={{
        textDecoration: 'line-through',
      }}
    >
      {children}
    </Typo>
  )
}

const CouponDiscountPrice = ({ children }: { children: ReactNode }) => {
  const { color } = useTheme()

  return (
    <StyledCouponPriceWrapper variant={{ gap: '0.4rem' }}>
      <Typo variant="Body1 Bold" color={color.red500}>
        {children}
      </Typo>
      <Typo variant={TypoVariant.Caption1Bold} color={color.red500}>
        쿠폰적용시
      </Typo>
    </StyledCouponPriceWrapper>
  )
}

const StyledCouponPriceWrapper = styled.div<{ variant: CSSProperties }>`
  display: flex;
  align-items: end;
  gap: ${({ variant: { gap } }) => gap};
  margin-bottom: 0.2rem;
`

const DefaultPriceInfo = ({ hasDiscount, discountRate, price, salePrice }: PriceInfoPropsWithoutType) => {
  return (
    <>
      {hasDiscount && <DefaultOriginalPrice>{currencyText(price)}</DefaultOriginalPrice>}
      <DefaultDiscountInfo>
        {hasDiscount && <DefaultDiscountRate>{percentText(discountRate)}</DefaultDiscountRate>}
        <DefaultDiscountPrice>{currencyText(salePrice)}</DefaultDiscountPrice>
      </DefaultDiscountInfo>
    </>
  )
}

const CouponPriceInfo = ({ hasDiscount, discountRate, price, salePrice }: PriceInfoPropsWithoutType) => {
  return (
    <>
      <CouponDiscountInfo>
        {hasDiscount && <CouponDiscountRate>{percentText(discountRate)}</CouponDiscountRate>}
        {price !== salePrice && <CouponOriginalPrice>{currencyText(price)}</CouponOriginalPrice>}
      </CouponDiscountInfo>
      <CouponDiscountPrice>{currencyText(salePrice)}</CouponDiscountPrice>
    </>
  )
}

const PriceInfo = ({ hasDiscount, discountRate, price, salePrice, type }: PriceInfoProps) => {
  return match(type)
    .with(CARD_BODY_PRICE_INFO_TYPES.DEFAULT, () => (
      <DefaultPriceInfo hasDiscount={hasDiscount} discountRate={discountRate} price={price} salePrice={salePrice} />
    ))
    .with(CARD_BODY_PRICE_INFO_TYPES.COUPON, () => (
      <CouponPriceInfo hasDiscount={hasDiscount} discountRate={discountRate} price={price} salePrice={salePrice} />
    ))
    .exhaustive()
}

CardBodyHorizontal.Name = Name

CardBodyHorizontal.DiscountedBulkPrice = DiscountedBulkPrice
CardBodyHorizontal.ReviewInfo = ReviewInfo
CardBodyHorizontal.ReviewScore = ReviewScore
CardBodyHorizontal.ReviewCount = ReviewCount

CardBodyHorizontal.DefaultPriceInfo = DefaultPriceInfo
CardBodyHorizontal.CouponPriceInfo = CouponPriceInfo

CardBodyHorizontal.PriceInfo = PriceInfo
