import { FC } from 'react'
import { Row, Col } from 'antd'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Image } from '@/components/common'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import { IModelReviewImage } from '@/containers/models/modelReviewImage'
import { doSetFullScreenReviewImages } from '@/stores/reduxUI'

type ReviewImagesProps = {
  images: IModelReviewImage[]
}

const ReviewImages: FC<ReviewImagesProps> = ({ images }) => {
  const dispatch = useDispatch()

  const _onClick = (imageIdx: number) => {
    dispatch(
      doSetFullScreenReviewImages({
        reviewImages: images,
        showIndex: imageIdx,
      })
    )
  }

  return (
    <Row wrap={false} gutter={6}>
      {images.map((image: IModelReviewImage, index: number) => (
        <Col key={image.id} className="button" onClick={() => _onClick(index)}>
          <ImageContainer>
            <Link href={`#review-${image.id}`}>
              <Image objectFit="cover" src={image.thumbnail} alt="Review Thumbnail" />
            </Link>
          </ImageContainer>
        </Col>
      ))}
    </Row>
  )
}

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 3px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  cursor: pointer;

  @media ${SCREEN_MEDIA_QUERY.xs} {
    width: 76px;
    height: 76px;
  }
`

export default ReviewImages
