import { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { useIntersectionObserver, usePageInfo } from '@/containers/hooks/index'
import { useRecentlyProductsButtonItemQuery, useRecentlyProductsQuery } from '@/graphql/generated/hooks'
import { AdPetType } from '@/graphql/generated/schemas'
import { AppStorageDataType, Bridge } from '@/utils/bridge/bridge'
import { GraphQLProductType } from '@/utils/product/product-card.util'
import appBridgeProvider from '@/utils/utilBridge'
import { deepcopy } from '@/utils/utilData'
import { localRecentlyProductByPetType, localRecentlyProductItems } from '@/utils/utilLocalStorage'

type UseRecentlyProductType = {
  length?: number | undefined
  currentProductId?: string
  isFloatingButton?: boolean
}

type UseRecentlyProductReturnType = {
  isLoading: boolean
  recentlyProducts: GraphQLProductType[]
  recentlyProductsButtonItem: GraphQLProductType[]
  elementRef: (node: HTMLDivElement) => void
  addRecentlyProduct: (data: RecentlyProductType) => void
  addRecentlyProductByPetType: (productId: string, petType: AdPetType) => void
  setStorageSync: () => void
  removeRecentlyProductItem: () => void
  buttonThumbnail: string
  totalCount: number
  localRecentlyItems?: RecentlyProductItemsType[]
}

export type RecentlyProductItemsType = {
  productId: string
  promotionId: string
  viewDate: number
  image: string
}

export type RecentlyProductFilterType = {
  product: string
  productPromotion?: string
}

export interface RecentlyProductType {
  productId: string
  image: string
  productPromotionId?: string
}

const defaultPageSize = PageSizeEnum.PageSize16

const useRecentlyProduct = ({
  length,
  currentProductId,
  isFloatingButton = false,
}: UseRecentlyProductType): UseRecentlyProductReturnType => {
  const [recentlyProducts, setRecentlyProduct] = useState<GraphQLProductType[]>([])
  const [buttonThumbnail, setButtonThumbnail] = useState('')
  const localRecentlyItems = localRecentlyProductItems.load()

  const { getTablePageInfoVariables, setPageNumber, ...pageProps } = usePageInfo(length ?? defaultPageSize)
  const { elementRef } = useIntersectionObserver(() => {
    // 더 호출할 페이지 없으면 추가 호출하지 않음
    setPageNumber((prev) => prev + 1)
  })

  /**
   * storage 형식에서 query 호출 형식으로 변환
   * storage 저장 시 (RecentlyProductItemsType), 쿼리 호출 시 (RecentlyProductFilterType)
   */
  const getRecentlyProductFilter = () => {
    const recentlyProductFromStorage: RecentlyProductFilterType[] = []
    const filterLength = length && length + 1 <= localRecentlyItems.length ? length : localRecentlyItems.length

    for (let i = 0; i < filterLength; i++) {
      const { productId, promotionId } = localRecentlyItems[i]
      // 상품 상세 페이지 일 때 현재 보고있는 상품은 제외
      if (currentProductId === productId) continue

      let recentlyItem: RecentlyProductFilterType = { product: productId }
      if (promotionId) recentlyItem.productPromotion = promotionId
      recentlyProductFromStorage.push(recentlyItem)
    }
    return recentlyProductFromStorage
  }

  /**
   * 최근 본 상품 리스트 쿼리 호출
   */
  const { loading: isLoading, data: recentlyProductData } = useRecentlyProductsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    // 로컬스토리지에 최근 본 상품이 없으면 쿼리 호출하지 않음
    skip: !getRecentlyProductFilter().length || isFloatingButton || length === 0,
    variables: {
      filter: {
        products: getRecentlyProductFilter(),
      },
      ...(!length && getTablePageInfoVariables(pageProps.pageNumber, pageProps.pageSizeNumber)),
    },
    onCompleted: (data) => {
      if (!data) {
        return
      }
      const products: GraphQLProductType[] =
        data.productsWithoutPetType?.edges.map(({ node }) => node as unknown as GraphQLProductType) || []
      const concatList = recentlyProducts.concat(products)
      const deduplication = _.uniqBy(concatList, 'id')
      setRecentlyProduct(deduplication)
    },
  })

  const isFirstProductHasImage = localRecentlyItems?.length > 0 ? !!localRecentlyItems[0]?.image : false

  /**
   * 최근 본 상품 플로팅 버튼 쿼리 호출
   */
  const { data } = useRecentlyProductsButtonItemQuery({
    fetchPolicy: 'no-cache',
    skip: !localRecentlyItems.length || !isFloatingButton || isFirstProductHasImage,
    variables: {
      filter: {
        products: getRecentlyProductFilter(),
      },
    },
  })
  const recentlyProductsButtonItem =
    data?.productsWithoutPetType?.edges.map(({ node }) => node as unknown as GraphQLProductType) || []

  /**
   * 상품 상세페이지에 접근하여 productDetail 쿼리 호출 후 최근 본 상품에 저장
   */
  const addRecentlyProduct = ({ productId, productPromotionId, image }: RecentlyProductType) => {
    const viewedProduct: RecentlyProductItemsType = {
      productId: productId,
      promotionId: productPromotionId || '',
      viewDate: Date.now(),
      image,
    }

    const recentlyProductItems = _.chain(deepcopy(localRecentlyItems))
      .filter((i) => {
        if (productPromotionId) {
          return i.productId !== productId && i.promotionId !== productPromotionId
        }
        return i.productId !== productId
      })
      .concat(viewedProduct)
      .sortBy('viewDate')
      .reverse()
      .value()
    localRecentlyProductItems.save(recentlyProductItems)
  }

  /**
   * 최근 본 상품의 연관 상품 추천 섹션 관련 데이터 저장
   * 펫 타입에 따라 상품을 나누어 저장하여 홈섹션 쿼리 variable에 사용
   */
  const addRecentlyProductByPetType = (productId: string, petType: AdPetType) => {
    const recentlyProductByPetType = deepcopy(localRecentlyProductByPetType.load())

    if (petType === AdPetType.All) {
      recentlyProductByPetType[AdPetType.Dog] = productId
      recentlyProductByPetType[AdPetType.Cat] = productId
    } else {
      recentlyProductByPetType[petType] = productId
    }
    localRecentlyProductByPetType.save(recentlyProductByPetType)
  }

  /**
   * 앱스토리지와 브릿지를 통해 최근 본 상품 데이터 싱크를 맞추도록 함
   */
  const setStorageSync = () => {
    appBridgeProvider(async (bridge: Bridge) => {
      bridge.storageGetItem('viewedProductList').then((data: AppStorageDataType<RecentlyProductItemsType[]>) => {
        if (data.data) {
          localRecentlyProductItems.save(data.data)
        }
      })
    })
  }

  /**
   * 최근 본 상품 viewDate 30일 경과하면 로컬스토리지 데이터에 해당 상품을 제거
   */
  const removeRecentlyProductItem = () => {
    const recentlyProductItems = _.chain(deepcopy(localRecentlyItems))
      .filter((item) => moment(item.viewDate).add('30', 'days') > moment())
      .value()
    localRecentlyProductItems.save(recentlyProductItems)
  }

  useEffect(() => {
    setButtonThumbnail(
      isFirstProductHasImage ? localRecentlyItems[0]?.image : recentlyProductsButtonItem[0]?.mainImage?.thumbnail || ''
    )
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setButtonThumbnail(
          isFirstProductHasImage
            ? localRecentlyItems[0]?.image
            : recentlyProductsButtonItem[0]?.mainImage?.thumbnail || ''
        )
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
  }, [localRecentlyItems])

  return {
    isLoading,
    recentlyProducts,
    recentlyProductsButtonItem,
    buttonThumbnail,
    elementRef,
    addRecentlyProduct,
    addRecentlyProductByPetType,
    setStorageSync,
    removeRecentlyProductItem,
    totalCount: recentlyProductData?.productsWithoutPetType?.totalCount as number,
    localRecentlyItems,
  }
}

export default useRecentlyProduct
