import { FC, useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import isNil from 'lodash/isNil'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ApiQuery } from '@/components/api'
import { QueryParamsProps } from '@/components/api/ApiQuery'
import { List, TextLegacy } from '@/components/common'
import { PlainBorderButtonLegacy } from '@/components/common/buttons'
import { ListItemDeliveryAddress } from '@/components/domains/listItems'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'
import { IModelCustomerTemplateAddress } from '@/containers/models/modelCustomerTemplateAddress'
import { doSetAddressListLength } from '@/stores/reduxData'
import { RootState } from '@/stores/store'
import { localPostCode } from '@/utils/utilLocalStorage'

type ListDeliveryAddressesProps = {
  type: 'order' | 'mypage' | 'retrieval'
  selectedCustomerAddress?: IModelCustomerTemplateAddress
  setSelectedAddress?: (address: IModelCustomerTemplateAddress) => void
  getDefaultAddress?: (defaultAddress: IModelCustomerTemplateAddress) => void
}

const ListDeliveryAddresses: FC<ListDeliveryAddressesProps> = ({
  type,
  selectedCustomerAddress,
  setSelectedAddress,
  getDefaultAddress,
}) => {
  const dispatch = useDispatch()
  const { push, query } = useCustomRouter()
  const addressLength = useSelector((state: RootState) => state.reduxDataReducers.addressLength)

  const [forceRerender, toggleForceRerender] = useState<boolean>(true)

  const _onAddressSelected = (address: IModelCustomerTemplateAddress) => {
    if (!isNil(setSelectedAddress)) {
      setSelectedAddress(address)
    }
  }

  const isSelected = (targetAddress: IModelCustomerTemplateAddress, index: number) => {
    if (!selectedCustomerAddress) {
      return targetAddress.isDefault
    }

    if (selectedCustomerAddress.id) {
      return selectedCustomerAddress?.id === targetAddress.id
    }

    return index === 0
  }

  const onApiSuccess = (data: any) => {
    if (!data) return null

    const addresses = data.customerTemplateAddresses.data

    if (typeof addressLength === 'number' && addressLength < addresses.length && setSelectedAddress) {
      setSelectedAddress(addresses[addresses.length - 1])
    }
    dispatch(doSetAddressListLength(addresses.length))

    if (getDefaultAddress) {
      let _defaultAddress
      if (type === 'retrieval') {
        _defaultAddress = addresses.filter((a: IModelCustomerTemplateAddress) => a.id === query?.id)[0]
      } else {
        _defaultAddress = addresses[0]
      }
      getDefaultAddress(_defaultAddress)
    }
  }

  const queryParams: QueryParamsProps = {
    gqlKey: 'customerAddresses',
    variables: {
      orderBy: '-isDefault',
    },
  }

  useEffect(() => {
    if (forceRerender) {
      toggleForceRerender(false)
    }
  }, [forceRerender])

  useEffect(() => {
    // 배송지 수정 페이지를 가기 전에 초기화 합니다.
    localPostCode.remove()
  }, [])

  if (forceRerender) {
    return null
  }

  return (
    <ApiQuery queryParams={queryParams} onAPISuccess={onApiSuccess} noCache>
      {({ data }) => {
        if (!data) return null
        const { data: addresses, totalCount } = data.customerTemplateAddresses

        return (
          <>
            <StyledRow justify="space-between" align="middle">
              <Col>
                <TextLegacy size={{ xs: 16, md: 18 }} weight="bold">
                  배송지 {totalCount}곳
                </TextLegacy>
              </Col>
              <Col>
                <PlainBorderButtonLegacy
                  text="배송지 추가"
                  onClick={() => {
                    push(ROUTES.DELIVERY_ADDRESSES.CREATE)
                  }}
                />
              </Col>
            </StyledRow>
            <List
              dataSource={addresses}
              renderItem={(item, index) => {
                return (
                  <ListItemDeliveryAddress
                    key={item.id}
                    address={item}
                    selected={isSelected(item, index)}
                    updateRoute={ROUTES.DELIVERY_ADDRESSES.UPDATE}
                    onSelect={_onAddressSelected}
                  />
                )
              }}
            />
          </>
        )
      }}
    </ApiQuery>
  )
}

const StyledRow = styled(Row)`
  padding-bottom: 12px;
`

export default ListDeliveryAddresses
