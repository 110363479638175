import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { ImageAsset, TextLegacy } from '@/components/common'

const ListUserReviewEmpty: FC = () => {
  return (
    <StyledContainer justify="center" align="middle">
      <Col>
        <Row justify="center">
          <ImageAsset name="empty_writable_review" size={80} />
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <TextLegacy align="center">{`상품 리뷰가 없습니다.\n상품을 구매하고 리뷰를 작성해 보세요!`}</TextLegacy>
        </Row>
      </Col>
    </StyledContainer>
  )
}

const StyledContainer = styled(Row)`
  min-height: 70vh;
`

export default ListUserReviewEmpty
