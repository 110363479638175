import { FC } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'
import { ImageAsset } from '@/components/common'

type ButtonCircleCloseProps = {
  onClick: () => void
}

const ButtonCircleClose: FC<ButtonCircleCloseProps> = ({ onClick }) => {
  return (
    <StyledCircleButton justify="center" align="middle" onClick={onClick}>
      <ImageAsset name="close-white" size={16} />
    </StyledCircleButton>
  )
}

const StyledCircleButton = styled(Row)`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  box-shadow: 4px 4px 10px rgb(46 46 46 / 25%);
  cursor: pointer;
  background-color: ${(props) => props.theme.color.gray700};
  padding-right: 1px;
`

export default ButtonCircleClose
