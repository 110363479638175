import { FC } from 'react'
// TODO typescript 오류 수정 필요
// @ts-ignore
import DaumPostcode, { AddressData } from 'react-daum-postcode'
import { Modal } from '@/components/common'
import { ModalBaseProps } from '@/components/common/modals/ModalBase'
import { FieldKeysEnum } from '@/constants/field-keys.enum'
import { localPostCode } from '@/utils/utilLocalStorage'

type ModalDaumPostcodeProps = {} & ModalBaseProps<any>

const handleResult = (data: AddressData) => {
  let fullAddress = data.address
  let extraAddress = ''

  if (data.addressType === 'R') {
    if (data.bname !== '') {
      extraAddress += data.bname
    }
    if (data.buildingName !== '') {
      extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
    }
    fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
  }

  return {
    [FieldKeysEnum.BasicAddress]: fullAddress,
    [FieldKeysEnum.PostNumber]: data.zonecode,
    [FieldKeysEnum.DetailAddress]: '',
  }
}

const ModalDaumPostcode: FC<ModalDaumPostcodeProps> = ({ useModalProps }) => {
  const { setActionParams, hideModal } = useModalProps
  let historyLength = 0
  return (
    <Modal
      title="우편번호/주소 검색"
      useModalProps={useModalProps}
      buttonType="CANCEL"
      cancelText="닫기"
      backAction={false}
      noBackBehavior
    >
      <DaumPostcode
        height={650}
        onClose={() => {
          window.history.go(historyLength * -1)
        }}
        onSearch={() => {
          // react-daum-postcode 자체 버그 수정
          historyLength += 1
        }}
        // height={isMobile ? 400 : 650}
        onComplete={(data) => {
          const actionParams = handleResult(data)
          localPostCode.save(actionParams)
          setActionParams(actionParams)
          hideModal()
        }}
        autoClose
      />
    </Modal>
  )
}

export default ModalDaumPostcode
