import { FC } from 'react'
import { useRouter } from 'next/router'
import styled, { useTheme } from 'styled-components'
import { ValueOf } from 'type-fest'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { SkeletonLine } from '@/components/domains/category/SkeletonLine'
import { IconNameEnum } from '@/constants/icon-name.enum'
import {
  PRODUCTS_SELECT_FILTER_ORDER_BY_V4,
  ProductsSelectFilterOrderBy,
} from '@/constants/products-select-filter-orderby.const'
import { useSearchResult } from '@/containers/hooks'
import { numberWithCommas } from '@/utils/utilNumber'

type ListProductFilterProps = {
  totalCount: number
  orderBy: string
  options?: Array<ValueOf<typeof ProductsSelectFilterOrderBy>>
  isLoading?: boolean
}

const ListProductFilter: FC<ListProductFilterProps> = ({
  totalCount,
  orderBy,
  options = [
    PRODUCTS_SELECT_FILTER_ORDER_BY_V4.PRICE_ASC,
    PRODUCTS_SELECT_FILTER_ORDER_BY_V4.BEST_SELLING,
    PRODUCTS_SELECT_FILTER_ORDER_BY_V4.REVIEW_SCORE,
    PRODUCTS_SELECT_FILTER_ORDER_BY_V4.REVIEW_COUNT,
    PRODUCTS_SELECT_FILTER_ORDER_BY_V4.DISCOUNT_RATE,
    PRODUCTS_SELECT_FILTER_ORDER_BY_V4.PRICE_DESC,
    PRODUCTS_SELECT_FILTER_ORDER_BY_V4.NEWEST,
  ],
  isLoading = false,
}) => {
  const { pathname } = useRouter()
  const { appendFilterQuery } = useSearchResult()
  const { color, iconSize } = useTheme()

  return (
    <StyledContainer className="list-product-filter">
      <div className="list-product-filter__total-count">
        <Typo variant={TypoVariant.Body3Regular} color={color.gray500}>
          {`${numberWithCommas(totalCount === -1 ? 0 : totalCount)} 개의 상품`}
        </Typo>
      </div>
      <div className="list-product-filter__select">
        {isLoading ? (
          <SkeletonLine height="2rem" width="8rem" />
        ) : (
          <>
            <StyledSelect value={orderBy} onChange={(e) => appendFilterQuery(pathname, { orderBy: e.target.value })}>
              {options.map((f) => (
                <option key={f.value} value={f.value}>
                  {f.text}
                </option>
              ))}
            </StyledSelect>
            <FlatIcon type={IconNameEnum.IcArrowDown} color={color.gray300} size={iconSize.size20} />
          </>
        )}
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  .list-product-filter {
    &__select {
      display: flex;
      align-items: center;
    }
  }
`
const StyledSelect = styled.select`
  border: none;
  background-color: ${({
    theme: {
      color: { grayWhite },
    },
  }) => grayWhite};
  font-size: 1.4rem;
  color: ${({
    theme: {
      color: { gray500 },
    },
  }) => gray500};
  font-weight: normal;
  cursor: pointer;
  direction: rtl;
  :focus-visible {
    outline: none;
  }
  appearance: none;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
`

export default ListProductFilter
