import { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ImageAsset } from '@/components/common'
import { CarouselBase } from '@/components/common/carousels/CarouselBase'
import { Z_INDEXES, LAYOUT_UNITS } from '@/constants/legacy/constLayout'
import { useCustomRouter } from '@/containers/hooks'
import { doSetFullScreenReviewImages } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'
import { enableScroll } from '@/utils/utilCommon'

type ModalReviewImageFullScreenProps = {}

const ModalReviewImageFullScreen: FC<ModalReviewImageFullScreenProps> = () => {
  const { back } = useCustomRouter()
  const dispatch = useDispatch()
  const fullScreenReviewImages = useSelector((rootState: RootState) => rootState.reduxUIReducers.fullScreenReviewImages)

  useEffect(() => {
    enableScroll(!fullScreenReviewImages)

    window.addEventListener('popstate', beforePop)
    return () => {
      window.removeEventListener('popstate', beforePop)
    }
  }, [fullScreenReviewImages])

  const beforePop = (ev: PopStateEvent) => {
    ev.preventDefault()
    dispatch(doSetFullScreenReviewImages(undefined))
  }

  if (!fullScreenReviewImages) {
    return null
  }

  const { reviewImages, showIndex } = fullScreenReviewImages

  return (
    <StyledContainer>
      <Background onClick={back}>
        <CloseButtonContainer>
          <ImageAsset name="close-white-thin" size={20} />
        </CloseButtonContainer>
        <CarouselContainer>
          <CarouselBase
            slidesToShow={1}
            initialSlide={showIndex}
            adaptiveHeight
            lazyLoad
            NextArrow={<RenderNextArrow />}
            PreviousArrow={<RenderPrevArrow />}
          >
            {reviewImages.map((image) => (
              <StyledImageContainer key={image.id}>
                <StyledImage src={image.getImageUrl()} onClick={(e) => e.stopPropagation()} />
              </StyledImageContainer>
            ))}
          </CarouselBase>
        </CarouselContainer>
      </Background>
    </StyledContainer>
  )
}

const RenderNextArrow = (props: any) => {
  const { onClick } = props
  return (
    <NextArrowButtonContainer>
      <ArrowButton
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
      >
        <ImageAsset name="arrow-right-white" size={24} />
      </ArrowButton>
    </NextArrowButtonContainer>
  )
}

const RenderPrevArrow = (props: any) => {
  const { onClick } = props
  return (
    <PrevArrowButtonContainer>
      <ArrowButton
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
      >
        <ImageAsset name="arrow-left-white" size={24} />
      </ArrowButton>
    </PrevArrowButtonContainer>
  )
}

const StyledContainer = styled.div`
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  z-index: ${Z_INDEXES.REVIEW_IMAGE_POPUP};
`

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: ${Z_INDEXES.CAROUSEL_ARROW_BUTTON};
  cursor: pointer;
`

const Background = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
`

const CarouselContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`

const StyledImageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
`

const StyledImage = styled.img`
  width: 100%;
  object-fit: contain;
`

const NextArrowButtonContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${Z_INDEXES.CAROUSEL_ARROW_BUTTON};
  cursor: pointer;
`

const PrevArrowButtonContainer = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${Z_INDEXES.CAROUSEL_ARROW_BUTTON};
  cursor: pointer;
`

const ArrowButton = styled.div`
  background-color: #3a3a3a;
  opacity: 0.8;
  padding: 16px;
`

export default ModalReviewImageFullScreen
