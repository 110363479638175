import { FC } from 'react'
import { Row, Col } from 'antd'
import styled, { useTheme } from 'styled-components'
import { TextLegacy } from '@/components/common'
import { Typo, TypoVariant } from '@/components/common/typography'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IModelMileageHistory } from '@/containers/models/modelMileageHistory'
import { date2string, DATE_TIME_DISPLAY_FORMAT } from '@/utils/utilDate'
import { numberWithCommas } from '@/utils/utilNumber'

type ListItemUserMileageRecordProps = {
  mileage: IModelMileageHistory
}

const ListItemUserMileageRecord: FC<ListItemUserMileageRecordProps> = ({ mileage }) => {
  const { color } = useTheme()
  const {
    updatedAt,
    message,
    subMessage,
    mileageType,
    mileageDiff,
    // orderItem,
    expiredAt,
    review,
  } = mileage

  const isAdded = mileageDiff > 0

  const getCaseText = () => {
    if (mileageType === 'ORDER') {
      return '사용'
    }

    if (isAdded) {
      return '적립'
    }

    return '소멸'
  }

  const _getProductName = (): string | undefined => {
    if (!subMessage && !review) {
      return undefined
    }

    return subMessage || review.productOptionName
  }

  const _productName = _getProductName()

  return (
    <StyledContainer>
      <Row justify="space-between" align="middle" wrap={false}>
        <Col>
          <UpdateDateRow>
            <TextLegacy size={{ xs: 12, md: 14 }} color="gray400">
              {date2string(updatedAt)}
            </TextLegacy>
          </UpdateDateRow>
          <Row>
            <TextLegacy>{message}</TextLegacy>
          </Row>

          {_productName && (
            <Row>
              <TextLegacy size={{ xs: 12, md: 14 }} color="gray400">
                {_productName}
              </TextLegacy>
            </Row>
          )}
          {/* { orderItem && (
            <Row>
              <TextLegacy size={{xs: 12, md: 14}} color='gray400'>
                { orderItem.productName }
              </TextLegacy>
            </Row>
          ) } */}

          {isAdded && (
            <Row>
              <TextLegacy size={{ xs: 12, md: 14 }} color="gray400">
                {/* 유효기간 : 백엔드 추가 필요 */}
                {`유효기간 : ${date2string(expiredAt, DATE_TIME_DISPLAY_FORMAT)}`}
              </TextLegacy>
            </Row>
          )}
        </Col>

        <Col xs={6} md={4}>
          <Row justify="center">
            <Typo variant={TypoVariant.Heading6Bold} color={isAdded ? color.blue500 : color.gray400}>
              {`${isAdded ? '+' : ''}${numberWithCommas(mileageDiff)}`}
            </Typo>
          </Row>
          <Row justify="center">
            <Typo variant={TypoVariant.Body3Bold} color={isAdded ? color.blue500 : color.gray400}>
              {getCaseText()}
            </Typo>
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  padding: 20px 30px 20px 20px;
  border-bottom: 1px solid ${GlobalColorEnum.Gray70};
`

const UpdateDateRow = styled(Row)`
  margin-bottom: 5px;
`

export default ListItemUserMileageRecord
