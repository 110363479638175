import { UrlObject } from 'url'
import { FITPETMALL_HOST, HOSPITAL_HOST, S3_HOST_URL, S3_ROOT_FOLDER } from '@/constants/legacy/constEnv'
import { PlaceHolderThumbnailEnum } from '@/constants/placeholder-thumbnail.enum'
import { ProductThumbnailSizeEnum } from '@/constants/product-thumbnail-size.enum'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider from '@/utils/utilBridge'
import { isInAndroid, isInIos } from '@/utils/utilCommon'

/**
 * 이전 페이지 경로를 받아서 페이지 이름일 반환, 수기로 텍스트를 지정하는 기존 location과 다르게 url 두번째or세번째 부분 추출하는 방식으로 기록
 * @param prevPathname 이전 페이지의 path(basePath + asPath)
 * @return 인자에서 추출된 페이지 path 명
 * @description 'https://fitpetmall.com/mall' 가 이전 페이지라면 "mall" 반환
 * @description 'https://fitpetmall.com/mall/home/events/gnb_fitpetonly-dog' 가 이전 페이지라면 "events"를 반환
 * @description 'https://fitpetmall.com/mall/products/1000023187' 가 이전 페이지라면 "products"를 반환
 */
export const getPrevPageName = (prevPathname: string) => {
  try {
    const prevPathToWord = prevPathname.match(/(?:\/mall\/)((home\/(?<mainGnbSubPath>\w+))|(?<path>\w+))/)
    const { mainGnbSubPath, path } = prevPathToWord?.groups as { mainGnbSubPath?: string; path?: string }

    return mainGnbSubPath || path
  } catch (e) {
    return 'mall'
  }
}

const convertToProductThumbnail = {
  [ProductThumbnailSizeEnum.Card76]: PlaceHolderThumbnailEnum.Card76,
  [ProductThumbnailSizeEnum.Card86]: PlaceHolderThumbnailEnum.Card86,
  [ProductThumbnailSizeEnum.Card109]: PlaceHolderThumbnailEnum.Card109,
  [ProductThumbnailSizeEnum.Card136]: PlaceHolderThumbnailEnum.Card136,
  [ProductThumbnailSizeEnum.Card167AndHalf]: PlaceHolderThumbnailEnum.Card167AndHalf,
  [ProductThumbnailSizeEnum.Card200]: PlaceHolderThumbnailEnum.Card200,
  [ProductThumbnailSizeEnum.Card375]: PlaceHolderThumbnailEnum.Card375,
  [ProductThumbnailSizeEnum.Fill]: PlaceHolderThumbnailEnum.Card167AndHalf, // 디폴트 사이즈가 없어서 167.5로 설정
}

const getThumbnailPath = (size: ProductThumbnailSizeEnum): PlaceHolderThumbnailEnum => {
  return convertToProductThumbnail[size]
}

export const getThumbnailPlaceHolder = (size: ProductThumbnailSizeEnum) => {
  const path = getThumbnailPath(size)
  return `${process.env.HOST}${path}`
}

/**
 * image url 을 s3 url 로 변환한다
 * full path(http://...) 인 경우 그대로 리턴한다
 *
 * @param url
 */
export const getImageUrl = (url?: string): undefined | string => {
  if (!url) {
    return undefined
  }

  if (url.startsWith(S3_HOST_URL) || url.startsWith('http:') || url.startsWith('https:')) {
    return url
  }

  return `${S3_HOST_URL}/${url}`
}

export const getFullPathUrl = (url: UrlObject['pathname']) => {
  const hostUrl = FITPETMALL_HOST ?? ''
  return url ? hostUrl + url : hostUrl
}

/**
 * 상대 경로를 S3 이미지 URL로 변환합니다.
 *
 * @param {string} relativePath - 이미지의 상대 경로입니다. 예: 'events/event-partners-content.jpg'.
 * @returns {string} S3의 이미지 전체 URL입니다. 예: 'https://static.fitpetcdn.com/web/staging/events/event-partners-content.jpg'.
 */
export const getImageByStaticUrl = (relativePath: string) => {
  const cleanedRelativePath = relativePath.replace(/^\//, '')
  return `${S3_HOST_URL}/${S3_ROOT_FOLDER}/${cleanedRelativePath}`
}

/**
 * 주어진 URL을 새 탭에서 열어주는 함수입니다.
 * 안드로이드 앱에서는 `window.open`이 동작하지 않으므로, 앱 브리지를 통해 브라우저를 엽니다.
 * iOS 앱에서는 `window.open`이 앱 바텀시트로 열린다.
 * 그 외의 플랫폼에서는 `window.open`을 사용하여 URL을 새 탭에서 엽니다.
 *
 * @param {string} url - 새 탭에서 열 URL입니다.
 */
export const openExternalUrl = (url: string) => {
  if (isInAndroid()) {
    appBridgeProvider((bridge: Bridge) => bridge.openBrowser(url, 'out'))
  } else {
    window.open(url, '_blank')
  }
}

export const openExternalWithBridgeUrl = (url: string) => {
  if (isInAndroid() || isInIos()) {
    appBridgeProvider((bridge: Bridge) => bridge.openBrowser(url, 'out'))
  } else {
    window.open(url, '_blank')
  }
}

export const isHospitalRelatedUrls = (url: string) => {
  const hospitalRelatedUrls = [HOSPITAL_HOST, 'http://localhost:7001']
  return hospitalRelatedUrls.some((validUrl) => validUrl && url.startsWith(validUrl))
}

export const isRelativePath = (url: string = '') => {
  return /^(?!http)/.test(url) && !/(\.com)|(\.co\.kr)/.test(url)
}
