import { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { match } from 'ts-pattern'
import { LinkButton } from '@/components/common/buttons'
import Collapse from '@/components/common/etc/Collapse'
import { Image } from '@/components/common/images/Image'
import { Typo, TypoVariant } from '@/components/common/typography'
import { ABFlagEnum } from '@/constants/ab-flag.enum'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { FITPET } from '@/constants/legacy/constData'
import { FITPETMALL_HOST, ZENDESK_URLS } from '@/constants/legacy/constEnv'
import ROUTES from '@/constants/legacy/constRoutes'
import { ToastConst } from '@/constants/toast.const'
import { useCommonCopyText } from '@/containers/common/common-copy-text.hook'
import { useCustomRouter } from '@/containers/hooks'
import { localDeviceUuid } from '@/utils/utilLocalStorage'

type InfoItem = {
  name: string
  content: string
}

type BusinessInfoItem = {
  type: 'default' | 'email' | 'link'
  linkURL?: string
} & InfoItem

const snsIcons = [
  { id: 'facebook', src: 'ic_facebook.svg', url: 'https://www.facebook.com/fitpetofficial/' },
  { id: 'instagram', src: 'ic_insta.svg', url: 'https://www.instagram.com/fitpet_official/' },
  { id: 'blog', src: 'ic_blog.svg', url: 'https://blog.naver.com/fitpet_official' },
  { id: 'youtube', src: 'ic_youtube.svg', url: 'https://www.youtube.com/@Fitpet' },
]

const navigation = [
  {
    name: '이용약관',
    url: ROUTES.POLICY.AGREEMENT,
  },
  {
    name: '개인(위치)정보처리방침',
    url: ROUTES.POLICY.PRIVACY,
  },
  {
    name: '위치기반서비스 이용약관',
    url: ROUTES.POLICY.LOCATION_SERVICE,
  },
  {
    name: '고객센터',
    url: ZENDESK_URLS.CUSTOMER_CENTER as string,
  },
  {
    name: '공지사항',
    url: ZENDESK_URLS.NOTICE as string,
  },
]

const infoList: InfoItem[] = [
  {
    name: '핏펫몰 입점',
    content: FITPET.SELLER_QNA_EMAIL,
  },
  {
    name: '병원 예약 입점',
    content: FITPET.CARE_EMAIL,
  },
  {
    name: '도매',
    content: FITPET.HELP_JUNGLEBOOK_EMAIL,
  },
  {
    name: '광고',
    content: FITPET.CONTACT_EMAIL,
  },
  {
    name: '마케팅',
    content: FITPET.MARKETING_EMAIL,
  },
  {
    name: '언론 문의',
    content: FITPET.PR_EMAIL,
  },
]

const businessInfoList: BusinessInfoItem[] = [
  {
    name: '상호',
    content: FITPET.CORPORATION_COMPANY,
    type: 'default',
  },
  {
    name: '주소',
    content: FITPET.ADDRESS,
    type: 'default',
  },
  {
    name: '대표',
    content: FITPET.CEO,
    type: 'default',
  },
  {
    name: '사업자번호',
    content: FITPET.BUSINESS_REGISTRATION_NUMBER,
    type: 'default',
  },
  {
    name: '사업자번호조회 통신판매업 신고번호',
    content: FITPET.COMMUNICATION_SALES_BUSINESS_REPORT_NUMBER,
    type: 'default',
  },
  {
    name: '개인정보관리자',
    content: FITPET.PRIVACY_OFFICER,
    type: 'default',
  },
  {
    name: '이메일',
    content: FITPET.HELP_EMAIL,
    type: 'email',
  },
  {
    name: '전화번호',
    content: `${FITPET.TEL} | 팩스번호: ${FITPET.FAX}`,
    type: 'default',
  },
  {
    name: '구매안전서비스',
    content: FITPET.PURCHASE_SAFETY_SERVICE,
    type: 'link',
    linkURL: ROUTES.POLICY.PAYMENT_GUARANTEE,
  },
]

const BusinessInfoItem = ({ name, type, content, linkURL }: BusinessInfoItem) => {
  const { color } = useTheme()
  const { copyText } = useCommonCopyText()
  return match(type)
    .with('email', () => {
      return (
        <Typo variant={TypoVariant.Body5Regular} color={color.gray400}>
          {name} :{' '}
          <button
            className="footer__info-email"
            type="button"
            onClick={() =>
              copyText({
                text: content,
                successMessage: '복사되었어요',
                toastVariant: ToastConst.ToastVariant.withCTA,
              })
            }
          >
            {content}
          </button>
        </Typo>
      )
    })
    .with('link', () => {
      return (
        <Typo variant={TypoVariant.Body5Regular} color={color.gray400}>
          {name} :{' '}
          <LinkButton
            url={{
              pathname: linkURL,
            }}
            className="footer__info-link"
          >
            {content}
          </LinkButton>
        </Typo>
      )
    })
    .otherwise(() => {
      return (
        <Typo variant={TypoVariant.Body5Regular} color={color.gray400}>
          {name} : {content}
        </Typo>
      )
    })
}

const LayoutFooter = () => {
  const { color } = useTheme()
  const { copyText } = useCommonCopyText()
  const { reload } = useCustomRouter()

  const [abFlag, setAbFlag] = useState<ABFlagEnum.A | ABFlagEnum.B | undefined>(undefined)

  const handleClickAbFlagToggleForStage = () => {
    // A -> B
    if (abFlag === ABFlagEnum.A) {
      localDeviceUuid.save('2c4519d3-0dd5-43f8-ac43-082e16441ccc')
      setAbFlag(ABFlagEnum.B)
    }
    // B -> A
    if (abFlag === ABFlagEnum.B) {
      localDeviceUuid.save('2c4519d3-0dd5-43f8-ac43-082e16441ffc')
      setAbFlag(ABFlagEnum.A)
    }
    reload()
  }

  useEffect(() => {
    if (localDeviceUuid.load() === '2c4519d3-0dd5-43f8-ac43-082e16441ccc') {
      setAbFlag(ABFlagEnum.B)
    } else {
      setAbFlag(ABFlagEnum.A)
    }
  }, [])

  return (
    <StyledContainer data-cy="footer">
      <nav className="footer__nav">
        <ul className="footer__nav-list">
          {navigation.map(({ name, url }) => (
            <li className="footer__nav-item" key={name}>
              <Typo variant={TypoVariant.Body5Bold} color={color.gray600}>
                <LinkButton routeType="push" url={url}>
                  {name}
                </LinkButton>
              </Typo>
            </li>
          ))}
        </ul>
      </nav>
      <ul className="footer__operation-time">
        <li>평일 09:00~18:00 | 점심시간 13:00~14:00</li>
      </ul>
      <Typo as="p" variant={TypoVariant.Body5Bold} color={color.gray400}>
        제휴 입점 및 기타 문의
      </Typo>
      <ul className="footer__info-list">
        {infoList.map(({ name, content }) => (
          <li key={name} className="footer__info-item">
            <Typo variant={TypoVariant.Body5Regular} color={color.gray400}>
              {name} :{' '}
              <button
                className="footer__info-email"
                type="button"
                onClick={() =>
                  copyText({
                    text: content,
                    successMessage: '복사되었어요',
                    toastVariant: ToastConst.ToastVariant.withCTA,
                  })
                }
              >
                {content}
              </button>
            </Typo>
          </li>
        ))}
      </ul>
      <Typo as="p" variant={TypoVariant.Body5Regular} color={color.gray400}>
        사이버몰 내 판매되는 상품 중에는 핏펫에 등록한 개별 판매자가 판매하는 셀러판매 서비스 상품이 포함되어 있습니다.
        셀러판매 서비스 상품의 경우 핏펫은 통신판매중개자이며 통신판매의 당사자가 아닙니다. 핏펫은 셀러판매 서비스 상품,
        거래정보 및 거래 등에 대하여 책임을 지지 않습니다.
      </Typo>
      <div className="footer__business-info">
        <Collapse
          header={
            <Typo as="span" variant={TypoVariant.Body5Bold} color={color.gray400}>
              주식회사 핏펫 사업자 정보
            </Typo>
          }
          initialIsCollapsed={true}
          iconProps={{
            position: 'right',
            icon: 'ic_chevron_down',
            style: { marginLeft: '0.2rem' },
          }}
          content={
            <>
              <ul className="footer__info-list">
                {businessInfoList.map(({ name, content, type = 'default', linkURL = '' }) => (
                  <li key={name} className="footer__info-item">
                    {<BusinessInfoItem name={name} type={type} content={content} linkURL={linkURL} />}
                  </li>
                ))}
              </ul>
            </>
          }
          styleContent={{ lineHeight: 0 }}
          styleHeader={{ backgroundColor: GlobalColorEnum.Gray50 }}
        />
      </div>
      <SnsRow>
        {snsIcons.map((icon) => {
          return (
            <LinkButton key={icon.src} url={icon.url} openNewWindow routeType="none">
              <div className={'image-wrap'}>
                <Image src={`${FITPETMALL_HOST}/images/${icon.src}`} alt={icon.id} />
              </div>
            </LinkButton>
          )
        })}
      </SnsRow>
      <StyledRow>
        <StyledText>Copyright Fitpet, Inc. All rights Reserved.</StyledText>
      </StyledRow>
      {/* APP AB-TEST */}
      {process.env.FITPETMALL_ENV !== 'production' && (
        <button type="button" onClick={handleClickAbFlagToggleForStage}>
          현재 AB Flag: {abFlag} {localDeviceUuid.load()}
        </button>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  background-color: ${({
    theme: {
      color: { gray50 },
    },
  }) => gray50};
  padding: 4rem 1.6rem 4.8rem;
  .footer {
    &__nav {
      margin-bottom: 0.4rem;
    }
    &__nav-list {
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.8rem;
      &::before {
        content: '';
        order: 3;
        flex-basis: 100%;
      }
    }
    &__nav-item {
      margin-bottom: 1.2rem;
      &:nth-child(4) {
        order: 4;
      }
      &:nth-child(5) {
        order: 5;
      }
      a {
        color: inherit;
      }
    }
    &__operation-time {
      margin-bottom: 1.6rem;
      li {
        &:not(:first-child) {
          margin-top: 0.4rem;
        }
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.7rem;
        color: ${({
          theme: {
            color: { gray400 },
          },
        }) => gray400};
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: pre-line;
      }
    }
    &__info-list {
      margin: 0.4rem 0 1.6rem;
      button {
        outline: none;
        cursor: pointer;
        padding: 0;
        border: none;
        background-color: transparent;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: ${({
          theme: {
            color: { gray400 },
          },
        }) => gray400};
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: pre-line;
      }
    }
    &__info-item {
      &:not(:first-child) {
        margin-top: 0.4rem;
      }
    }
    &__info-email,
    &__info-link {
      color: inherit;
      text-decoration: underline;
      text-decoration-skip-ink: none;
    }
    &__business-info {
      margin: 2rem 0;
    }
  }
`

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 2rem;
`

const SnsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 2rem;

  & div.image-wrap {
    position: relative;
    width: 2.4rem;
    height: 2.4rem;
  }
`

const StyledText = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: ${(props) => props.theme.color.gray400};
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: pre-line;
`

export default LayoutFooter
