import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { TextLegacy } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { ModalMoreOrdersButtons, ModalShippingNumberSelect } from '@/components/domains/modals'
import { ListOrderItemProductMeta, OrderItemSweetTracker } from '@/components/domains/order'
import ActionPowerOrderItemActionButtons from '@/components/domains/order/ActionPowerOrderItemActionButtons'
import AddCartButton from '@/components/domains/order/ListOrderItemCard/AddCartButton'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { ShippingNumbersForSweetTracker } from '@/constants/legacy/constType'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { OrderStatusEnum } from '@/constants/order-status.enum'
import { useModal } from '@/containers/hooks'
import { OrderButtonsTrackInfoType } from '@/containers/hooks/order/useOrderConfirm'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { IModelProductOption } from '@/containers/models/modelProductOption'
import { IModelProductPromotion } from '@/containers/models/modelProductPromotion'
import { TransactionTransactionStatus } from '@/graphql/generated/schemas'
import { RootState } from '@/stores/store'

type Props = {
  orderItem: IModelOrderItem
  orderId: string
  showActionButtons?: boolean
  confirmCancelAll: () => void
  handleAddCartOrderItem?: (quantity: number, productOptionId: string, productPromotionId?: string) => Promise<void>
}

const ActionPowerListOrderItemCard: FC<Props> = ({
  orderItem,
  orderId,
  confirmCancelAll,
  showActionButtons = true,
  handleAddCartOrderItem,
}) => {
  const {
    id: orderItemId,
    orderStatus,
    canWriteReview,
    getOrderProductInfo,
    productPromotion,
    productOption,
    quantity,
    actionPowerOrder,
  } = orderItem

  const { color } = useTheme()

  const isAutoConfirmText = orderStatus === OrderStatusTypeEnum.Confirmed && canWriteReview()

  const initData = useSelector((rootState: RootState) => rootState.reduxDataReducers.initData)
  const _productInfo = getOrderProductInfo()
  const { productId, productName, optionName } = _productInfo
  const { id: productPromotionId } = productPromotion
  const { useModalProps: useSelectShippingModalProps } = useModal()
  const { useModalProps: useSelectMoreButtonsModalProps } = useModal()
  const [shippingTrackInfo, setShippingTrackInfo] = useState<{ companyCode: string; shippingNumber: string }>()

  const orderButtonsTrackInfo: OrderButtonsTrackInfoType = {
    product_id: productId,
    option_id: Number(orderItem.productOption._id),
    productName,
    optionName,
  }

  const productDetailLink =
    ROUTES.PRODUCTS.DETAIL.replace('[productId]', `${productId}`) + `?promotion=${productPromotionId}`

  const getShippingTrackNumbers = () => {
    const shippingNumbersForSweetTrackers: ShippingNumbersForSweetTracker[] = []
    const sweetTrackerCode = initData?.getSweetTrackerCode(orderItem.shippingCompanyId)
    if (sweetTrackerCode) {
      orderItem.shippingNumbers.forEach((shippingNumber) => {
        shippingNumbersForSweetTrackers.push({ companyCode: sweetTrackerCode, shippingNumber })
      })
    }
    return shippingNumbersForSweetTrackers
  }

  const shippingNumbersForSweetTrackers = getShippingTrackNumbers()

  const openSweetTracker = (shippingTrackNumber: ShippingNumbersForSweetTracker) => {
    setShippingTrackInfo({
      companyCode: shippingTrackNumber.companyCode,
      shippingNumber: shippingTrackNumber.shippingNumber,
    })
    useSelectShippingModalProps.hideModal()
  }

  const isRenderAddCartButton = (status: OrderStatusTypeEnum) => {
    switch (status) {
      case OrderStatusTypeEnum.Shipped:
      case OrderStatusTypeEnum.Canceled:
      case OrderStatusTypeEnum.CancelRequested:
      case OrderStatusTypeEnum.Confirmed:
        return isListOrderHistory() && handleAddCartOrderItem
      default:
        return false
    }
  }

  const isListOrderHistory = () => {
    const { product } = productOption
    return Boolean(product)
  }

  const isDeall100 = (productPromotion: IModelProductPromotion) => {
    if (!productPromotion) {
      return false
    }
    const { productPromotionType } = productPromotion
    const _isDeal100 = productPromotionType === 'DEAL_100' || productPromotionType === 'DEAL_990'
    return _isDeal100
  }

  const isDisablePurchase = (productOption: IModelProductOption) => {
    const { product } = productOption
    const isSoldOut =
      product.isRealSoldOut || product.isSoldOut || productOption.stockQuantity === 0 || productOption.isSoldOut
    const disabled = product.status.value !== 'APPROVED' || !productOption.isUse || productOption.isDelete

    return isSoldOut || disabled
  }

  const isDisabledAddCartButton = () => {
    if (isDeall100(productPromotion)) {
      return true
    }

    return isDisablePurchase(productOption)
  }

  const getOrderStatusTextColor = (orderStatus: OrderStatusTypeEnum) => {
    switch (orderStatus) {
      case OrderStatusTypeEnum.CancelRequested:
        return GlobalColorEnum.Red500
      case OrderStatusTypeEnum.UnconfirmedPayment:
      case OrderStatusTypeEnum.NewOrder:
      case OrderStatusTypeEnum.ReadyForShipping:
      case OrderStatusTypeEnum.Shipping:
      case OrderStatusTypeEnum.Shipped:
        return GlobalColorEnum.Blue500
      case OrderStatusTypeEnum.ReturnRequested:
      case OrderStatusTypeEnum.ReturnUnderReturn:
      case OrderStatusTypeEnum.ExchangeRequested:
      case OrderStatusTypeEnum.ExchangeUnderReturn:
        return GlobalColorEnum.Gray900
      case OrderStatusTypeEnum.Canceled:
      case OrderStatusTypeEnum.Confirmed:
      case OrderStatusTypeEnum.ReturnReturned:
      case OrderStatusTypeEnum.ReturnConfirmed:
      case OrderStatusTypeEnum.ExchangeConfirmed:
        return GlobalColorEnum.Gray400
    }
  }

  const getOrderStatusText = (): OrderStatusEnum => {
    if (
      orderItem.approvedTransaction.payMethodType === 'VBANK' &&
      orderItem.approvedTransaction.transactionStatus === TransactionTransactionStatus.UnpaidCanceled
    ) {
      return OrderStatusEnum.UnpaidCanceled
    }

    switch (orderStatus) {
      case OrderStatusTypeEnum.UnconfirmedPayment:
        return OrderStatusEnum.UnconfirmedPayment
      case OrderStatusTypeEnum.NewOrder:
        return OrderStatusEnum.NewOrder
      case OrderStatusTypeEnum.ReadyForShipping:
        return OrderStatusEnum.NewOrder
      case OrderStatusTypeEnum.Shipping:
        return OrderStatusEnum.NewOrder
      case OrderStatusTypeEnum.Shipped:
        return OrderStatusEnum.NewOrder
      case OrderStatusTypeEnum.CancelRequested:
        return OrderStatusEnum.CancelRequested
      case OrderStatusTypeEnum.Canceled:
        return OrderStatusEnum.Canceled
      case OrderStatusTypeEnum.Confirmed:
        return OrderStatusEnum.Confirmed
      case OrderStatusTypeEnum.ReturnRequested:
        return OrderStatusEnum.ReturnRequested
      case OrderStatusTypeEnum.ReturnUnderReturn:
        return OrderStatusEnum.ReturnUnderReturn
      case OrderStatusTypeEnum.ReturnReturned:
        return OrderStatusEnum.ReturnReturned
      case OrderStatusTypeEnum.ReturnConfirmed:
        return OrderStatusEnum.ReturnConfirmed
      case OrderStatusTypeEnum.ExchangeRequested:
        return OrderStatusEnum.ExchangeRequested
      case OrderStatusTypeEnum.ExchangeUnderReturn:
        return OrderStatusEnum.ExchangeUnderReturn
      case OrderStatusTypeEnum.ExchangeConfirmed:
        return OrderStatusEnum.ExchangeConfirmed
      default:
        return OrderStatusEnum.UnconfirmedPayment
    }
  }

  return (
    <div data-testid="action-power-list-order-item-card">
      <StyledCardSection>
        <StyledStatusBox>
          <TextLegacy weight={700} color={getOrderStatusTextColor(orderStatus as OrderStatusTypeEnum)} size={16}>
            {getOrderStatusText()}
          </TextLegacy>
        </StyledStatusBox>
        <StyledProductMetaBox>
          <LinkButton url={productDetailLink}>
            <ListOrderItemProductMeta productInfo={_productInfo} />
          </LinkButton>
        </StyledProductMetaBox>
        {isRenderAddCartButton(orderStatus as OrderStatusTypeEnum) && (
          <StyledAddCartButtonBox>
            <AddCartButton
              disabled={isDisabledAddCartButton()}
              onClick={() => handleAddCartOrderItem?.(quantity, productOption.id, productPromotion.id)}
            />
          </StyledAddCartButtonBox>
        )}
        {showActionButtons && (
          <ActionPowerOrderItemActionButtons
            orderId={orderId}
            orderButtonsTrackInfo={orderButtonsTrackInfo}
            orderItemId={orderItemId}
            orderItemStatus={orderStatus as OrderStatusTypeEnum}
            confirmCancelAll={confirmCancelAll}
            canWriteReview={canWriteReview()}
            actionPowerLink={actionPowerOrder?.orderUrl}
          />
        )}
        {isAutoConfirmText && (
          <StyledAutoConfirmText>
            <FlatIcon type={IconNameEnum.IcInfo} color={color.gray400} size={IconSizeEnum.Size16} />
            <Typo variant={TypoVariant.Body5Regular} color={color.gray400}>
              디지털상품은 자동으로 구매확정이 이루어져요
            </Typo>
          </StyledAutoConfirmText>
        )}
      </StyledCardSection>
      <OrderItemSweetTracker shippingTrackInfo={shippingTrackInfo} />
      <ModalShippingNumberSelect
        shippingTrackNumbers={shippingNumbersForSweetTrackers}
        useModalProps={useSelectShippingModalProps}
        handleShippingNumberSelected={(selectedShippingNumbersForSweetTracker) => {
          openSweetTracker(selectedShippingNumbersForSweetTracker)
        }}
      />
      <ModalMoreOrdersButtons useModalProps={useSelectMoreButtonsModalProps} />
    </div>
  )
}

const StyledCardSection = styled.div`
  background-color: ${(props) => props.theme.color.grayWhite};
  padding: 16px;
`

const StyledStatusBox = styled.div``

const StyledProductMetaBox = styled.div`
  margin-top: 16px;
`

const StyledAddCartButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`

const StyledAutoConfirmText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 1.6rem;
`

export default ActionPowerListOrderItemCard
