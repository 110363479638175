import { FC } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { UserContainer } from '@/containers/users/UserContainer'
import { formatPhoneNumber } from '@/utils/utilString'

const OrderDetailSectionOrdererInfo: FC = () => {
  const { me } = UserContainer.useContainer()
  if (!me) {
    return null
  }
  const { name, email, mobileNumber } = me
  return (
    <StyledContainer>
      <TitleRow>
        <TextLegacy size={{ xs: 16, md: 18 }} weight="bold">
          주문자 정보
        </TextLegacy>
      </TitleRow>
      <>
        <StyledRow>{`${name} (${formatPhoneNumber(mobileNumber || '')})`}</StyledRow>
        <StyledRow>
          <TextLegacy>{email}</TextLegacy>
        </StyledRow>
      </>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  padding: 2rem;
  background-color: ${(props) => props.theme.color.grayWhite};
`
const TitleRow = styled.div`
  margin-bottom: 3rem;
`

const StyledRow = styled(Row)`
  margin-bottom: 0.7rem;
`

export default OrderDetailSectionOrdererInfo
