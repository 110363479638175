import { FC } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import { TextEllipsis, TextLegacy } from '@/components/common'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { FIXED_GID } from '@/constants/legacy/constData'
import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import { OrderProductInfoType } from '@/constants/legacy/constType'
import { currencyText } from '@/utils/utilNumber'

type ListOrderItemProductMetaProps = {
  productInfo: OrderProductInfoType
}

const ListOrderItemProductMeta: FC<ListOrderItemProductMetaProps> = ({ productInfo }) => {
  if (!productInfo) {
    return null
  }

  const { thumbnail, productName, optionName, customerPrice, quantity, seller, image } = productInfo
  const blurDataURL = `${S3_HOST_URL}/${thumbnail}`
  const _src = `${S3_HOST_URL}/${image}`

  return (
    <StyledWrapper>
      <StyledImageSection>
        <StyledImageBox>
          <Image
            unoptimized
            objectFit="contain"
            width={76}
            height={76}
            src={_src}
            alt="Product Thumbnail"
            placeholder="blur"
            blurDataURL={blurDataURL}
          />
        </StyledImageBox>
      </StyledImageSection>
      <StyledContentSection>
        {seller && (
          <StyledSellerNameBox>
            <TextLegacy size={13} color={GlobalColorEnum.Gray700}>{`${seller.name}`}</TextLegacy>
            {seller.id !== FIXED_GID.SELLER.FITPET && (
              <TextLegacy size={13} color={GlobalColorEnum.Gray700}>
                (업체 직배송)
              </TextLegacy>
            )}
          </StyledSellerNameBox>
        )}
        <StyledProductNameBox>
          <TextEllipsis lineNumber={2} size={14}>
            {productName}
          </TextEllipsis>
        </StyledProductNameBox>
        <StyledOptionNameBox>
          <TextEllipsis color={GlobalColorEnum.Gray400} size={14} lineNumber={1}>
            {`${optionName}`}
          </TextEllipsis>
        </StyledOptionNameBox>
        <StyledPurchaseInfoBox>
          <TextLegacy weight={700} size={16}>
            {currencyText(customerPrice)}
          </TextLegacy>
          <TextLegacy size={16}>{` / ${quantity}개`}</TextLegacy>
        </StyledPurchaseInfoBox>
      </StyledContentSection>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
`

const StyledImageSection = styled.div`
  margin-right: 10px;
`

const StyledImageBox = styled.div`
  width: 76px;
  height: 76px;
  border-radius: 8px;
  overflow: hidden;
`

const StyledContentSection = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledSellerNameBox = styled.div`
  padding-bottom: 6px;
`
const StyledProductNameBox = styled.div``
const StyledOptionNameBox = styled.div`
  padding: 6px 0px 8px 0px;
`
const StyledPurchaseInfoBox = styled.div``

export default ListOrderItemProductMeta
