import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { Image, TextLegacy } from '@/components/common'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { isOrderStatusCancel, isOrderStatusExchange, isOrderStatusReturn } from '@/constants/legacy/constData'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { IModelReturnInfoImage } from '@/containers/models/modelReturnInfoImage'

type OrderDetailSectionCancelReasonInfoProps = {
  status: OrderStatusTypeEnum
  orderItem: IModelOrderItem
}

const OrderDetailSectionCancelReasonInfo: FC<OrderDetailSectionCancelReasonInfoProps> = ({ status, orderItem }) => {
  const getStatusText = () => {
    if (isOrderStatusCancel(status)) {
      return '취소'
    }
    if (isOrderStatusReturn(status)) {
      return '반품'
    }
    if (isOrderStatusExchange(status)) {
      return '교환'
    }
  }

  const {
    getCancelRequestedAtText,
    getCancelFinishedAtText,
    getCancelReasonText,
    getCancelGuideMessageText,
    getCancelImages,
  } = orderItem

  return (
    <StyledContainer>
      <TitleRow>
        <TextLegacy size={{ xs: 16, md: 18 }} weight="bold">
          상세정보
        </TextLegacy>
      </TitleRow>
      <RenderRow title={`${getStatusText()} 접수일`} value={getCancelRequestedAtText()} />
      <RenderRow title={`${getStatusText()} 완료일`} value={getCancelFinishedAtText()} />
      <RenderRow title={`${getStatusText()}사유`} value={getCancelReasonText()} />
      {getCancelGuideMessageText() && <RenderRow title="관리자 안내" value={getCancelGuideMessageText()!} />}
      {getCancelImages() && <RenderImages images={getCancelImages()!} />}
    </StyledContainer>
  )
}

const RenderRow = ({ title, value }: { title: string; value: string }) => (
  <ItemRow align="top">
    <Col flex="100px">
      <TextLegacy color="gray400">{title}</TextLegacy>
    </Col>
    <Col>
      <TextLegacy>{value}</TextLegacy>
    </Col>
  </ItemRow>
)

const RenderImages = ({ images }: { images: IModelReturnInfoImage[] }) => {
  return (
    <>
      <ItemRow align="top">
        <Col>
          <TextLegacy color="gray400">첨부한 사진</TextLegacy>
        </Col>
      </ItemRow>
      <Row>
        {images.map((image, index) => (
          <Col key={index} xs={{ span: 7 }} md={{ span: 5 }}>
            <Image src={image.thumbnail} layout="responsive" width={200} height={200} alt="image" objectFit="cover" />
          </Col>
        ))}
      </Row>
    </>
  )
}

const TitleRow = styled.div`
  margin-bottom: 3rem;
`

const ItemRow = styled(Row)`
  padding: 0.5rem 0;
`

const StyledContainer = styled.div`
  padding: 2rem;
  background-color: ${(props) => props.theme.color.grayWhite};
`

export default OrderDetailSectionCancelReasonInfo
