import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { ReviewStarRating } from '@/components/common/etc'
import { LikeProductReview, ReviewImages } from '@/components/domains/etc'
import ReportProductReview from '@/components/domains/etc/ReportProductReview'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IModelReview } from '@/containers/models/modelReview'
import { BOARD_DATE_DISPLAY_FORMAT, formatDate } from '@/utils/utilDate'

type ListItemProductReviewsProps = {
  review: IModelReview
}

const ListItemProductReview: FC<ListItemProductReviewsProps> = ({ review }) => {
  const {
    id: reviewId,
    username,
    productOptionName,
    reviewBody,
    score,
    reviewReviewImages,
    isPhotoReview,
    isLiked,
    createdAt,
    getLikedCount,
  } = review

  return (
    <StyledContainer>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={5}>
            <Col>
              <ReviewStarRating rating={score} />
            </Col>
            <Col>
              <TextLegacy size={{ xs: 12, md: 14 }}>{username}</TextLegacy>
            </Col>
          </Row>
        </Col>
        <Col>
          <TextLegacy size={{ xs: 12, md: 14 }} color="gray400">
            {formatDate(createdAt, BOARD_DATE_DISPLAY_FORMAT)}
          </TextLegacy>
        </Col>
      </Row>
      <Row>
        <TextLegacy size={{ xs: 12, md: 14 }} color="gray400">
          {`상품 : ${productOptionName}`}
        </TextLegacy>
      </Row>

      {isPhotoReview && (
        <StyledRow>
          {/* @ts-ignore TODO typescript 오류 수정 필요 */}
          <ReviewImages images={reviewReviewImages} />
        </StyledRow>
      )}

      <StyledRow>
        <Col>
          <TextLegacy size={{ xs: 13, md: 15 }} color="black">
            {reviewBody}
          </TextLegacy>
        </Col>
      </StyledRow>

      <Row align={'middle'} justify={'space-between'} style={{ marginTop: 12 }}>
        <Col>
          <ReportProductReview reviewId={reviewId} />
        </Col>
        <Col>
          <LikeProductReview reviewId={reviewId} isLikedDefault={isLiked} likedCountDefault={getLikedCount()} />
        </Col>
      </Row>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  padding: 20px;
  border-bottom: 0.1rem solid ${GlobalColorEnum.Gray70};
`

const StyledRow = styled(Row)`
  margin-top: 15px;
`

export default ListItemProductReview
