import { FC, ReactNode } from 'react'
import { Col, Row } from 'antd'
import { isIOS, isMobileSafari } from 'react-device-detect'
import styled from 'styled-components'
import { Modal, TextLegacy } from '@/components/common'
import { ModalBaseProps } from '@/components/common/modals/ModalBase'

type ModalPolicyContent = {
  title: string
  content?: ReactNode
}

export type ModalPolicyData = {
  subContents?: ModalPolicyContent[]
} & ModalPolicyContent

type ModalPolicyProps<T> = {} & ModalBaseProps<T>

const ModalPolicy: FC<ModalPolicyProps<any>> = ({ useModalProps }) => {
  const policyData = useModalProps.data as ModalPolicyData

  return (
    <Modal
      useModalProps={useModalProps}
      buttonType="ACTION"
      okText="확인"
      onOk={() => {
        useModalProps.hideModal()

        if (isIOS || isMobileSafari) {
          const scrollBottom = document?.body?.scrollHeight || 10
          window.scrollTo({ top: scrollBottom - 10, behavior: 'smooth' })
        }
      }}
    >
      <ModalBody>
        <Row justify="center">
          <TitleCol>
            <TextLegacy size={{ xs: 16, md: 18 }} weight="bold">
              {policyData.title}
            </TextLegacy>
          </TitleCol>
        </Row>
        <Row>
          {useModalProps.visible && policyData.content && (
            <Col>
              <TextLegacy size={{ xs: 10, md: 12 }}>{policyData.content}</TextLegacy>
            </Col>
          )}
        </Row>
        {policyData.subContents?.map((subContent, index) => {
          return (
            <div key={index}>
              <Row>
                <SubTitleCol>
                  <TextLegacy size={{ xs: 14, md: 16 }}>{subContent.title}</TextLegacy>
                </SubTitleCol>
              </Row>
              <Row>
                <SubContentCol>
                  <SubContentBody>
                    <TextLegacy size={{ xs: 10, md: 12 }}>{subContent.content}</TextLegacy>
                  </SubContentBody>
                </SubContentCol>
              </Row>
            </div>
          )
        })}
      </ModalBody>
    </Modal>
  )
}

const ModalBody = styled.div`
  max-height: 500px;
  height: 60vh;
  overflow: hidden scroll;
`

const TitleCol = styled(Col)`
  text-align: center;
  margin-bottom: 12px;
`

const SubTitleCol = styled(Col)`
  text-align: center;
  margin-top: 12px;
`

const SubContentCol = styled(Col)`
  text-align: center;
  background: ${(props) => props.theme.color.gray50};
  border-radius: 3px;
  margin-top: 9px;
  margin-bottom: 8px;
`

const SubContentBody = styled.div`
  height: 190px;
  overflow: hidden scroll;
`

export default ModalPolicy
