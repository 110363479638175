import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import { ModalMoreOrdersButtons, ModalShippingNumberSelect } from '@/components/domains/modals'
import { ListOrderItemProductMeta, OrderItemActionButtons, OrderItemSweetTracker } from '@/components/domains/order'
import AddCartButton from '@/components/domains/order/ListOrderItemCard/AddCartButton'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { ShippingNumbersForSweetTracker } from '@/constants/legacy/constType'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { useOrderHistoryTracker } from '@/containers/event-tracker/order-history-tracker'
import { useModal } from '@/containers/hooks'
import { OrderButtonsTrackInfoType } from '@/containers/hooks/order/useOrderConfirm'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { IModelProductOption } from '@/containers/models/modelProductOption'
import { IModelProductPromotion } from '@/containers/models/modelProductPromotion'
import { CommerceUserPayMethodType, TransactionPgProviderType } from '@/graphql/generated/schemas'
import { RootState } from '@/stores/store'

type Props = {
  orderItem: IModelOrderItem
  orderId: string
  showActionButtons?: boolean
  confirmCancelAll: () => void
  handleAddCartOrderItem?: (quantity: number, productOptionId: string, productPromotionId?: string) => Promise<void>
  isMembershipBenefited: boolean
  refreshConfirmButtonClick?: () => void
}

const ListOrderItemCard: FC<Props> = ({
  orderItem,
  orderId,
  confirmCancelAll,
  showActionButtons = true,
  handleAddCartOrderItem,
  isMembershipBenefited = false,
  refreshConfirmButtonClick = undefined,
}) => {
  const {
    id: orderItemId,
    orderStatus,
    canWriteReview,
    getOrderStatusText,
    getOrderProductInfo,
    productPromotion,
    orderConfirmMileage,
    membershipOrderConfirmMileage,
    productOption,
    quantity,
    brand,
    supplyType,
  } = orderItem

  const isPurchaseMileage: boolean =
    orderItem.approvedTransaction.payMethodType === CommerceUserPayMethodType.ZeroPrice &&
    orderItem.approvedTransaction.pgProviderType === TransactionPgProviderType.Fitpet

  const orderConfirmMileageAmount = isMembershipBenefited ? membershipOrderConfirmMileage : orderConfirmMileage
  const initData = useSelector((rootState: RootState) => rootState.reduxDataReducers.initData)
  const _productInfo = getOrderProductInfo()
  const { productId, productName, optionName } = _productInfo
  const { id: productPromotionId } = productPromotion
  const { useModalProps: useSelectShippingModalProps } = useModal()
  const { useModalProps: useSelectMoreButtonsModalProps } = useModal()
  const [shippingTrackInfo, setShippingTrackInfo] = useState<{ companyCode: string; shippingNumber: string }>()
  const { trackClickOrderListShippingInfoButtonEvent } = useOrderHistoryTracker()

  const orderButtonsTrackInfo: OrderButtonsTrackInfoType = {
    product_id: productId,
    option_id: Number(orderItem.productOption._id),
    productName,
    optionName,
  }

  const productDetailLink =
    ROUTES.PRODUCTS.DETAIL.replace('[productId]', `${productId}`) + `?promotion=${productPromotionId}`

  const getShippingTrackNumbers = () => {
    const shippingNumbersForSweetTrackers: ShippingNumbersForSweetTracker[] = []
    const sweetTrackerCode = initData?.getSweetTrackerCode(orderItem.shippingCompanyId)
    if (sweetTrackerCode) {
      orderItem.shippingNumbers.forEach((shippingNumber) => {
        shippingNumbersForSweetTrackers.push({ companyCode: sweetTrackerCode, shippingNumber })
      })
    }
    return shippingNumbersForSweetTrackers
  }

  const shippingNumbersForSweetTrackers = getShippingTrackNumbers()

  const openSweetTracker = (shippingTrackNumber: ShippingNumbersForSweetTracker) => {
    setShippingTrackInfo({
      companyCode: shippingTrackNumber.companyCode,
      shippingNumber: shippingTrackNumber.shippingNumber,
    })
    useSelectShippingModalProps.hideModal()
  }

  const isRenderAddCartButton = (status: OrderStatusTypeEnum) => {
    switch (status) {
      case OrderStatusTypeEnum.Shipped:
      case OrderStatusTypeEnum.Canceled:
      case OrderStatusTypeEnum.CancelRequested:
      case OrderStatusTypeEnum.Confirmed:
        return isListOrderHistory() && handleAddCartOrderItem
      default:
        return false
    }
  }

  const isListOrderHistory = () => {
    const { product } = productOption
    return Boolean(product)
  }

  const isDeall100 = (productPromotion: IModelProductPromotion) => {
    if (!productPromotion) {
      return false
    }
    const { productPromotionType } = productPromotion
    const _isDeal100 = productPromotionType === 'DEAL_100' || productPromotionType === 'DEAL_990'
    return _isDeal100
  }

  const isDisablePurchase = (productOption: IModelProductOption) => {
    const { product } = productOption
    const isSoldOut =
      product.isRealSoldOut || product.isSoldOut || productOption.stockQuantity === 0 || productOption.isSoldOut
    const disabled = product.status.value !== 'APPROVED' || !productOption.isUse || productOption.isDelete

    return isSoldOut || disabled
  }

  const isDisabledAddCartButton = () => {
    if (isDeall100(productPromotion)) {
      return true
    }

    return isDisablePurchase(productOption)
  }

  const handleShippingTrack = () => {
    trackClickOrderListShippingInfoButtonEvent()
    if (shippingNumbersForSweetTrackers.length === 1) {
      openSweetTracker(shippingNumbersForSweetTrackers[0])
      return
    }
    if (shippingNumbersForSweetTrackers.length > 1) {
      useSelectShippingModalProps.showModal()
    }
  }

  const getOrderStatusTextColor = (orderStatus: OrderStatusTypeEnum) => {
    switch (orderStatus) {
      case OrderStatusTypeEnum.CancelRequested:
        return GlobalColorEnum.Red500
      case OrderStatusTypeEnum.UnconfirmedPayment:
      case OrderStatusTypeEnum.NewOrder:
      case OrderStatusTypeEnum.ReadyForShipping:
      case OrderStatusTypeEnum.Shipping:
      case OrderStatusTypeEnum.Shipped:
        return GlobalColorEnum.Blue500
      case OrderStatusTypeEnum.ReturnRequested:
      case OrderStatusTypeEnum.ReturnUnderReturn:
      case OrderStatusTypeEnum.ExchangeRequested:
      case OrderStatusTypeEnum.ExchangeUnderReturn:
        return GlobalColorEnum.Gray900
      case OrderStatusTypeEnum.Canceled:
      case OrderStatusTypeEnum.Confirmed:
      case OrderStatusTypeEnum.ReturnReturned:
      case OrderStatusTypeEnum.ReturnConfirmed:
      case OrderStatusTypeEnum.ExchangeConfirmed:
        return GlobalColorEnum.Gray400
    }
  }

  return (
    <>
      <StyledCardSection>
        <StyledStatusBox>
          <TextLegacy weight={700} color={getOrderStatusTextColor(orderStatus as OrderStatusTypeEnum)} size={16}>
            {getOrderStatusText()}
          </TextLegacy>
        </StyledStatusBox>
        <StyledProductMetaBox>
          <LinkButton url={productDetailLink}>
            <ListOrderItemProductMeta productInfo={_productInfo} />
          </LinkButton>
        </StyledProductMetaBox>
        {isRenderAddCartButton(orderStatus as OrderStatusTypeEnum) && (
          <StyledAddCartButtonBox>
            <AddCartButton
              disabled={isDisabledAddCartButton()}
              onClick={() => handleAddCartOrderItem?.(quantity, productOption.id, productPromotion.id)}
            />
          </StyledAddCartButtonBox>
        )}
        {showActionButtons && (
          <OrderItemActionButtons
            orderId={orderId}
            orderButtonsTrackInfo={orderButtonsTrackInfo}
            orderConfirmMileage={orderConfirmMileageAmount}
            orderItemId={orderItemId}
            orderItemStatus={orderStatus as OrderStatusTypeEnum}
            confirmCancelAll={confirmCancelAll}
            canWriteReview={canWriteReview()}
            handleShippingTrack={() => handleShippingTrack()}
            useSelectMoreButtonsModalProps={useSelectMoreButtonsModalProps}
            refreshConfirmButtonClick={refreshConfirmButtonClick}
            isPurchaseMileage={isPurchaseMileage}
          />
        )}
      </StyledCardSection>
      <OrderItemSweetTracker shippingTrackInfo={shippingTrackInfo} />
      <ModalShippingNumberSelect
        shippingTrackNumbers={shippingNumbersForSweetTrackers}
        useModalProps={useSelectShippingModalProps}
        handleShippingNumberSelected={(selectedShippingNumbersForSweetTracker) => {
          openSweetTracker(selectedShippingNumbersForSweetTracker)
        }}
      />
      <ModalMoreOrdersButtons useModalProps={useSelectMoreButtonsModalProps} />
    </>
  )
}

const StyledCardSection = styled.div`
  background-color: ${(props) => props.theme.color.grayWhite};
  padding: 16px;
`

const StyledStatusBox = styled.div``

const StyledProductMetaBox = styled.div`
  margin-top: 16px;
`

const StyledAddCartButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`

export default ListOrderItemCard
