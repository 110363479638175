import ProductRecentlySliderWithAddToCart from '@/components/domains/search/ProductRecentlySlider'
import { PopularKeywords, RecentSearchKeywords, RecommendKeywords } from '@/components/domains/search/index'
import PopularBrandsB from '@/components/domains/search/search-body-contents/components/popular-brands-B'
import type { GraphQLProductType } from '@/utils/product/product-card.util'

interface SearchBodyContentsProps {
  recentlyProducts: GraphQLProductType[]
}

export const SearchBodyContents = ({ recentlyProducts }: SearchBodyContentsProps) => {
  return (
    <>
      <section>
        <RecentSearchKeywords />
      </section>
      <section>
        <PopularBrandsB />
      </section>
      {!!recentlyProducts?.length && (
        <section>
          <ProductRecentlySliderWithAddToCart data={recentlyProducts} />
        </section>
      )}
      <section>
        <RecommendKeywords />
      </section>
      <section>
        <PopularKeywords />
      </section>
    </>
  )
}
