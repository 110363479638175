import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IModelCustomerAddress } from '@/containers/models/modelCustomerAddress'
import { formatPhoneNumber } from '@/utils/utilString'

type OrderDetailSectionDeliveryInfoProps = {
  customerAddress: IModelCustomerAddress
}

const OrderDetailSectionDeliveryInfo: FC<OrderDetailSectionDeliveryInfoProps> = ({ customerAddress }) => {
  const { mobileNumber, name, getFullAddressText, message } = customerAddress

  return (
    <StyledContainer>
      <TitleRow>
        <TextLegacy size={{ xs: 16, md: 18 }} weight="bold">
          배송정보
        </TextLegacy>
      </TitleRow>
      <StyledRow>
        <TextLegacy>{name}</TextLegacy>
      </StyledRow>
      <StyledRow>
        <TextLegacy>{formatPhoneNumber(mobileNumber)}</TextLegacy>
      </StyledRow>
      <StyledRow>
        <TextLegacy>{getFullAddressText()}</TextLegacy>
      </StyledRow>
      <RequestMessageRow gutter={10} wrap={false}>
        <Col>
          <TextLegacy>배송 요청사항</TextLegacy>
          <span style={{ paddingRight: 10 }} />
          <TextLegacy color="gray400">{message || '-'}</TextLegacy>
        </Col>
      </RequestMessageRow>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.color.grayWhite};
  padding: 2rem;
`

const TitleRow = styled.div`
  margin-bottom: 30px;
`

const StyledRow = styled(Row)`
  margin-bottom: 7px;
`

const RequestMessageRow = styled(Row)`
  border-top: 1px solid ${GlobalColorEnum.Gray70};
  padding-top: 12px;
  margin-top: 12px;
`

export default OrderDetailSectionDeliveryInfo
