import { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { match } from 'ts-pattern'
import { ImageAsset } from '@/components/common'
import Button from '@/components/common/buttons/Button'
import { Typo, TypoVariant } from '@/components/common/typography'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'
import { useAfterLogin } from '@/containers/hooks/auth'
import usePetType from '@/containers/hooks/usePetType'
import { UserContainer } from '@/containers/users/UserContainer'
import { AdPetType } from '@/graphql/generated/schemas'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { isInAppFlag } from '@/utils/utilBridge'

interface CartEmptyProps {
  isPersonalizationTab?: boolean
  title?: string
}
const CartEmpty = ({ isPersonalizationTab, title }: CartEmptyProps) => {
  const { color } = useTheme()
  const { getPetType } = usePetType()
  const { push, isReady } = useCustomRouter()
  const { me } = UserContainer.useContainer()
  const { pathname, query } = useCustomRouter()
  const { loginOperator } = useAfterLogin()
  const [petType, setPetType] = useState<AdPetType>()

  const onClickShopping = () => {
    if (isInAppFlag) {
      appBridgeProvider((bridge: Bridge) => bridge.goDeepLink('fitpetmall', '/main'))
    } else {
      push(ROUTES.INDEX)
    }
  }

  const onClickLogin = () => {
    loginOperator(`${pathname}?tab=${query.tab}`)
  }

  const emptyContent = () => {
    return match({ isLogin: !!me, isPersonalizationTab: !!isPersonalizationTab })
      .with({ isLogin: false, isPersonalizationTab: true }, () => (
        <>
          <StyledEmptyDesc>
            <Typo color={color.gray500} variant={TypoVariant.Body1Regular}>
              로그인하면 {title}을 볼 수 있어요
            </Typo>
          </StyledEmptyDesc>
          <StyledEmptyButton data-cy="empty-cart-button" width="11.8rem" height="4.8rem" onClick={onClickLogin}>
            <Typo variant={TypoVariant.Body1Bold} color={color.blue500}>
              로그인하기
            </Typo>
          </StyledEmptyButton>
        </>
      ))
      .otherwise(() => (
        <>
          <StyledEmptyButton data-cy="empty-cart-button" width="11.8rem" height="4.8rem" onClick={onClickShopping}>
            <Typo variant={TypoVariant.Body1Bold} color={color.blue500}>
              쇼핑하러 가기
            </Typo>
          </StyledEmptyButton>
        </>
      ))
  }

  useEffect(() => {
    if (isReady) {
      setPetType(getPetType())
    }
  }, [isReady])

  return (
    <>
      <StyledWrapper data-cy="empty-cart" className="empty-cart">
        {petType && <StyledEmptyImage name={petType === 'CAT' ? 'cart_empty_cat' : 'cart_empty_dog'} size={140} />}

        <StyledEmptyTitle>
          <Typo variant={TypoVariant.Body1SemiBold} color={color.gray500}>
            {getPetType() === 'CAT' ? '상품이 없다냥..' : '상품이 없다개..'}
          </Typo>
        </StyledEmptyTitle>
        {emptyContent()}
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  padding: 6rem 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const StyledEmptyImage = styled(ImageAsset)``

const StyledEmptyTitle = styled.div`
  margin-top: 1.6rem;
`

const StyledEmptyDesc = styled.div`
  margin-top: 0.4rem;
`

const StyledEmptyButton = styled(Button.Tertiary)`
  margin-top: 2.4rem;
  border: 1px solid
    ${({
      theme: {
        color: { blue500 },
      },
    }) => blue500};
`

export default CartEmpty
