import { FC } from 'react'
import { Col } from 'antd'
import RectangleButtonLegacy, { RectangleButtonLegacyProps } from '@/components/common/buttons/RectangleButtonLegacy'
import LayoutBottomFixed, { LayoutBottomFixedProps } from '@/components/domains/layouts/LayoutBottomFixed'
import { GlobalColorEnum } from '@/constants/global-color.enum'

type BottomFixedRectangleButtonProps = { isBoxShadowEnabled?: boolean } & RectangleButtonLegacyProps &
  LayoutBottomFixedProps

const BottomFixedRectangleButtonLegacy: FC<BottomFixedRectangleButtonProps> = ({
  backgroundColor = GlobalColorEnum.GrayWhite,
  padding,
  position,
  isBoxShadowEnabled = true,
  ...rectangleButtonProps
}) => {
  return (
    <LayoutBottomFixed
      backgroundColor={backgroundColor}
      position={position}
      padding={padding}
      isBoxShadowEnabled={isBoxShadowEnabled}
    >
      <Col flex="1">
        <RectangleButtonLegacy radius={8} {...rectangleButtonProps} />
      </Col>
    </LayoutBottomFixed>
  )
}

export default BottomFixedRectangleButtonLegacy
