import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { OrderButtonText } from '@/constants/order-type.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'

export const useOrderHistoryTracker = () => {
  const { triggerCustomEvent } = useTracker()
  const trackClickOrderListShippingInfoButtonEvent = () => {
    triggerCustomEvent(FitpetMallEventEnum.ClickOrderListShippingInfoButton, {
      buttonText: OrderButtonText.ShippingTracking,
    })
  }

  return {
    trackClickOrderListShippingInfoButtonEvent,
  }
}
