import { FC, useState } from 'react'
import { Row } from 'antd'
import { useDispatch } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { Text } from '@/components/common'
import { Button } from '@/components/common/buttons'
import { Typo, TypoVariant } from '@/components/common/typography'
import ROUTES from '@/constants/legacy/constRoutes'
import { OrderCancelTypeEnum } from '@/constants/order-cancel-type.enum'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useActionPowerEventTracker } from '@/containers/event-tracker/action-power.event-tracker'
import { useCustomRouter, useModal } from '@/containers/hooks'
import { OrderButtonsTrackInfoType } from '@/containers/hooks/order/useOrderConfirm'
import { useIntegrateActionPowerOrderItemMutation } from '@/graphql/generated/hooks'
import { doSetBeforeHistoryLength } from '@/stores/reduxUI'
import { convertGoReviewFromOrdersParams } from '@/utils/event-tracker/convertParams/convertProductParams'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { openExternalWithBridgeUrl } from '@/utils/utilURL'

type OrderItemActionButtonsProps = {
  orderId: string
  orderButtonsTrackInfo: OrderButtonsTrackInfoType
  orderItemId: string
  orderItemStatus: OrderStatusTypeEnum
  canWriteReview: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  confirmCancelAll: () => void
  actionPowerLink: string | undefined
}

const ActionPowerOrderItemActionButtons: FC<OrderItemActionButtonsProps> = ({
  orderId,
  orderItemId,
  orderButtonsTrackInfo,
  orderItemStatus,
  canWriteReview,
  actionPowerLink,
}) => {
  const [savedLink, setSavedLink] = useState<string | undefined>(actionPowerLink)
  const { color, fontWeight, spacing } = useTheme()
  const { push } = useCustomRouter()
  const { useModalProps } = useModal()
  const tracker = useTracker()
  const dispatch = useDispatch()
  const [mutate] = useIntegrateActionPowerOrderItemMutation({
    variables: {
      id: orderItemId,
    },
  })

  const { trackClickOrderListOtherInfoButtonEvent } = useActionPowerEventTracker()

  const onPhotoClick = async (title: string) => {
    trackClickOrderListOtherInfoButtonEvent({
      buttonText: title,
    })
    if (savedLink) {
      openExternalWithBridgeUrl(savedLink)
    } else {
      const data = await mutate()
      const link = data.data?.integrateActionPowerOrderItem?.orderItem?.actionPowerOrder?.orderUrl
      setSavedLink(link)
      openExternalWithBridgeUrl(link || '')
    }
  }

  const onCancel = (orderCancelType: OrderCancelTypeEnum) => {
    useModalProps.hideModal()
    window.history.pushState(undefined, '', window.location.href)
    dispatch(doSetBeforeHistoryLength(window.history.length - 1))
    push({
      pathname: ROUTES.MYPAGE.ORDER_CANCEL.BASE,
      query: {
        cancelType: orderCancelType,
        orderId,
        orderItemId,
      },
    })
  }

  const openDetail = () => {
    push({
      pathname: ROUTES.MYPAGE.ORDER_DETAIL,
      query: {
        orderId,
        orderItemId,
        status: orderItemStatus,
      },
    })
  }

  const goReview = () => {
    tracker.triggerCustomEvent(
      FitpetMallEventEnum.ClickOrderlistReviewButton,
      convertGoReviewFromOrdersParams(orderButtonsTrackInfo)
    )
    push({
      pathname: ROUTES.MYPAGE.REVIEWS.WRITE,
      query: {
        orderItemId,
        prePath: ROUTES.MYPAGE.ORDERS,
      },
    })
  }

  const showRequestCancel = () => {
    switch (orderItemStatus) {
      case OrderStatusTypeEnum.UnconfirmedPayment:
        return true
      default:
        return false
    }
  }

  const showUploadImage = () => {
    switch (orderItemStatus) {
      case OrderStatusTypeEnum.NewOrder:
        return true
      default:
        return false
    }
  }

  const showCreatingImage = () => {
    switch (orderItemStatus) {
      case OrderStatusTypeEnum.Shipping:
      case OrderStatusTypeEnum.Shipped:
        return true
      default:
        return false
    }
  }

  const showDownloadImage = () => {
    switch (orderItemStatus) {
      case OrderStatusTypeEnum.Confirmed:
        return true
      default:
        return false
    }
  }

  const showCancelInfo = () => {
    switch (orderItemStatus) {
      case OrderStatusTypeEnum.CancelRequested:
      case OrderStatusTypeEnum.Canceled:
        return true
      default:
        return false
    }
  }

  const OrderCancelButton = () => {
    return (
      <StyledButtonGrayFill
        onClick={() => onCancel(OrderCancelTypeEnum.Cancel)}
        width={spacing.per100}
        height={spacing.size46}
      >
        <Typo variant={TypoVariant.Body3Medium} color={color.gray900}>
          주문 취소
        </Typo>
      </StyledButtonGrayFill>
    )
  }

  const DisableOrderCancelButton = () => {
    return (
      <StyledButtonGrayFill disabled={true} width={spacing.per100} height={spacing.size46}>
        <Typo variant={TypoVariant.Body3Medium} color={color.gray200}>
          주문 취소
        </Typo>
      </StyledButtonGrayFill>
    )
  }

  const CancelInfoButton = () => {
    return (
      <StyledButtonTertiary onClick={openDetail} width={spacing.per100} height={spacing.size46}>
        <Text.Body3 fontWeight={fontWeight.medium} fontColor={color.gray900}>
          취소정보
        </Text.Body3>
      </StyledButtonTertiary>
    )
  }

  const ReviewButton = () => {
    return (
      <StyledButtonTertiary onClick={() => goReview()} width={spacing.per100} height={spacing.size46}>
        <Typo variant={TypoVariant.Body3Medium} color={color.gray900}>
          리뷰쓰기
        </Typo>
      </StyledButtonTertiary>
    )
  }

  const PhotoButton = ({ title }: { title: string }) => {
    return (
      <StyledButtonSecondary
        data-testid="actinon-power-open-url"
        onClick={() => onPhotoClick(title)}
        width={spacing.per100}
        height={spacing.size46}
      >
        <Text.Body3 fontColor={color.blue500} fontWeight={fontWeight.bold}>
          {title}
        </Text.Body3>
      </StyledButtonSecondary>
    )
  }

  return (
    <>
      <StyledWrapper>
        {showCancelInfo() && <CancelInfoButton />}
        {showRequestCancel() && <OrderCancelButton />}
        {showUploadImage() && (
          <StyledMoreButtonSection>
            <OrderCancelButton />
            <PhotoButton title="사진 올리기" />
          </StyledMoreButtonSection>
        )}
        {showCreatingImage() && (
          <StyledMoreButtonSection>
            <DisableOrderCancelButton />
            <PhotoButton title="사진 제작중" />
          </StyledMoreButtonSection>
        )}
        {showDownloadImage() && (
          <StyledMoreButtonSection>
            {canWriteReview && <ReviewButton />}
            <PhotoButton title="사진 받기" />
          </StyledMoreButtonSection>
        )}
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled(Row)`
  margin-top: 1.2rem;
`

const StyledMoreButtonSection = styled.div`
  display: flex;
  width: 100%;
  gap: 0.7rem;
`

const StyledButtonGrayFill = styled(Button.GrayFill)`
  padding: 1.4rem;
`
const StyledButtonTertiary = styled(Button.Tertiary)`
  padding: 1.4rem;
`

const StyledButtonSecondary = styled(Button.Secondary)`
  padding: 1.4rem;
`

export default ActionPowerOrderItemActionButtons
