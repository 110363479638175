import { FC } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { ModalSlider } from '@/components/common/modals'
import { ModalBaseProps } from '@/components/common/modals/ModalBase'
import { GlobalColorEnum } from '@/constants/global-color.enum'

export type MoreButtonType = {
  name: string
  handleClick: () => void
}
type Props<T> = ModalBaseProps<T>

const ModalMoreOrdersButtons: FC<Props<MoreButtonType[]>> = ({ useModalProps }) => {
  const { data, hideModal } = useModalProps
  const buttons = data

  if (_.isEmpty(buttons)) {
    return null
  }

  return (
    <ModalSlider title="더보기" centerTitle titleNoBorder useModalProps={useModalProps}>
      <StyledWrapper>
        {buttons.map((button) => {
          return (
            <StyledButtonSection
              key={button.name}
              onClick={() => {
                button.handleClick()
                hideModal()
              }}
            >
              <TextLegacy size={15} weight={600}>
                {button.name}
              </TextLegacy>
            </StyledButtonSection>
          )
        })}
      </StyledWrapper>
    </ModalSlider>
  )
}

const StyledWrapper = styled.div`
  display: grid;
  gap: 10px;
  background-color: ${(props) => props.theme.color.grayWhite};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - 60px);
  ::-webkit-scrollbar {
    display: block;
  }
  padding: 16px;
`

const StyledButtonSection = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  span {
    :active {
      color: ${(props) => props.theme.color.blue500};
    }
  }

  cursor: pointer;
`

export default ModalMoreOrdersButtons
