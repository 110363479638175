import ROUTES from '@/constants/legacy/constRoutes'

export enum LocationEnum {
  Recommend = 'recommend',
  GnbHome = 'gnb_home',
  GnbOther = 'gnb_other',
  Display = 'display',
  EventSale = 'eventsale',
  Category = 'category',
  Brand = 'brand',
  Search = 'search',
  Deal100Won = '100_won_deal',
  RecentProduct = 'recentproduct',
  LikeProduct = 'likeproduct',
  ProductDetail = 'productdetail',
  ProductDetailMembershipMileage = 'productdetail_membership_mileage',
  ProductDetailMembershipInfo = 'productdetail_membership_info',
  Best = 'best',
  Bundled = 'bundled',
  Cart = 'cart',
  CartMembershipInfo = 'cart_membership_info',
  MyPage = 'mypage',
  Home = 'home',
  Banner = 'banner',
  Order = 'order',
  OrderMembershipInfo = 'order_membership_info',
  Others = 'others',
  // Braze 팝업 창에서 url을 통해 접속시
  Braze = 'braze',
  MainHome = 'main_home',
  EventList = 'event_list',
  Coupon = 'coupon',
  List = 'list',
}

export const PathToLocation: { [key in string]: LocationEnum } = {
  [ROUTES.INDEX]: LocationEnum.GnbHome,
  [ROUTES.HOME.BEST_SELLER]: LocationEnum.GnbOther,
  [ROUTES.HOME.EVENTS.DETAIL]: LocationEnum.GnbOther,
  [ROUTES.PRODUCTS.DETAIL]: LocationEnum.ProductDetail,
  [ROUTES.SECTION_DETAIL]: LocationEnum.Display,
  [ROUTES.EVENT_DETAIL]: LocationEnum.EventSale,
  [ROUTES.BRAND_DETAIL]: LocationEnum.Brand,
  [ROUTES.SEARCH_RESULT]: LocationEnum.Search,
  [ROUTES.PRODUCTS.BUNDLED_LIST]: LocationEnum.Bundled,
  [ROUTES.EVENTS_100_DEAL]: LocationEnum.Deal100Won,
  [ROUTES.BEST_SELLER]: LocationEnum.Best,
  [ROUTES.PRODUCTS.DETAIL]: LocationEnum.ProductDetail,
  [ROUTES.MYPAGE.LIKE_LIST]: LocationEnum.LikeProduct,
  [ROUTES.MYPAGE.RECENTLY_LIST]: LocationEnum.RecentProduct,
  [ROUTES.MYPAGE.BASE]: LocationEnum.MyPage,
  [ROUTES.CART]: LocationEnum.Cart,
  [ROUTES.ORDER.BASE]: LocationEnum.Order,
}
