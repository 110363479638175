import styled from 'styled-components'
import { FrequentlyBoughtItemResponseDto } from '@/apis/rest/generated/types'
import { BundledProductListInBottomSheet } from '@/components/domains/cart/cart-confirmation/bundled-product-list-in-bottom-sheet'
import { FrequentlyBoughtProductListInBottomSheet } from '@/components/domains/cart/cart-confirmation/frequently-bought-product-list-in-bottom-sheet'
import { RecentlyProductListInBottomSheet } from '@/components/domains/cart/cart-confirmation/recently-product-list-in-bottom-sheet'
import { RelatedProductListInBottomSheet } from '@/components/domains/cart/cart-confirmation/related-product-list=in-bottom-sheet'
import { GraphQLProductType } from '@/utils/product/product-card.util'

// 원본 src/components/domains/modals/ModalAddCartComplete.tsx

interface CartConfirmationContentProps {
  count: number
  loadDataList: {
    frequentlyBoughtProducts: FrequentlyBoughtItemResponseDto[]
    recommendedProductDetailProducts: GraphQLProductType[]
    recommendBundledProducts: GraphQLProductType[]
    recentlyProducts: GraphQLProductType[]
  }
  setStorageSync: () => void
}

const heightByCount: { [key: number]: string } = {
  0: 'auto',
  1: 'auto',
  2: 'calc(70vh - 150px)',
  3: 'calc(70vh - 150px)',
  4: 'calc(70vh - 150px)',
}

export const CartConfirmationContent = ({ count, loadDataList, setStorageSync }: CartConfirmationContentProps) => {
  const { frequentlyBoughtProducts, recommendedProductDetailProducts, recommendBundledProducts, recentlyProducts } =
    loadDataList

  return (
    <StyledCartConfirmationContentWrapper className="cart-confirmation-content" heightByCount={heightByCount[count]}>
      {/* 자주 구매한 상품 */}
      <FrequentlyBoughtProductListInBottomSheet data={frequentlyBoughtProducts} />
      {/* 이 상품의 연관 상품 ok */}
      <RelatedProductListInBottomSheet data={recommendedProductDetailProducts} />
      {/* 묶음 배송이 가능한 상품 ok */}
      <BundledProductListInBottomSheet data={recommendBundledProducts} />
      {/* 최근 본 상품 */}
      <RecentlyProductListInBottomSheet data={recentlyProducts} setStorageSync={setStorageSync} />
    </StyledCartConfirmationContentWrapper>
  )
}

const StyledCartConfirmationContentWrapper = styled.div<{ heightByCount: string }>`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  // 데이터 개수에 따라 높이 조절해보기
  ${({ heightByCount }) => `height: ${heightByCount};`}
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: calc(4.8rem + env(safe-area-inset-bottom));
`
