import { FC } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'
import { QueryParamsProps } from '@/components/api/ApiQuery'
import { TextLegacy } from '@/components/common'
import { ListInfiniteScroll } from '@/components/common/list'
import { ListItemUserMileageRecord } from '@/components/domains/listItems'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { IModelMileageHistory } from '@/containers/models/modelMileageHistory'

type ListUserMileageRecordsProps = {}

const ListUserMileageRecords: FC<ListUserMileageRecordsProps> = () => {
  const queryParams: QueryParamsProps = {
    gqlKey: 'mileageList',
    dataKey: 'mileageHistories',
    variables: {
      orderBy: '-createdAt',
    },
  }

  return (
    <ListInfiniteScroll
      noCache
      defaultPageSize={PageSizeEnum.PageSize20}
      grid={1}
      queryParams={queryParams}
      renderItem={(item: IModelMileageHistory) => <ListItemUserMileageRecord key={item.id} mileage={item} />}
      emptyText={
        <EmptyTextRelativeDiv>
          <EmptyTextDiv>
            <Row justify="center" align="middle">
              <TextLegacy>적립금 내역이 없습니다.</TextLegacy>
            </Row>
          </EmptyTextDiv>
        </EmptyTextRelativeDiv>
      }
    />
  )
}

const EmptyTextRelativeDiv = styled.div`
  /* position: relative; */
  width: 100%;
  height: 55vh;
`

const EmptyTextDiv = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default ListUserMileageRecords
