import { FC, useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { ImageAsset, TextLegacy, TextEllipsis } from '@/components/common'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IModelProductQna } from '@/containers/models/modelProductQna'
import { hasData } from '@/utils/utilData'

type ListItemProductQAProps = {
  productQna: IModelProductQna
}

const ListItemProductQA: FC<ListItemProductQAProps> = ({ productQna }) => {
  const [fold, toggleFold] = useState<boolean>(true)

  const {
    questionBody,
    username,
    productOption,
    isAnswered,
    answerBody,
    getFormattedAnswerAt,
    getFormattedQuestionAt,
  } = productQna
  const optionName = hasData(productOption) ? productOption.getName() : undefined
  return (
    <StyledContainer onClick={() => toggleFold(!fold)}>
      <Row justify="space-between">
        <TextLegacy size={{ xs: 12, md: 14 }}>{username}</TextLegacy>
        <TextLegacy size={{ xs: 12, md: 14 }} color="gray200">
          {getFormattedQuestionAt()}
        </TextLegacy>
      </Row>
      {optionName && (
        <Row style={{ paddingTop: 3 }}>
          <TextLegacy size={{ xs: 12, md: 14 }} color="gray200">
            {`옵션 : ${optionName}`}
          </TextLegacy>
        </Row>
      )}

      <QuestionBody>
        <Row gutter={5} wrap={false}>
          <Col>
            <TextLegacy size={{ xs: 13, md: 15 }} weight="bold">
              Q.
            </TextLegacy>
          </Col>
          <Col>
            {fold ? (
              <TextEllipsis size={{ xs: 13, md: 15 }}>{questionBody}</TextEllipsis>
            ) : (
              <TextLegacy size={{ xs: 13, md: 15 }}>{questionBody}</TextLegacy>
            )}
          </Col>
        </Row>
      </QuestionBody>
      <Row gutter={5}>
        <Col>
          <TextLegacy size={{ xs: 13, md: 15 }} weight="bold">
            A.
          </TextLegacy>
        </Col>
        {isAnswered ? (
          <>
            <Col>
              <TextLegacy size={{ xs: 13, md: 15 }} weight="bold" color="blue500">
                답변 완료
              </TextLegacy>
            </Col>
            <Col>
              <ImageAsset name={fold ? 'chevron-down' : 'chevron-up'} />
            </Col>
          </>
        ) : (
          <Col>
            <TextLegacy size={{ xs: 13, md: 15 }} weight="bold" color="blue500">
              답변 대기
            </TextLegacy>
          </Col>
        )}
      </Row>
      {!fold && isAnswered && (
        <AnswerBody>
          <Row>
            <Col>
              <TextLegacy size={{ xs: 13, md: 15 }} weight="bold">
                판매자
              </TextLegacy>
            </Col>
            <Col>
              <TextLegacy size={{ xs: 12, md: 14 }} color="gray400">
                {getFormattedAnswerAt()}
              </TextLegacy>
            </Col>
          </Row>
          <Row>
            <TextLegacy size={{ xs: 13, md: 15 }}>{answerBody}</TextLegacy>
          </Row>
        </AnswerBody>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  padding: 20px;
  cursor: pointer;
`

const QuestionBody = styled.div`
  margin-top: 15px;
`

const AnswerBody = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: ${(props) => props.theme.color.gray50};
`

export default ListItemProductQA
