import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { ImageAsset, TextLegacy } from '@/components/common'

const ListLikeEmpty: FC = () => {
  return (
    <StyledContainer>
      <Col style={{ margin: 'auto' }}>
        <Row justify="center" align="middle">
          <ImageAsset name="wish_list_empty" size={80} svg />
        </Row>
        <Row justify="center" align="middle" style={{ marginTop: 16 }}>
          <TextLegacy>찜한 상품이 없습니다.</TextLegacy>
        </Row>
      </Col>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  min-height: 70vh;
  margin: -15px;
`

export default ListLikeEmpty
