import { FC } from 'react'
import styled from 'styled-components'
import { match } from 'ts-pattern'
import ListOrderItemCard from '@/components/domains/order/ListOrderItemCard'
import ActionPowerListOrderItemCard from '@/components/domains/order/ListOrderItemCard/ActionPowerListOrderItemCard'
import { useOrderCancel } from '@/containers/hooks'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { ProductProductType } from '@/graphql/generated/schemas'

type OrderDetailSectionOrderItemsProps = {
  orderId: string
  orderItems: IModelOrderItem[]
  showOrderItemActionButtons?: boolean
  isMembershipBenefited?: boolean
  refreshConfirmButtonClick?: () => void
}

const OrderDetailSectionOrderItems: FC<OrderDetailSectionOrderItemsProps> = ({
  orderId,
  orderItems,
  showOrderItemActionButtons = true,
  isMembershipBenefited = false,
  refreshConfirmButtonClick,
}) => {
  const { confirmCancelAllUnpaidOrderItems } = useOrderCancel()
  const orderItemsIds = orderItems.map((item) => item.id)

  return (
    <StyledWrapper>
      {orderItems.map((orderItem, index) => {
        const isLast = index === orderItems.length - 1
        return (
          <div key={orderItem._id}>
            {match(orderItem.productOption.product.productType)
              .with(ProductProductType.ActionPower, () => (
                <ActionPowerListOrderItemCard
                  orderItem={orderItem}
                  orderId={orderId}
                  showActionButtons={showOrderItemActionButtons}
                  confirmCancelAll={() => {
                    confirmCancelAllUnpaidOrderItems(orderItemsIds)
                  }}
                />
              ))
              .with(ProductProductType.Normal, () => (
                <ListOrderItemCard
                  orderItem={orderItem}
                  orderId={orderId}
                  showActionButtons={showOrderItemActionButtons}
                  confirmCancelAll={() => {
                    confirmCancelAllUnpaidOrderItems(orderItemsIds)
                  }}
                  isMembershipBenefited={isMembershipBenefited}
                  refreshConfirmButtonClick={refreshConfirmButtonClick}
                />
              ))
              .exhaustive()}
            {!isLast && <Divider />}
          </div>
        )
      })}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div``
const Divider = styled.div`
  width: 100%;
  height: 8px;
  background: ${(props) => props.theme.color.gray50};
`

export default OrderDetailSectionOrderItems
