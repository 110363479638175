import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import { OrderItemProductInfo } from '@/components/domains/etc'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { currencyText } from '@/utils/utilNumber'

type ListItemReviewWritableOrderItemProps = {
  orderItem: IModelOrderItem
}

const ListItemReviewWritableOrderItem: FC<ListItemReviewWritableOrderItemProps> = ({ orderItem }) => {
  const tracker = useTracker()
  const {
    id: orderItemId,
    getOrderProductInfo,
    getWriteReviewRemainingDays,
    getWriteReviewRemainingDaysText,
    productPromotion,
    getMaxRewardMileage,
  } = orderItem
  const { productName, optionName, thumbnail, productNumber } = getOrderProductInfo()
  const remainingDays = getWriteReviewRemainingDaysText()
  const mileage = getMaxRewardMileage()
  return (
    <div>
      <OrderItemProductInfo
        productNumber={productNumber}
        productName={productName}
        productOptionName={optionName}
        thumbnail={thumbnail}
        productPromotionId={productPromotion?.id}
      />
      <Line />

      <ConditionRow justify="space-between" align="middle">
        <Col>
          {mileage > 0 && (
            <Row>
              <TextLegacy size={{ xs: 13, md: 15 }}>
                최대 적립금 {currencyText(orderItem.order?.membership?.id ? orderItem.membershipMileage : mileage)}
              </TextLegacy>
            </Row>
          )}
          {remainingDays && (
            <Row>
              <TextLegacy size={{ xs: 13, md: 15 }} weight="bold" color="blue500">
                {remainingDays}
              </TextLegacy>
            </Row>
          )}
        </Col>
        <Col>
          <LinkButton
            url={{
              pathname: ROUTES.MYPAGE.REVIEWS.WRITE,
              query: {
                orderItemId,
              },
            }}
            onClick={() => {
              const { _id, productOption } = orderItem
              const { _id: productOptionId, name: productOptionName } = productOption
              tracker.triggerCustomEvent(FitpetMallEventEnum.ClickReviewWriteButton, {
                orderId: _id,
                productOptionId,
                productOptionName,
                mileageExpected: mileage,
                reviewwriteRemainingPeriod: getWriteReviewRemainingDays(),
              })
            }}
          >
            <WriteButton>
              <TextLegacy size={{ xs: 12, md: 14 }} color="blue500">
                리뷰 작성
              </TextLegacy>
            </WriteButton>
          </LinkButton>
        </Col>
      </ConditionRow>
      <Divider />
    </div>
  )
}

const Line = styled.div`
  margin: 0px 20px;
  height: 1px;
  border-bottom: 1px solid ${GlobalColorEnum.Gray70};
`

const ConditionRow = styled(Row)`
  padding: 13px 20px;
`

const WriteButton = styled.div`
  border: 1px solid ${GlobalColorEnum.Blue500};
  padding: 8px 16px;
  background-color: ${(props) => props.theme.color.grayWhite};
  border-radius: 8px;
`

const Divider = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${(props) => props.theme.color.gray50};
`

export default ListItemReviewWritableOrderItem
