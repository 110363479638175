/**
 * @desc svg 파일 추가시에 width: 100%, height: 100%, fill: currentColor 로 저장
 */
import IcAdd from '@/icons/ic_add.svg'
import IcAddSmall from '@/icons/ic_add_small.svg'
import IcArrowDown from '@/icons/ic_arrow_down.svg'
import IcArrowDownSmall from '@/icons/ic_arrow_down_12.svg'
import IcArrowLeft from '@/icons/ic_arrow_left.svg'
import IcArrowRight from '@/icons/ic_arrow_right.svg'
import IcArrowRightThin from '@/icons/ic_arrow_right_thin.svg'
import IcArrowUp from '@/icons/ic_arrow_up.svg'
import IcArrowUpSmall from '@/icons/ic_arrow_up_12.svg'
import IcBack from '@/icons/ic_back.svg'
import IcBottom from '@/icons/ic_bottom.svg'
import IcCart from '@/icons/ic_cart.svg'
import IcCaution from '@/icons/ic_caution.svg'
import IcChange from '@/icons/ic_change.svg'
import IcCheck from '@/icons/ic_check.svg'
import IcChevronDown from '@/icons/ic_chevron_down.svg'
import IcChevronRight from '@/icons/ic_chevron_right.svg'
import IcClose from '@/icons/ic_close.svg'
import IcCoinFlat from '@/icons/ic_coin_flat.svg'
import IcDownload from '@/icons/ic_download.svg'
import IcDownloadRadius from '@/icons/ic_download_radius.svg'
import IcEmpty from '@/icons/ic_empty.svg'
import IcEventFlat from '@/icons/ic_event_flat.svg'
import IcHeartDimmedThumbnail from '@/icons/ic_heart__dimmed_thumbnail.svg'
import IcHeartFilled from '@/icons/ic_heart_filled.svg'
import IcHeartOutlined from '@/icons/ic_heart_outlined.svg'
import IcHome from '@/icons/ic_home.svg'
import IcInfo from '@/icons/ic_info.svg'
import IcInfoFilled from '@/icons/ic_info_filled.svg'
import IcOrderCoupon from '@/icons/ic_order_coupon.svg'
import IcOwner from '@/icons/ic_owner.svg'
import IcRankingDown from '@/icons/ic_ranking_down.svg'
import IcRankingNone from '@/icons/ic_ranking_none.svg'
import IcRankingUp from '@/icons/ic_ranking_up.svg'
import IcRecently from '@/icons/ic_recently.svg'
import IcSearch from '@/icons/ic_search.svg'
import IcSearchDelete from '@/icons/ic_search_delete.svg'
import IcSetting from '@/icons/ic_setting.svg'
import IcShare from '@/icons/ic_share.svg'
import IcStar from '@/icons/ic_star.svg'
import IcTabCareFilled from '@/icons/ic_tab_care_filled.svg'
import IcTabCareOutlined from '@/icons/ic_tab_care_outlined.svg'
import IcTabHealthFilled from '@/icons/ic_tab_health_filled.svg'
import IcTabHealthOutlined from '@/icons/ic_tab_health_outlined.svg'
import IcTabHospitalFilled from '@/icons/ic_tab_hospital_filled.svg'
import IcTabHospitalOutlined from '@/icons/ic_tab_hospital_outlined.svg'
import IcTabMyFilled from '@/icons/ic_tab_my_filled.svg'
import IcTabMyOutlined from '@/icons/ic_tab_my_outlined.svg'
import IcTabShoppingFilled from '@/icons/ic_tab_shopping_filled.svg'
import IcTabShoppingOutlined from '@/icons/ic_tab_shopping_outlined.svg'
import IcTip from '@/icons/ic_tip.svg'
import IcTop from '@/icons/ic_top.svg'

const SvgImages = {
  IcHeartOutlined,
  IcHeartFilled,
  IcHeartDimmedThumbnail,
  IcChange,
  IcSearch,
  IcSetting,
  IcTabShoppingOutlined,
  IcTabShoppingFilled,
  IcTabMyOutlined,
  IcTabMyFilled,
  IcCart,
  IcAdd,
  IcAddSmall,
  IcArrowRight,
  IcArrowLeft,
  IcArrowRightThin,
  IcArrowUp,
  IcArrowDown,
  IcRecently,
  IcClose,
  IcHome,
  IcBack,
  IcShare,
  IcStar,
  IcInfo,
  IcInfoFilled,
  IcArrowUpSmall,
  IcArrowDownSmall,
  IcTop,
  IcTip,
  IcSearchDelete,
  IcCheck,
  IcTabCareFilled,
  IcTabCareOutlined,
  IcTabHospitalFilled,
  IcTabHospitalOutlined,
  IcBottom,
  IcRankingNone,
  IcRankingUp,
  IcRankingDown,
  IcEventFlat,
  IcChevronDown,
  IcChevronRight,
  IcDownload,
  IcDownloadRadius,
  IcOrderCoupon,
  IcOwner,
  IcCoinFlat,
  IcTabHealthFilled,
  IcTabHealthOutlined,
  IcEmpty,
  IcCaution,
}

export default SvgImages
