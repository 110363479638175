import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from '@/components/common/etc'
import { CartConfirmationContent } from '@/components/domains/cart/cart-confirmation/CartConfirmationContent'
import { CartConfirmationTop } from '@/components/domains/cart/cart-confirmation/CartConfirmationTop'
import ROUTES from '@/constants/legacy/constRoutes'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { useCartBottomSheetEventTracker } from '@/containers/event-tracker/cart-bottom-sheet.event.tracker'
import { useCustomRouter, useRecentlyProduct } from '@/containers/hooks'
import { useCartBottomSheetRestReduxData } from '@/containers/hooks/cart/useCartBottomSheetRestReduxData'
import useRelatedProductContainerHookV2 from '@/containers/hooks/related-product.containerV2.hook'
import useFrequentlyBought from '@/containers/hooks/useFrequentlyBought.hook'
import { useRecommendBundleProductsByGen } from '@/containers/hooks/useRecommendBundleProductsByGen'
import { doSetBottomSheetData } from '@/stores/reduxData'
import { RootState } from '@/stores/store'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import type { ProductScheme } from '@/apis/rest/generated/types'

const fallbackImage = `${process.env.HOST}/images/product_placeholder_76.svg`

export const CartConfirmation = () => {
  const { reduxProductInCartConfirmationBottomSheet } = useSelector(
    (state: RootState) => state.reduxDataReducers.cartConfirmationBottomSheetData
  )
  const { push } = useCustomRouter()
  const { resetCartBottomSheetReduxData } = useCartBottomSheetRestReduxData()
  const { trackViewAddCartBottomUpProductEvent } = useCartBottomSheetEventTracker()
  const encodedProductId = Base64ConverterUtil.convertNumberIdToBase64ByKey({
    key: 'ProductType',
    id: reduxProductInCartConfirmationBottomSheet?.id,
  })

  /**
   * 자주 산 상품
   */
  const { data: frequentlyBoughtProducts, isLoading: isFrequentlyBoughtProductsLoading } = useFrequentlyBought({
    page: PageSizeEnum.PageSize1,
    size: PageSizeEnum.PageSize20,
  })
  /**
   * 이 상품의 연관 상품
   */
  const { loading: isRecommendedProductDetailProductsLoading, recommendedProductDetailProducts } =
    useRelatedProductContainerHookV2(encodedProductId, PageSizeEnum.PageSize20)

  /**
   * 묶음 배송이 가능한 상품
   */
  const { recommendBundledProducts, isRecommendBundleProductsLoading } = useRecommendBundleProductsByGen({
    productId: encodedProductId,
  })

  /**
   * 최근 본 상품
   */
  const {
    recentlyProducts,
    isLoading: isRecentlyProductsLoading,
    setStorageSync,
  } = useRecentlyProduct({
    length: PageSizeEnum.PageSize20,
    currentProductId: encodedProductId,
  })

  const loadDataState = {
    frequentlyBoughtProducts: {
      name: '자주 산 상품',
      isLoading: isFrequentlyBoughtProductsLoading,
      data: frequentlyBoughtProducts,
    },
    recommendedProductDetailProducts: {
      name: '이 상품의 연관 상품',
      isLoading: isRecommendedProductDetailProductsLoading,
      data: recommendedProductDetailProducts,
    },
    recommendBundledProducts: {
      name: '묶음 배송이 가능한 상품',
      isLoading: isRecommendBundleProductsLoading,
      data: recommendBundledProducts,
    },
    recentlyProducts: {
      name: '최근 본 상품',
      isLoading: isRecentlyProductsLoading,
      data: recentlyProducts,
    },
  }

  const isLoading = Object.values(loadDataState).some((state) => {
    return state.isLoading
  })
  const trueDataCount = !isLoading
    ? Object.values(loadDataState).reduce((count, item) => {
        return count + (item.data.length > 0 ? 1 : 0)
      }, 0)
    : 0
  const count = !isLoading ? trueDataCount : 0

  const handleClickLink = () => {
    resetCartBottomSheetReduxData()
    push({
      pathname: ROUTES.CART,
      query: { tab: 'cart' },
    })
  }

  useEffect(() => {
    if (!loadDataState.recentlyProducts.isLoading && trueDataCount > 0) {
      const sectionNames = Object.values(loadDataState).reduce<string[]>((names, item) => {
        if (item.data.length > 0) {
          names.push(item.name)
        }
        return names
      }, [])

      trackViewAddCartBottomUpProductEvent({
        sectionNames,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDataState.recentlyProducts.isLoading, trueDataCount])

  return (
    <>
      <StyledCartConfirmationWrapper>
        {isLoading ? (
          <StyledSpinnerContainer>
            <Spinner />
          </StyledSpinnerContainer>
        ) : (
          <>
            <div className="cart-confirmation__top">
              <CartConfirmationTop
                imageUrl={reduxProductInCartConfirmationBottomSheet?.thumbnail || fallbackImage}
                onClickLink={handleClickLink}
              />
            </div>
            <div className="cart-confirmation__content">
              <CartConfirmationContent
                count={count}
                loadDataList={{
                  frequentlyBoughtProducts: loadDataState.frequentlyBoughtProducts.data,
                  recommendedProductDetailProducts: loadDataState.recommendedProductDetailProducts.data,
                  recommendBundledProducts: loadDataState.recommendBundledProducts.data,
                  recentlyProducts: loadDataState.recentlyProducts.data,
                }}
                setStorageSync={setStorageSync}
              />
            </div>
          </>
        )}
      </StyledCartConfirmationWrapper>
    </>
  )
}

const StyledCartConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .cart-confirmation {
    &__top {
      padding: 0 1.6rem 1.2rem;
      border-bottom: 1px solid
        ${({
          theme: {
            color: { gray70 },
          },
        }) => gray70};
    }
    &__content {
    }
  }
`

const StyledSpinnerContainer = styled.div`
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
