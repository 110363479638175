import { FC } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'
import { ImageAsset, TextLegacy } from '@/components/common'
import { ButtonToHome } from '@/components/common/buttons'
import { FIXED_HEIGHT } from '@/constants/legacy/constLayout'
import { ListOrderType } from '@/constants/legacy/constType'

type ListOrderEmptyProps = {
  type: ListOrderType
}

const ListOrderEmpty: FC<ListOrderEmptyProps> = ({ type }) => {
  const getEmptyComments = () => {
    switch (type) {
      case 'order':
        return `주문내역이 없습니다.\n지금 바로 첫 구매를 시작해 보세요.`
      case 'cancel-return-exchange':
        return `취소/반품/교환 내역이 없습니다.`
      default:
        return null
    }
  }

  const getEmptyGoToLinkBtn = () => {
    switch (type) {
      case 'order':
        return <ButtonToHome />
      default:
        return null
    }
  }

  return (
    <StyledOuter headerHeight={FIXED_HEIGHT.HEADER}>
      <StyledInner>
        <Row justify="center" align="middle">
          <ImageAsset name="no_list" size={80} />
        </Row>
        <Row justify="center" align="middle" style={{ marginTop: 15 }}>
          <TextLegacy color="gray400" align="center">
            {getEmptyComments()}
          </TextLegacy>
        </Row>
        {getEmptyGoToLinkBtn()}
      </StyledInner>
    </StyledOuter>
  )
}

const StyledOuter = styled.div<{ headerHeight: number }>`
  display: flex;
  height: ${(props) => `calc(100vh - ${props.headerHeight}px - 150px)`};
  margin: -16px;
`
const StyledInner = styled.div`
  margin: auto;
`

export default ListOrderEmpty
