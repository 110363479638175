import { FC } from 'react'
import styled from 'styled-components'
import { QueryParamsProps } from '@/components/api/ApiQuery'
import { ListInfiniteScroll } from '@/components/common/list'
import { ListItemProductQA } from '@/components/domains/listItems'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { IModelProductQna } from '@/containers/models/modelProductQna'

type ListProductQAProps = {
  productNumber: string
  setTotalCount: (totalCount: number) => void
}

const ListProductQA: FC<ListProductQAProps> = ({ productNumber, setTotalCount }) => {
  const queryParams: QueryParamsProps = {
    gqlKey: 'productQnas',
    dataKey: 'productQnas',
    variables: {
      filter: {
        productNumber,
        orderBy: '-createdAt',
      },
    },
  }

  return (
    <ListInfiniteScroll
      defaultPageSize={PageSizeEnum.PageSize30}
      grid={1}
      noCache
      queryParams={queryParams}
      renderItem={(item: IModelProductQna) => (
        <>
          <ListItemProductQA productQna={item} />
          <Divider />
        </>
      )}
      setListTotalCount={setTotalCount}
    />
  )
}

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${GlobalColorEnum.Gray70};
`

export default ListProductQA
