import { FC } from 'react'
import { Col, Row } from 'antd'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Modal, TextLegacy } from '@/components/common'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { useMessage, useModal, useUserInfo } from '@/containers/hooks'
import { doSetToggleModalLogin } from '@/stores/reduxUI'

type ReportProductReviewProps = {
  reviewId: string
}

const ReportProductReview: FC<ReportProductReviewProps> = ({ reviewId }) => {
  const dispatch = useDispatch()
  const { hasSignedIn } = useUserInfo
  const { useModalProps } = useModal()
  const { show } = useMessage()

  const onClick = (/*mutation: MutationFunction*/) => {
    if (!hasSignedIn()) {
      dispatch(doSetToggleModalLogin(true))
      return
    }

    useModalProps.showModal()
  }

  const RenderReportSuccessMessage = () => {
    return (
      <TextLegacy size={{ xs: 14, md: 16 }} color="white" customStyle={`padding: 4px 42px;`}>
        {'신고가 완료되었습니다.'}
      </TextLegacy>
    )
  }

  return (
    // <ApiMutation
    //   gqlKey='reportReview'
    // >
    //   { ( mutation: MutationFunction ) => (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <StyledButton className="button" onClick={() => onClick(/*mutation*/)} color={GlobalColorEnum.Gray200}>
            <Row justify="center" align="middle">
              <TextLegacy size={{ xs: 12, md: 14 }} color={'gray400'}>
                신고하기
              </TextLegacy>
            </Row>
          </StyledButton>
        </Col>
      </Row>
      <Modal
        useModalProps={useModalProps}
        buttonType="ACTION_CANCEL"
        okText="확인"
        onOk={() => {
          show(<RenderReportSuccessMessage />)
          // TODO: ReportReviewMutation 구현
          // const variables = MUTATION_VARIABLES.REPORT_REVIEW( { id: reviewId, user_id: userId } )
          // mutation( {
          //   variables
          // } )
        }}
        backAction={false}
        cancelText="취소"
        width={320}
      >
        <>
          <Row justify="center">
            <Col style={{ textAlign: 'center' }}>
              <TextLegacy size={{ xs: 16, md: 18 }} weight="bold" align="center">
                {'신고 하시겠습니까?'}
              </TextLegacy>
            </Col>
          </Row>
        </>
      </Modal>
    </>
    //   )}
    // </ApiMutation>
  )
}

const StyledButton = styled.div<{ color: string }>`
  border-radius: 4px;
  background-color: ${(props) => props.theme.color.grayWhite};
`
export default ReportProductReview
