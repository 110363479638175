import { useEffect } from 'react'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { usePetType } from '@/containers/hooks/index'
import useIntersectionObserver from '@/containers/hooks/useIntersectionObserver'
import usePageInfo from '@/containers/hooks/usePageInfo'
import { useAdsQuery } from '@/graphql/generated/hooks'
import { AdType } from '@/graphql/generated/schemas'

const defaultPageSize = PageSizeEnum.PageSize50

const useEventsListContainer = () => {
  const { getPetType } = usePetType()
  const { pageNumber, setPageNumber, getTablePageInfoVariables } = usePageInfo(defaultPageSize)

  const { data, fetchMore: fetchMoreEventList } = useAdsQuery({
    skip: !getPetType(),
    variables: {
      filter: {
        isActive: true,
        petType: getPetType(),
      },
      orderBy: 'displayOrder,-startedAt',
      pageInfo: {
        first: defaultPageSize,
      },
    },
  })

  const { elementRef } = useIntersectionObserver(() => {
    setPageNumber((prev) => prev + 1)
  })

  const eventList: AdType[] = (data?.ads?.edges.map((e) => ({ ...e?.node })) as AdType[]) || []

  useEffect(() => {
    if (pageNumber === 1) {
      return
    }
    fetchMoreEventList({
      variables: {
        pageInfo: {
          first: defaultPageSize,
          after: getTablePageInfoVariables(pageNumber + 1, defaultPageSize).pageInfo.after,
        },
      },
      // updateQuery 가 호출이 안되었다고 lint-error 가 발생하여 @ts-ignore 처리
      // @ts-ignore
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }
        return {
          ...fetchMoreResult,
          ads: {
            ...fetchMoreResult.ads,
            edges: [...(previousResult.ads?.edges || []), ...(fetchMoreResult.ads?.edges || [])],
          },
        }
      },
    })
  }, [pageNumber])

  return {
    eventList,
    elementRef,
  }
}

export default useEventsListContainer
