import { FC } from 'react'
import styled from 'styled-components'
import { ModalSlider } from '@/components/common/modals'
import { ModalBaseProps } from '@/components/common/modals/ModalBase'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import { ShippingNumbersForSweetTracker } from '@/constants/legacy/constType'
import { OrderButtonText } from '@/constants/order-type.enum'

type ModalShippingNumberSelectProps<T> = {
  shippingTrackNumbers: ShippingNumbersForSweetTracker[]
  handleShippingNumberSelected: (selectedShippingNumbersForSweetTracker: ShippingNumbersForSweetTracker) => void
} & ModalBaseProps<T>

const ModalShippingNumberSelect: FC<ModalShippingNumberSelectProps<any>> = ({
  shippingTrackNumbers,
  useModalProps,
  handleShippingNumberSelected,
}) => {
  return (
    <ModalSlider title={OrderButtonText.ShippingTracking} titleNoBorder useModalProps={useModalProps}>
      <>
        <StyledContainer>
          <OptionSelectContainer>
            <ShippingNumbersContainer>
              {shippingTrackNumbers.map((o, index) => {
                return (
                  <ShippingNumberRow
                    key={index}
                    onClick={() => {
                      handleShippingNumberSelected(o)
                    }}
                  >
                    <ShippingNumberText>{o.shippingNumber}</ShippingNumberText>
                  </ShippingNumberRow>
                )
              })}
            </ShippingNumbersContainer>
          </OptionSelectContainer>
        </StyledContainer>
      </>
    </ModalSlider>
  )
}

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.color.grayWhite};
`

const OptionSelectContainer = styled.div`
  padding-bottom: 34px;
  border-top: 1px solid ${GlobalColorEnum.Gray70};
  overflow-y: scroll;
`

const ShippingNumberRow = styled.div`
  height: 58px;
  align-items: center;
  border-bottom: 1px solid ${GlobalColorEnum.Gray70};
`

const ShippingNumberText = styled.text`
  padding: 0px 20px;
  height: 58px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.color.gray700};

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: 14px;
  }
`

const ShippingNumbersContainer = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  max-height: calc(100% - 60px);

  ::-webkit-scrollbar {
    display: block;
  }
`

export default ModalShippingNumberSelect
