import { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import useSearchedKeywords from '@/components/domains/search/hooks/useSearchedKeywords'
import { SearchBodyContents } from '@/components/domains/search/search-body-contents/search-body-contents'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { useSearch } from '@/containers/hooks'
import useRecentlyProduct from '@/containers/hooks/useRecentlyProduct'

const SearchBody = () => {
  const { initSearchBodyMount } = useSearch()
  const { isShowSearchedKeywords } = useSearchedKeywords()
  const { recentlyProducts } = useRecentlyProduct({ length: PageSizeEnum.PageSize20 })

  useEffect(() => {
    initSearchBodyMount()
  }, [])

  return (
    <StyledSearchBodyContainer hasKeywords={isShowSearchedKeywords}>
      <StyledDisplayDefaultContainer>
        <SearchBodyContents recentlyProducts={recentlyProducts} />
      </StyledDisplayDefaultContainer>
    </StyledSearchBodyContainer>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledSearchBodyContainer = styled.div<{ hasKeywords: boolean }>`
  display: ${({ hasKeywords }) => (hasKeywords ? 'none' : 'block')};
  animation: ${fadeIn} 0.5s ease-in-out;
`

const StyledDisplayDefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3.6rem;
  padding-top: 2rem;
`

export default SearchBody
