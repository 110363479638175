import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { ImageAsset, TextLegacy, TextEllipsis } from '@/components/common'
import { LinkButton, PlainBorderButtonLegacy } from '@/components/common/buttons'
import { ReviewStarRating } from '@/components/common/etc'
import { ReviewImages } from '@/components/domains/etc'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { IModelReview } from '@/containers/models/modelReview'

type ListItemUserReviewProps = {
  review: IModelReview
}

const ListItemUserReview: FC<ListItemUserReviewProps> = ({ review }) => {
  const {
    id: reviewId,
    getProductName,
    getProductNumber,
    score,
    reviewBody,
    getWrittenDateFormat,
    isPhotoReview,
    reviewReviewImages,
  } = review

  return (
    <div>
      <Card>
        <LinkButton url={ROUTES.PRODUCTS.DETAIL.replace('[productId]', getProductNumber())}>
          <ProductNameRow align="middle" gutter={8}>
            <Col>
              <TextLegacy weight="bold">구매 상품</TextLegacy>
            </Col>
            <Col flex="1">
              <TextEllipsis color="gray400" lineNumber={1}>
                {getProductName()}
              </TextEllipsis>
            </Col>
            <Col style={{ marginTop: -5 }}>
              <ImageAsset name="chevron-right" size={12} />
            </Col>
          </ProductNameRow>
        </LinkButton>

        <ScoreRow align="middle" gutter={10} style={{ marginTop: 1.4 }}>
          <Col>
            <ReviewStarRating rating={score} />
          </Col>
          <Col>
            <TextLegacy size={{ xs: 12, md: 14 }} color="gray400">
              {getWrittenDateFormat()}
            </TextLegacy>
          </Col>
        </ScoreRow>

        {isPhotoReview && (
          <ImageContainer>
            {/* @ts-ignore TODO typescript 오류 수정 필요 */}
            <ReviewImages images={reviewReviewImages} />
          </ImageContainer>
        )}
        <BodyRow>
          <TextLegacy size={{ xs: 13, md: 15 }}>{reviewBody}</TextLegacy>
        </BodyRow>

        <Row justify="end">
          <LinkButton
            url={{
              pathname: ROUTES.MYPAGE.REVIEWS.EDIT,
              query: {
                reviewId,
              },
            }}
            routeType="replace"
          >
            <PlainBorderButtonLegacy text="리뷰 수정" height={40} />
          </LinkButton>
        </Row>
      </Card>
      <Divider />
    </div>
  )
}

const Card = styled.div`
  padding: 0px 20px;
  padding-bottom: 20px;
`
const ProductNameRow = styled(Row)`
  padding: 20px 0px;
  border-bottom: 1px solid ${GlobalColorEnum.Gray70};
`

const ScoreRow = styled(Row)`
  padding: 10px 0px;
`

const BodyRow = styled(Row)`
  padding-bottom: 10px;
`

const Divider = styled.div`
  height: 8px;
  background-color: ${(props) => props.theme.color.gray50};
`

const ImageContainer = styled.div`
  padding-bottom: 10px;
`

export default ListItemUserReview
