import { FC, useEffect, useLayoutEffect } from 'react'
import parse, { DOMNode } from 'html-react-parser'
import styled from 'styled-components'
import useCustomRouter from '@/containers/hooks/useCustomRouter'
import { isInAppFlag } from '@/utils/utilBridge'
import { convertSanitizingHtmlString } from '@/utils/utilString'
import 'intersection-observer'

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

type SanitizedHtmlDisplayProps = {
  dirtyHtmlString: string
  /** 클릭시 이벤트 true 가 아니면 SenitizedHtmlDisplay 의 onClick 로직 동작 */
  onClick?: (e: MouseEvent) => void
  replaceParseHtml?: (domNode: DOMNode) => JSX.Element | object | void | undefined | null | false
}

const SanitizedHtmlDisplay: FC<SanitizedHtmlDisplayProps> = ({ dirtyHtmlString, onClick, replaceParseHtml }) => {
  const { push } = useCustomRouter()

  useIsomorphicLayoutEffect(() => {
    const io = new IntersectionObserver(
      (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach(({ isIntersecting, intersectionRatio, target }) => {
          if (isIntersecting && intersectionRatio > 0) {
            // TODO typescript 오류 수정 필요
            // @ts-ignore
            target.src = target.getAttribute('data-src')
            target.classList.remove('lazy-img')
            observer.unobserve(target)
          }
        })
      },
      { rootMargin: '0px' }
    )
    document.querySelectorAll('.lazy-img').forEach((el) => {
      io.observe(el)
    })
  }, [])

  if (!dirtyHtmlString) {
    return null
  }

  return (
    <StyledContainer
      onClick={(e) => {
        if (onClick) {
          // TODO typescript 오류 수정 필요
          // @ts-ignore
          onClick(e)
          return
        }

        // TODO typescript 오류 수정 필요
        // @ts-ignore
        const target = e.target.tagName === 'A' ? e.target : e.target.parentElement
        if (target.tagName !== 'A') {
          return
        }

        if (isInAppFlag) {
          e.preventDefault()
          e.stopPropagation()
          push(target.href)
        }
      }}
    >
      {parse(convertSanitizingHtmlString(dirtyHtmlString), {
        replace: replaceParseHtml,
      })}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  line-height: 1.6;

  * {
    margin-left: 16px;
    margin-right: 16px;
  }

  p:has(img) {
    margin-left: 0 !important;
    margin-right: 0 !important;

    img {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  img {
    display: flex;
    aspect-ratio: auto;
    width: 100%;
    height: auto;
  }

  p {
    margin-bottom: 0 !important;
  }

  .ql-align-center {
    text-align: center;
  }

  /** a tag 자식으로 img가 있는 경우, a tag가 이미지영역 클릭시에 onClick이 되어야하므로 block으로 처리 */
  a {
    display: block;
  }
`

export default SanitizedHtmlDisplay
