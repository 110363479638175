import { CardBodyHorizontal } from '@/components/domains/products/new-product-card/CardBodyHorizontal'
import { CardBodyVertical } from '@/components/domains/products/new-product-card/CardBodyVertical'
import { HorizontalProductCard } from '@/components/domains/products/new-product-card/horizontal-product-card'
import {
  BasicLabelType,
  CARD_BODY_PRICE_INFO_TYPES,
  CARD_BODY_VERTICAL_SIZES,
  ProductCardSize,
  THUMBNAIL_STATES,
  ThumbnailState,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import { VerticalProductCard } from '@/components/domains/products/new-product-card/vertical-product-card'
import ROUTES from '@/constants/legacy/constRoutes'
import { useProductListEventTracker } from '@/containers/event-tracker/product-list.event-tracker'
import { useOpenProductOptionChoiceBottomSheet } from '@/containers/hooks/cart/useOpenProductOptionChoiceBottomSheet'
import useCustomRouter from '@/containers/hooks/useCustomRouter'
import { BottomSheetEventTrackerType } from '@/types/bottom-sheet-event-tracker.type'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { encodeId } from '@/utils/utilApi'
import { currencyText } from '@/utils/utilNumber'

interface ProductCardProps {
  cartType?: 'VERTICAL' | 'HORIZONTAL'
  product: ProductTypeDefinedByFrontend
  size: ProductCardSize
  thumbnailState?: ThumbnailState
  label?: BasicLabelType
  rankingNumber?: number
  hasCartButton?: boolean
  trackerData?: BottomSheetEventTrackerType
  productCardTrackerData?: { location?: string }
  onClickProductCard?: () => void
  onClickCartButton?: () => void
  callbackAddToCart?: (ids?: number[]) => void
  callbackLike?: () => void
}
export const NewProductCard = ({
  cartType = 'VERTICAL',
  product,
  size,
  thumbnailState,
  label,
  rankingNumber,
  hasCartButton = false,
  trackerData,
  productCardTrackerData,
  onClickProductCard,
  onClickCartButton,
  callbackAddToCart,
  callbackLike,
}: ProductCardProps) => {
  const { push } = useCustomRouter()
  const { openProductOptionChoiceBottomSheet } = useOpenProductOptionChoiceBottomSheet()
  const { trackClickListStartPurchaseButtonEventInAddToCart2 } = useProductListEventTracker()

  const {
    id,
    promotion,
    thumbnail,
    name,
    discountRate,
    price,
    salePrice,
    reviewCount,
    reviewScore,
    maxOptionSkuCount,
    pricePerSku,
    isShowBundledMessage: isBundledMessage,
    hasApplicableProductCoupon,
    hasDiscount,
    isLiked,
    isRealSoldOut,
    isNotAvailable,
    thumbnailData,
  } = product

  const handleClickProductCard = () => {
    onClickProductCard?.()
    push({
      pathname: ROUTES.PRODUCTS.DETAIL,
      query: {
        productId: id,
        ...(promotion?.id && { promotion: encodeId('ProductPromotionType', promotion?.id) }),
        ...(productCardTrackerData?.location && { location: productCardTrackerData?.location }),
      },
    })
  }

  const handleClickCartButton = () => {
    onClickCartButton?.()
    trackClickListStartPurchaseButtonEventInAddToCart2({
      product,
      addLocation: trackerData?.addLocation,
      sectionName: trackerData?.sectionName,
      listIndex: trackerData?.listIndex,
    })
    openProductOptionChoiceBottomSheet({ product, trackerData, callback: callbackAddToCart })
  }

  if (cartType === 'HORIZONTAL') {
    return (
      <div style={{ position: 'relative' }}>
        <HorizontalProductCard onClick={handleClickProductCard}>
          <HorizontalProductCard.Thumbnail
            productId={id}
            productName={name}
            productPromotionScheme={promotion}
            thumbnailState={thumbnailState || THUMBNAIL_STATES.basicLabel}
            imageUrl={thumbnail}
            isLiked={isLiked}
            isRealSoldOut={isRealSoldOut}
            isNotAvailable={isNotAvailable}
            rankingNumber={rankingNumber}
            basicLabel={label}
            trackingData={thumbnailData}
            callbackLike={callbackLike}
          />

          <div>
            <HorizontalProductCard.Body>
              <CardBodyHorizontal>
                <CardBodyHorizontal.Name>{name}</CardBodyHorizontal.Name>
                <CardBodyHorizontal.PriceInfo
                  hasDiscount={hasDiscount}
                  price={price}
                  salePrice={salePrice}
                  discountRate={discountRate}
                  type={
                    hasApplicableProductCoupon ? CARD_BODY_PRICE_INFO_TYPES.COUPON : CARD_BODY_PRICE_INFO_TYPES.DEFAULT
                  }
                />
                {isBundledMessage && (
                  <CardBodyHorizontal.DiscountedBulkPrice>
                    {maxOptionSkuCount}개 구매 시 각 {currencyText(pricePerSku)}
                  </CardBodyHorizontal.DiscountedBulkPrice>
                )}
                {reviewScore > 0 && (
                  <CardBodyHorizontal.ReviewInfo>
                    <CardBodyHorizontal.ReviewScore>{reviewScore}</CardBodyHorizontal.ReviewScore>
                    <CardBodyHorizontal.ReviewCount>
                      ({reviewCount.toLocaleString('ko-KR')})
                    </CardBodyHorizontal.ReviewCount>
                  </CardBodyHorizontal.ReviewInfo>
                )}
              </CardBodyHorizontal>
            </HorizontalProductCard.Body>

            {hasCartButton && (
              <HorizontalProductCard.CartButton
                disabled={isRealSoldOut || isNotAvailable}
                onClick={handleClickCartButton}
              />
            )}
          </div>
        </HorizontalProductCard>
      </div>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <VerticalProductCard size={size} onClick={handleClickProductCard}>
        <VerticalProductCard.Thumbnail
          productId={id}
          productName={name}
          productPromotionScheme={promotion}
          thumbnailState={thumbnailState || THUMBNAIL_STATES.basicLabel}
          imageUrl={thumbnail}
          isLiked={isLiked}
          isRealSoldOut={isRealSoldOut}
          isNotAvailable={isNotAvailable}
          rankingNumber={rankingNumber}
          basicLabel={label}
          trackingData={thumbnailData}
          callbackLike={callbackLike}
        />

        {hasCartButton && (
          <VerticalProductCard.CartButton disabled={isRealSoldOut || isNotAvailable} onClick={handleClickCartButton} />
        )}

        <VerticalProductCard.Body>
          <CardBodyVertical size={size || CARD_BODY_VERTICAL_SIZES.lg}>
            <CardBodyVertical.Name>{name}</CardBodyVertical.Name>
            <CardBodyVertical.PriceInfo
              hasDiscount={hasDiscount}
              price={price}
              salePrice={salePrice}
              discountRate={discountRate}
              type={hasApplicableProductCoupon ? CARD_BODY_PRICE_INFO_TYPES.COUPON : CARD_BODY_PRICE_INFO_TYPES.DEFAULT}
            />
            {isBundledMessage && (
              <CardBodyVertical.DiscountedBulkPrice>
                {maxOptionSkuCount}개 구매 시 각 {currencyText(pricePerSku)}
              </CardBodyVertical.DiscountedBulkPrice>
            )}
            {reviewScore > 0 && (
              <CardBodyVertical.ReviewInfo>
                <CardBodyVertical.ReviewScore>{reviewScore}</CardBodyVertical.ReviewScore>
                <CardBodyVertical.ReviewCount>({reviewCount.toLocaleString('ko-KR')})</CardBodyVertical.ReviewCount>
              </CardBodyVertical.ReviewInfo>
            )}
          </CardBodyVertical>
        </VerticalProductCard.Body>
      </VerticalProductCard>
    </div>
  )
}
