import { useCallback } from 'react'
import { OrderItemForCartScheme } from '@/apis/rest/generated/types'
import { ProductLocation, ProductLocationTrackerValue } from '@/constants/product-location.const'
import { ShippingForCart } from '@/containers/cart/useCartContents'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { CartProductOptionType } from '@/containers/types/CartProductOption'
import { CartShippingGroupType } from '@/containers/types/CartShippingGroup'
import { convertProductOptionCartsParams } from '@/utils/event-tracker/convertParams/convertProductParams'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { decodeIdForEventTracker } from '@/utils/utilApi'

type BannerEventProperties =
  | { bannerName: string; otherSlug: string; eventsaleSlug?: never }
  | { bannerName: string; eventsaleSlug: string; otherSlug?: never }

interface CartPageTabCount {
  countProductCount?: number
  likeProductCount?: number
  frequentProductCount?: number
}

interface ViewCartEventProperties extends CartPageTabCount {
  cartShippingGroups: CartShippingGroupType[]
  totalPaymentAmount: number
}
interface ClickCartBottomProductEventProperties {
  sectionIndex: number
  listIndex: number
  sectionName: string
  productId?: string
  productName?: string
  productPrice?: number
  productPurchasePrice?: number
  productDiscountRate?: number
  productReviewAvgScore?: number
  productReviewTotalCount?: number
}

const useCartEventTracker = () => {
  const { triggerCustomEvent } = useTracker()

  const trackViewCartEvent2 = ({
    productAmount,
    cartCount,
    likeCount,
    frequentlyCount,
    shippings,
    ab_test_group,
  }: {
    productAmount: number
    cartCount: number
    likeCount: number
    frequentlyCount: number
    shippings: ShippingForCart[] | undefined
    ab_test_group?: string
  }) => {
    if (!shippings) {
      return
    }
    const productIds = shippings.flatMap((shipping) => shipping.orderItems.map((item) => item.productId))
    const productNames = shippings.flatMap((shipping) => shipping.orderItems.map((item) => item.productName))
    const productOptionIds = shippings.flatMap((shipping) => shipping.orderItems.map((item) => item.productOptionId))
    const productOptionNames = shippings.flatMap((shipping) =>
      shipping.orderItems.map((item) => item.productOptionName)
    )
    const productOptionQuantity = shippings.reduce(
      (acc, shipping) =>
        acc +
        shipping.orderItems.reduce((acc, item) => {
          return acc + item.quantity
        }, 0),
      0
    )

    // addtocart_all_price: 장바구니 페이지 총 결제금액
    // cartproduct_cnt: 장바구니 탭 하단에 표시되는 상품 개수
    // frequentproduct_cnt: 자주산상품 탭 하단에 표시되는 상품 개수
    // likeproduct_cnt: 찜한상품 탭 하단에 표시되는 상품 개수
    // product_ids: 상품 ID, 상품 식별자 array 형태로 전송
    // product_names: 상품 이름 array 형태로 전송
    // product_option_ids: 상품 옵션 ID, 상품 옵션 식별자 array 형태로 전송
    // product_option_names: 상품 옵션 이름 array 형태로 전송
    // product_option_quantity: 상품 옵션 수량, 장바구니에 담은 상품옵션 개수
    const properties = {
      addtocart_all_price: productAmount,
      cartproduct_cnt: cartCount,
      frequentproduct_cnt: frequentlyCount,
      likeproduct_cnt: likeCount,
      product_ids: productIds,
      product_names: productNames,
      product_option_ids: productOptionIds,
      product_option_names: productOptionNames,
      product_option_quantity: productOptionQuantity,
      ab_test_group,
    }

    triggerCustomEvent(FitpetMallEventEnum.ViewCart, properties)
  }

  /**
   * 장바구니 상단배너 클릭 이벤트
   * @link https://www.notion.so/industrious-ethernet-850/ee211b2f45f7406380ab95b3f58680b9?v=a4865763a884477b83769c2a3c79a430
   */
  const trackClickCartBannerEvent = ({ bannerName, otherSlug, eventsaleSlug }: BannerEventProperties) => {
    const slugProperty = eventsaleSlug ? { eventsaleSlug } : { otherSlug }
    triggerCustomEvent(FitpetMallEventEnum.ClickCartBanner, {
      bannerItemName: bannerName,
      ...slugProperty,
    })
  }

  const trackClickCartBottomProductEvent = ({
    sectionIndex,
    listIndex,
    sectionName,
    productId,
    productName,
    productPrice,
    productPurchasePrice,
    productDiscountRate,
    productReviewAvgScore,
    productReviewTotalCount,
  }: ClickCartBottomProductEventProperties) => {
    const eventProperties = {
      sectionIndex: sectionIndex,
      listIndex: listIndex,
      sectionName: sectionName,
      productId: decodeIdForEventTracker(productId),
      productName,
      productPrice,
      productPurchasePrice,
      productDiscountRate,
      productReviewAvgScore,
      productReviewTotalCount,
    }

    triggerCustomEvent(FitpetMallEventEnum.ClickCartBottomProduct, eventProperties)
  }

  const trackClickCartChangeCntButtonEvent = () => {
    triggerCustomEvent(FitpetMallEventEnum.ClickCartChangecntButton)
  }

  const trackClickCartChangeCntV2ButtonEvent = ({ prevCount, count }: { prevCount: number; count: number }) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickCartChangecntButton, {
      asis_cnt: prevCount,
      tobe_cnt: count,
    })
  }

  const trackClickCartChangeOptButtonEvent = () => {
    triggerCustomEvent(FitpetMallEventEnum.ClickCartChangeoptButton)
  }

  const trackClickCartPaymentButtonEvent = (cartShippingGroups: CartShippingGroupType[]) => {
    const properties = convertProductOptionCartsParams(cartShippingGroups)
    triggerCustomEvent(FitpetMallEventEnum.ClickCartPaymentButton, properties)
  }

  const trackClickCartPaymentButtonV2Event = ({
    purchaseTarget,
    totalShipmentAmount,
    totalProductAmount,
  }: {
    purchaseTarget: OrderItemForCartScheme[]
    totalProductAmount?: number
    totalShipmentAmount?: number
  }) => {
    const productIds = purchaseTarget.map((item) => item.productId)
    const productNames = purchaseTarget.map((item) => item.productName)
    const categoryIds = purchaseTarget.flatMap((item) => item.categories).map((categories) => categories.id)
    const categoryNames = purchaseTarget.flatMap((item) => item.categories).map((categories) => categories.name)

    triggerCustomEvent(FitpetMallEventEnum.ClickCartPaymentButton, {
      product_ids: productIds,
      product_names: productNames,
      category_ids: categoryIds,
      category_names: categoryNames,
      total_product_amount: totalProductAmount,
      total_shipment_amount: totalShipmentAmount,
    })
  }

  const trackClickCartDeleteSelectedEvent = (items: CartProductOptionType[]) => {
    const _cartEventProperties: {
      productIds: string[]
      productNames: string[]
      productOptionIds: string[]
      productOptionNames: string[]
    } = {
      productIds: [],
      productNames: [],
      productOptionIds: [],
      productOptionNames: [],
    }
    items.forEach((item) => {
      _cartEventProperties.productIds.push(decodeIdForEventTracker(item.productId))
      _cartEventProperties.productNames.push(item.productName)
      _cartEventProperties.productOptionIds.push(decodeIdForEventTracker(item.productOptionId))
      _cartEventProperties.productOptionNames.push(item.productOptionName)
    })
    triggerCustomEvent(FitpetMallEventEnum.ClickCartDeleteSelected, _cartEventProperties)
  }

  const trackClickCartDeleteIconEvent = (items: CartProductOptionType[]) => {
    const _cartEventProperties: {
      productIds: string[]
      productNames: string[]
      productOptionIds: string[]
      productOptionNames: string[]
    } = {
      productIds: [],
      productNames: [],
      productOptionIds: [],
      productOptionNames: [],
    }
    items.forEach((item) => {
      _cartEventProperties.productIds.push(decodeIdForEventTracker(item.productId))
      _cartEventProperties.productNames.push(item.productName)
      _cartEventProperties.productOptionIds.push(decodeIdForEventTracker(item.productOptionId))
      _cartEventProperties.productOptionNames.push(item.productOptionName)
    })
    triggerCustomEvent(FitpetMallEventEnum.ClickCartDeleteIcon, _cartEventProperties)
  }

  const trackViewCartLikeTabEvent = ({
    countProductCount,
    likeProductCount,
    frequentProductCount,
  }: CartPageTabCount) => {
    triggerCustomEvent(FitpetMallEventEnum.ViewCartLikeTab, {
      cartproduct_cnt: countProductCount,
      likeproduct_cnt: likeProductCount,
      frequentproduct_cnt: frequentProductCount,
    })
  }

  const trackViewCartFrequentTabEvent = ({
    countProductCount,
    likeProductCount,
    frequentProductCount,
  }: CartPageTabCount) => {
    triggerCustomEvent(FitpetMallEventEnum.ViewCartFrequentTab, {
      cartproduct_cnt: countProductCount,
      likeproduct_cnt: likeProductCount,
      frequentproduct_cnt: frequentProductCount,
    })
  }

  const trackClickCartNudgeMessageEvent = ({
    messageText,
    buttonText,
  }: {
    messageText: string
    buttonText: string
  }) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickCartNudgeMessage, {
      message_text: messageText,
      button_text: buttonText,
    })
  }

  const trackViewCartNudgeMessageEvent = useCallback(
    ({
      messageText,
      addAmount,
      couponDiscountAmount,
      couponDiscountRate,
    }: {
      messageText: string
      addAmount?: number
      couponDiscountAmount?: number
      couponDiscountRate?: number
    }) => {
      triggerCustomEvent(FitpetMallEventEnum.ViewCartNudgeMessage, {
        message_text: messageText,
        add_amount: addAmount,
        coupon_discount_amount: couponDiscountAmount,
        coupon_discount_rate: couponDiscountRate,
      })
    },
    [triggerCustomEvent]
  )

  /**
   *
   * 담기 버튼 클릭했을때 이벤트
   * REST API, GraphQL 응답값을 받아 변환시킨 데이터를 받는 경우
   */
  const trackClickListStartPurchaseButtonEventFinal = ({
    id,
    name,
    brandId,
    brandName,
    categoryIds,
    categoryNames,
    price,
    salePrice,
    discountRate,
    reviewCount,
    supplyType,
    searchKeyword,
    addLocation,
    sectionName,
    sectionIndex,
    listIndex,
  }: {
    id: number
    name: string
    brandId?: number
    brandName?: string
    categoryIds?: number[]
    categoryNames?: string[]
    price: number
    salePrice: number
    discountRate?: number
    reviewCount: number
    supplyType: string
    searchKeyword?: string
    addLocation?: ProductLocation
    sectionName?: string
    sectionIndex?: number
    listIndex?: number
  }) => {
    const properties = {
      product_id: id,
      product_name: name,
      brand_id: brandId,
      brand_name: brandName,
      category_ids: categoryIds,
      category_names: categoryNames,
      product_price: +price, // 강제 GraphQL Type으로 변환함 따라서 string으로 내려오는걸 강제로 number로 변환
      product_purchase_price: +salePrice,
      product_discount_rate: discountRate,
      product_review_total_count: reviewCount,
      supply_type: supplyType,
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      list_index: listIndex,
      section_index: sectionIndex,
      section_name: sectionName,
      search_keyword: searchKeyword,
    }
    triggerCustomEvent(FitpetMallEventEnum.ClickListStartPurchaseButton, properties)
  }

  const trackClickProductCouponApplyEvent = ({
    couponId,
    couponName,
    expiredAt,
    productId,
    productOptionId,
    productName,
    productOptionName,
    buttonText,
    couponDiscountAmount,
  }: {
    couponId?: number
    couponName: string
    expiredAt?: string
    productId: number
    productOptionId: number
    productName: string
    productOptionName: string
    buttonText: string
    couponDiscountAmount?: number
  }) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickProductCouponApply, {
      applied_coupon_id: couponId,
      applied_coupon_name: couponName,
      days_to_expire: expiredAt,
      coupon_discount_amount: couponDiscountAmount,
      product_id: productId,
      product_option_id: productOptionId,
      product_option_name: productOptionName,
      product_name: productName,
      button_text: buttonText,
    })
  }

  return {
    trackViewCartEvent2,
    trackClickCartBannerEvent,
    trackClickCartBottomProductEvent,
    trackClickCartChangeCntButtonEvent,
    trackClickCartChangeOptButtonEvent,
    trackClickCartPaymentButtonEvent,
    trackClickCartDeleteSelectedEvent,
    trackClickCartDeleteIconEvent,
    trackViewCartLikeTabEvent,
    trackViewCartFrequentTabEvent,
    trackClickCartNudgeMessageEvent,
    trackViewCartNudgeMessageEvent,
    trackClickListStartPurchaseButtonEventFinal,
    trackClickCartChangeCntV2ButtonEvent,
    trackClickProductCouponApplyEvent,
    trackClickCartPaymentButtonV2Event,
  }
}

export default useCartEventTracker
