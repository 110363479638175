import { useEffect } from 'react'
import { UserContainer } from '@/containers/users/UserContainer'
import { useIsAppCouponDownloadQuery } from '@/graphql/generated/hooks'

const useAppCouponDownload = () => {
  const { me } = UserContainer.useContainer()

  const { data: isAppCouponDownloadData, refetch } = useIsAppCouponDownloadQuery({
    skip: !me?.id,
    variables: {
      id: me?.id || '',
    },
  })
  const isAppCouponDownload = isAppCouponDownloadData?.user?.isAppCouponDownload

  useEffect(() => {
    if (me) {
      refetch()
    }
  }, [me])

  return { isAppCouponDownload }
}

export default useAppCouponDownload
