import { FC, MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react'
import { Grid, Row } from 'antd'
import _ from 'lodash'
import { WindowScroller } from 'react-virtualized'
import styled from 'styled-components'
import { ApiQuery } from '@/components/api'
import { QueryParamsProps } from '@/components/api/ApiQuery'
import { List } from '@/components/common'
import { Spinner } from '@/components/common/etc'
import { ListOrdersPeriodFilter } from '@/components/common/list/index'
import { ListInfiniteScrollExtractDataFromResultType } from '@/constants/legacy/constType'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { IDataResult } from '@/containers/gqls/data/dataResult'
import { IDataResults } from '@/containers/gqls/data/dataResults'
import { usePageInfo } from '@/containers/hooks'
import { isInApp } from '@/utils/utilCommon'
import { deepcopy } from '@/utils/utilData'

const { useBreakpoint } = Grid

type ListInfiniteScrollProps = {
  defaultPageSize?: number
  queryParams: QueryParamsProps
  grid?: number
  noCache?: boolean
  emptyText?: ReactNode
  refetchRef?: MutableRefObject<Function | null>
  setListTotalCount?: (totalCount: number) => void
  renderItem: (item: any, index?: number) => ReactNode
  extractDataFromResult?: ListInfiniteScrollExtractDataFromResultType
  filterPeriod?: string | undefined
  changePeriod?: (date: string | undefined) => void
  stopFetchByEmptyList?: () => void
  controlList?: any[]
  setControlList?: (list: any[]) => void
  onCompleteFetch?: (data: any) => void
  skeletonLength?: number
}

const ListInfiniteScroll: FC<ListInfiniteScrollProps> = ({
  queryParams,
  grid = 2,
  defaultPageSize = PageSizeEnum.PageSize30,
  noCache = false,
  emptyText,
  refetchRef,
  renderItem,
  setListTotalCount,
  extractDataFromResult,
  filterPeriod,
  changePeriod,
  stopFetchByEmptyList,
  setControlList,
  controlList,
  onCompleteFetch,
  skeletonLength = 0,
}) => {
  const [list, setList] = useState<any[]>([])
  const [loading, toggleLoading] = useState<boolean>(true)
  const [fetchLoading, setFetchLoading] = useState<boolean>(!isInApp())
  const { getTablePageInfoVariables, setPageNumber, ...pageProps } = usePageInfo(defaultPageSize)

  const pageNumRef = useRef<number>(1)
  const scrollRef = useRef() as MutableRefObject<HTMLDivElement>

  const { xs } = useBreakpoint()

  useEffect(() => {
    if (controlList) {
      setList(controlList)
    }
  }, [controlList])

  const handleScroll = () => {
    if (!fetchLoading || loading) {
      return
    }

    if (list.length === 0) {
      return
    }

    if (!scrollRef.current || scrollRef.current.clientHeight < 100) {
      return
    }
    const ableToRefetch = window.scrollY > scrollRef.current.clientHeight - 2000
    if (!ableToRefetch) {
      return
    }
    toggleLoading(true)
    pageNumRef.current += 1
    setPageNumber(pageNumRef.current)
  }

  const _extractDataFromResult = (response: IDataResult | IDataResults) => {
    if (!!extractDataFromResult) {
      return extractDataFromResult(response)
    }

    const dataKey = queryParams.dataKey || queryParams.gqlKey
    const tempData: any = response || { [dataKey]: { totalCount: 0, data: [] } }
    return tempData[dataKey]
  }

  const onListFetched = (response: IDataResult | IDataResults) => {
    const { data, totalCount } = _extractDataFromResult(response)
    onCompleteFetch?.(data)
    const _concatList = list.concat(data)
    setList(_concatList)
    setControlList?.(_concatList)
    setListTotalCount?.(totalCount)

    if (isInApp() && pageNumRef.current === 1) {
      setFetchLoading(true)
    }

    if (_concatList.length === totalCount) {
      setFetchLoading(false)
    }

    if (stopFetchByEmptyList && data.length === 0) {
      stopFetchByEmptyList()
      setFetchLoading(false)
    }

    toggleLoading(false)
  }

  const _queryParams = deepcopy(queryParams)
  const mergedQueryParams = _.merge(_queryParams, {
    variables: { ...getTablePageInfoVariables(pageProps.pageNumber, pageProps.pageSizeNumber) },
  })

  const getEmptyText = () => {
    if (loading) {
      return undefined
    }
    return emptyText
  }

  return (
    <ApiQuery queryParams={mergedQueryParams} noCache={noCache} onAPISuccess={onListFetched}>
      {({ refetch }) => {
        if (refetchRef) {
          refetchRef.current = refetch
        }

        return (
          <div
            ref={(r) => {
              if (r) {
                scrollRef.current = r
              }
            }}
          >
            {changePeriod && <ListOrdersPeriodFilter changePeriod={changePeriod} filterPeriod={filterPeriod} />}
            <WindowScroller onScroll={handleScroll}>
              {({ registerChild }) => (
                <>
                  {/* @ts-ignore TODO typescript 오류 수정 필요 */}
                  <div ref={registerChild}>
                    <List
                      dataSource={list}
                      grid={{ column: grid, gutter: grid === 1 ? 0 : xs ? 10 : 25 }}
                      renderItem={renderItem}
                      emptyText={getEmptyText()}
                    />
                  </div>
                </>
              )}
            </WindowScroller>
            {fetchLoading && (
              <SpinnerRow justify="center">
                <Spinner />
              </SpinnerRow>
            )}
          </div>
        )
      }}
    </ApiQuery>
  )
}

const SpinnerRow = styled(Row)`
  padding: 10px 0px;
`

export default ListInfiniteScroll
