import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { ABTestGroupCaseEnum, ABTestGroupCodeEnum } from '@/apis/rest/generated/types'
import { FloatingButton } from '@/components/domains/search/search-body-contents/components/floating-button'
import { SearchBodyContents as SearchBodyContentsA } from '@/components/domains/search/search-body-contents/search-body-contents-A'
import { SearchBodyContents as SearchBodyContentsB } from '@/components/domains/search/search-body-contents/search-body-contents-B'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useAbTest } from '@/containers/hooks/useAbTest'
import type { GraphQLProductType } from '@/utils/product/product-card.util'

interface SearchBodyContentsProps {
  recentlyProducts: GraphQLProductType[]
}

export const SearchBodyContents = ({ recentlyProducts }: SearchBodyContentsProps) => {
  const { query } = useRouter()
  const { [QueryStringKeyEnum.SearchPetType]: searchPetType } = query

  const tracker = useTracker()

  /**
   * TODO:
   *
   * 1. 서버에서 `abKey` 업데이트 시 적용하기
   */
  const { abCode } = useAbTest({
    abKey: ABTestGroupCaseEnum.SEARCH_BRAND_AREA_POSITION,
    pathname: '/search/result',
  })

  /**
   * TODO:
   *
   * 1. ABTest 끝난 후, 기존에 위치해있던 SearchBody.tsx로 로직 이동하기
   *
   * 2. ABTest 끝난 후, `abTestGroup` 파라미터 제거
   */
  useEffect(() => {
    if (searchPetType && abCode) {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ViewSearch, {
        petTypeSearch: searchPetType,
        ...(abCode && { abTestGroup: abCode === ABTestGroupCodeEnum.A ? 'search_brand_a' : 'search_brand_b' }),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPetType, abCode])

  if (abCode === ABTestGroupCodeEnum.B) {
    return (
      <>
        <SearchBodyContentsB recentlyProducts={recentlyProducts} />
        <FloatingButton abCode={abCode} />
      </>
    )
  }

  return (
    <>
      <SearchBodyContentsA recentlyProducts={recentlyProducts} />
      <FloatingButton abCode={abCode as 'A' | 'B'} />
    </>
  )
}
