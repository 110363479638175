import _defineProperty from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.12.5/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {
  "id": "09783645ca269f0bafda13ffd3ed17b92fc261a7"
};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/mall";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { encodeId } from '@/utils/utilApi';
import * as Sentry from '@sentry/nextjs';
var SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
var processGraphQLBreadcrumb = function processGraphQLBreadcrumb(breadcrumb, hint) {
  var requestBody = hint.input;
  breadcrumb.message = "GraphQL Request: ".concat(breadcrumb.data.url);
  if (requestBody) {
    try {
      var _requestBody$;
      var requestJson = JSON.parse(((_requestBody$ = requestBody[1]) === null || _requestBody$ === void 0 ? void 0 : _requestBody$.body) || requestBody);
      enrichBreadcrumbWithGraphQLData(breadcrumb, requestJson);
    } catch (error) {
      console.error('Failed to parse request body:', error);
    }
  }
  return breadcrumb;
};
var enrichBreadcrumbWithGraphQLData = function enrichBreadcrumbWithGraphQLData(breadcrumb, requestJson) {
  if (requestJson.query) {
    breadcrumb.data.query = requestJson.query;
  }
  if (requestJson.variables) {
    breadcrumb.data.variables = requestJson.variables;
  }
  if (requestJson.operationName) {
    breadcrumb.data.operationName = requestJson.operationName;
    breadcrumb.data.endcoedOperationName = encodeId('queryName', requestJson.operationName);
  }
};
var adjustDataUrlBreadcrumb = function adjustDataUrlBreadcrumb(breadcrumb) {
  return _objectSpread(_objectSpread({}, breadcrumb), {}, {
    data: _objectSpread(_objectSpread({}, breadcrumb.data), {}, {
      url: breadcrumb.data.url.startsWith('data:') ? breadcrumb.data.url.substr(0, 100) : breadcrumb.data.url
    })
  });
};
Sentry.init({
  dsn: SENTRY_DSN || 'https://3a78bbfabf2f461fa209e89536e833df@o356934.ingest.sentry.io/5962478',
  // Adjust this value in production, or use tracesSampler for greater control
  environment: process.env.FITPETMALL_ENV,
  tracesSampleRate: 1.0,
  beforeBreadcrumb: function beforeBreadcrumb(breadcrumb, hint) {
    // Process fetch breadcrumbs
    if (breadcrumb.category === 'fetch') {
      if (breadcrumb.data.url.includes('/graphql')) {
        return processGraphQLBreadcrumb(breadcrumb, hint);
      }

      // Adjust data URLs to prevent payload too large errors
      return adjustDataUrlBreadcrumb(breadcrumb);
    }
    return breadcrumb;
  },
  attachStacktrace: true,
  sendDefaultPii: true,
  integrations: [],
  ignoreErrors: [
  // Error generated by a bug in auto-fill library from browser
  // https://github.com/getsentry/sentry/issues/5267
  /Blocked a frame with origin/, 'TypeError: Failed to fetch', 'TypeError: NetworkError when attempting to fetch resource.', 'TypeError: Cancelled', 'TypeError: Load failed']
});