import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SWEET_TRACKER } from '@/constants/legacy/constEnv'
import { doSetGlobalNoticeModal } from '@/stores/reduxUI'

type ShippingTrackInfoType = {
  companyCode: string
  shippingNumber: string
}

type OrderItemSweetTrackerProps = {
  shippingTrackInfo?: ShippingTrackInfoType
}

type PopupType = Window | null | undefined | 'init'

const OrderItemSweetTracker: FC<OrderItemSweetTrackerProps> = ({ shippingTrackInfo }) => {
  const [shippingTrackInfoForm, setShippingTrackInfoForm] = useState<ShippingTrackInfoType>()
  const buttonRef = useRef<HTMLButtonElement>()
  const dispatch = useDispatch()
  const [popup, setPopup] = useState<PopupType>('init')

  const handleSubmit = () => {
    const popup = window.open('', 'popup_window', 'width=450,height=800,resizeable,scrollbars')
    setPopup(popup)
  }

  useEffect(() => {
    if (shippingTrackInfo) {
      setShippingTrackInfoForm(shippingTrackInfo)
    }
  }, [shippingTrackInfo])

  useEffect(() => {
    if (shippingTrackInfoForm) {
      buttonRef.current?.click()
    }
  }, [shippingTrackInfoForm])

  useEffect(() => {
    if (!popup) {
      dispatch(
        doSetGlobalNoticeModal({
          buttonType: 'ACTION',
          okText: '확인',
          text: '브라우저에서 팝업이 차단되어 있어서\n배송 조회 창이 뜨지 않습니다',
          subText: '브라우저 설정에서 팝업 차단을 해제해 주세요.',
          visible: true,
        })
      )
      setPopup('init')
    }
  }, [popup])

  return (
    <form
      id="sweetTrackerForm"
      style={{ display: 'none' }}
      action={SWEET_TRACKER.URL}
      method="post"
      target="popup_window"
      onSubmit={() => handleSubmit()}
    >
      <div>
        <input type="text" id="t_key" name="t_key" value={SWEET_TRACKER.API_KEY} readOnly />
      </div>
      <div>
        <input type="text" name="t_code" id="t_code" value={shippingTrackInfoForm?.companyCode} readOnly />
      </div>
      <div>
        <input type="text" name="t_invoice" id="t_invoice" value={shippingTrackInfoForm?.shippingNumber} readOnly />
      </div>

      <button
        // @ts-ignore
        ref={buttonRef}
        id="sweetTrackerFormButton"
        type="submit"
      />
    </form>
  )
}

export default OrderItemSweetTracker
