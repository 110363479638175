import React, { useState, useEffect } from 'react'
import qs from 'qs'
import styled from 'styled-components'
import { Text } from '@/components/common'
import { Typo, TypoVariant } from '@/components/common/typography'
import { FontWeightEnum } from '@/constants/font-weight.enum'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { FITPETMALL_HOST, S3_FOLDERS_LEGACY, S3_HOST_URL } from '@/constants/legacy/constEnv'
import { LAYOUT_UNITS, Z_INDEXES } from '@/constants/legacy/constLayout'
import { useCustomRouter } from '@/containers/hooks'
import { isTouchDevice } from '@/utils/utilCommon'

/**
 * 공유된 페이지 URL로 접근 시 뜨는 모달
 * 디자인 시스템 모달이 만들어지면 교체필요
 */
const ModalSharedPageAppLanding = () => {
  const logoImageUrl = `${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/fitpet_new_logo.svg`
  const { query, replace, asPath } = useCustomRouter()

  const { oneLinkUrl } = query

  const [isSharedPage, setIsSharedPage] = useState<boolean>(false)

  // 쿼리스트링으로 전달받은 oneLinkUrl로 이동
  const openFitpetApp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    const url = asPath.split('oneLinkUrl=')[1]
    setIsSharedPage(false)
    window.open(decodeURIComponent(url))
  }

  // oneLinkUrl 쿼리스트링을 지우고 현재 페이지로 replace
  const onClickCancel = () => {
    const queryObject = query
    delete queryObject.oneLinkUrl

    const path = asPath.split('?')[0]

    const url = `${FITPETMALL_HOST}/${path}?${qs.stringify(queryObject)}`
    setIsSharedPage(false)
    replace(url)
  }

  useEffect(() => {
    if (!oneLinkUrl || !isTouchDevice()) {
      return
    }
    setIsSharedPage(true)
  }, [oneLinkUrl])

  return (
    <ModalContainer open={isSharedPage}>
      <Background visible={isSharedPage} onClick={() => onClickCancel()}>
        <Modal visible={isSharedPage}>
          <StyledContentsWrapper>
            <div className={'img-container'}>
              <img src={logoImageUrl} alt="" />
            </div>
            <StyledText>
              <Typo variant={TypoVariant.Heading6Bold}>핏펫 앱으로 보고 \n매달 풍성한 할인 혜택을 받아보세요</Typo>
            </StyledText>
          </StyledContentsWrapper>
          <StyledButtonWrapper>
            <ConfirmButton onClick={(e) => openFitpetApp(e)}>
              <Text.Body1 fontWeight={FontWeightEnum.Bold} fontColor={GlobalColorEnum.GrayWhite}>
                핏펫 앱으로 보기
              </Text.Body1>
            </ConfirmButton>

            <CancelButton onClick={() => onClickCancel()}>
              <Text.Body1 fontColor={GlobalColorEnum.Gray500}>웹으로 보기</Text.Body1>
            </CancelButton>
          </StyledButtonWrapper>
        </Modal>
      </Background>
    </ModalContainer>
  )
}

export default ModalSharedPageAppLanding

const ModalContainer = styled.div<{ open: boolean }>`
  position: fixed;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  top: 0;
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  z-index: ${Z_INDEXES.BOTTOM_SLIDE_POPUP};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Background = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  height: 100vh;
  background-color: ${({ visible }) => (visible ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)')};
  transition: background-color 0.3s ease;
`
const Modal = styled.div<{ visible: boolean }>`
  position: absolute;
  width: calc(100% - 55px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 0 0;
  border-radius: 1.6rem;
  padding-top: 4rem;
  padding-bottom: 2.4rem;
  background-color: ${(props) => props.theme.color.grayWhite};
  animation: ${({ visible }) => (visible ? `0.3s forwards scaleUpModal` : `0.2s ease forwards scaleDownModal`)};

  @keyframes scaleUpModal {
    0% {
      scale: 0;
    }
    100% {
      scale: 1;
    }
  }

  @keyframes scaleDownModal {
    0% {
      scale: 1;
    }
    100% {
      scale: 0;
    }
  }
`

const StyledContentsWrapper = styled.section`
  padding: 0;
  margin-bottom: 4rem;

  .img-container {
    margin-bottom: 1.6rem;
    display: flex;
    justify-content: center;
  }
`

const StyledText = styled.div`
  text-align: center;
  white-space: pre;
`

const StyledButtonWrapper = styled.section`
  padding: 0 1.6rem;
`

const ConfirmButton = styled.button`
  border-radius: 8px;
  width: 100%;
  padding: 1.3rem 1.6rem;
  border: none;
  background-color: ${(props) => props.theme.color.blue500};
`

const CancelButton = styled.button`
  width: 100%;
  border: none;
  margin-top: 2rem;
  background-color: transparent;
  p {
    text-align: center;
  }
`
