import { FC, useState } from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { ImageAsset, List, TextLegacy } from '@/components/common'
import { RectangleButtonLegacy } from '@/components/common/buttons'
import { ModalSlider } from '@/components/common/modals'
import { ModalBaseProps } from '@/components/common/modals/ModalBase'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { FIXED_HEIGHT, LAYOUT_UNITS, Z_INDEXES } from '@/constants/legacy/constLayout'
import { IModelProductOption } from '@/containers/models/modelProductOption'

type ModalProductInquiryOptionSelectProps<T> = {
  productOptions: IModelProductOption[]
  onSelect: (optionId: string) => void
} & ModalBaseProps<T>

const ModalProductInquiryOptionSelect: FC<ModalProductInquiryOptionSelectProps<any>> = ({
  productOptions,
  useModalProps,
  onSelect,
}) => {
  const [selectedId, setSelectedId] = useState<string>()

  const onSelectOption = (optionId: string) => {
    setSelectedId(optionId)
  }

  const _onConfirm = () => {
    onSelect(selectedId!)
    useModalProps.hideModal()
  }

  return (
    <ModalSlider title="옵션 선택" titleNoBorder useModalProps={useModalProps}>
      <>
        <ListContainer>
          <List
            dataSource={productOptions}
            renderItem={(item, index) => (
              <RenderListRow
                key={item.id}
                option={item}
                isLast={index === productOptions.length - 2}
                selected={selectedId === item.id}
                onSelect={onSelectOption}
              />
            )}
          />
        </ListContainer>
        <BottomButtonContainer>
          <RectangleButtonLegacy
            disabled={!selectedId}
            text="완료"
            textStyle={{
              size: {
                xs: 16,
                md: 18,
              },
              weight: 'bold',
            }}
            onClick={_onConfirm}
          />
        </BottomButtonContainer>
      </>
    </ModalSlider>
  )
}

type RenderListRowProps = {
  option: IModelProductOption
  selected: boolean
  isLast: boolean
  onSelect: (optionId: string) => void
}

const RenderListRow = ({ option, selected, isLast, onSelect }: RenderListRowProps) => {
  const { id, name = '' } = option

  const getBackgroundColor = () => {
    if (selected) {
      return GlobalColorEnum.Blue30
    }
    return GlobalColorEnum.GrayWhite
  }

  const getFontColor = () => {
    if (selected) {
      return 'blue500'
    }
    return 'default'
  }

  const getFontWeight = () => {
    if (selected) {
      return 'bold'
    }

    return 'normal'
  }

  return (
    <ListRow
      wrap={false}
      justify="space-between"
      align="middle"
      background={getBackgroundColor()}
      className="button"
      onClick={() => onSelect(id)}
      noborder={isLast ? 1 : 0}
    >
      <Col>
        <TextLegacy size={{ xs: 13, md: 15 }} color={getFontColor()} weight={getFontWeight()}>
          {name}
        </TextLegacy>
      </Col>
      {selected && (
        <Col flex="1">
          <Row justify="end">
            <ImageAsset name="check_circle_blue_new" size={20} svg />
          </Row>
        </Col>
      )}
    </ListRow>
  )
}

const ListContainer = styled.div`
  padding-bottom: ${FIXED_HEIGHT.PRODUCT_ORDER_BUTTON}px;

  max-height: calc(90vh - 146px); /* 상단 모달 헤더 58px + 하단 버튼 container 88px */
  overflow-y: scroll;

  /* remove scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const BottomButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${(props) => props.theme.color.grayWhite};
  z-index: ${Z_INDEXES.BOTTOM_SLIDE_POPUP + 1};
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  margin: auto 0;
  padding: 20px;
`

const ListRow = styled(Row)<{ background: string; noborder: number }>`
  padding: 0 20px;
  height: 58px;
  border-bottom: ${({ noborder }) => (noborder ? 'none' : `1px solid ${GlobalColorEnum.Gray70}`)};
  background-color: ${({ background }) => background};
`

export default ModalProductInquiryOptionSelect
