import styled from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { useCustomRouter } from '@/containers/hooks'

interface FloatingButtonProps {
  abCode?: 'A' | 'B'
}

export const FloatingButton = (props: FloatingButtonProps) => {
  const router = useCustomRouter()

  const handleClickButton = () => {
    router.replace({
      pathname: router.pathname,
      query: {
        ...router.query,
        ab: props?.abCode === 'B' ? 'A' : 'B',
      },
    })
  }

  if (process.env.FITPETMALL_ENV === 'production') {
    return null
  }

  return (
    <StyledButton type="button" onClick={handleClickButton}>
      ABTest: {props?.abCode} {'->'} {props?.abCode === 'B' ? 'A' : 'B'}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  padding: 1.2rem 1.6rem;
  border-radius: 1.2rem;
  border: 1px solid ${GlobalColorEnum.Blue500};
  background-color: ${GlobalColorEnum.Blue500};
  color: white;
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  cursor: pointer;
`
