import { FC } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IModelTransaction } from '@/containers/models/modelTransaction'
import { date2string } from '@/utils/utilDate'
import { currencyText } from '@/utils/utilNumber'

type OrderDetailSectionAccountInfoProps = {
  transaction: IModelTransaction
}

const OrderDetailSectionAccountInfo: FC<OrderDetailSectionAccountInfoProps> = ({ transaction }) => {
  const { vbankAccountHolderName, vbankBankName, vbankAccountNumber, vbankEndedAt, amount } = transaction

  const endedAtString = date2string(vbankEndedAt, 'YYYY.MM.DD A h시 m분까지')
    .replace('PM', '오후')
    .replace('AM', '오전')

  return (
    <StyledContainer>
      <RenderRow title="입금계좌" value={`${vbankBankName} ${vbankAccountNumber}`} />
      <RenderRow title="금액" value={currencyText(amount)} />
      <RenderRow title="예금주" value={vbankAccountHolderName} />
      <RenderRow title="입금기한" value={endedAtString} />
    </StyledContainer>
  )
}

const RenderRow = ({ title, value }: { title: string; value: string }) => {
  return (
    <Row>
      <Col xs={6} md={4}>
        <TextLegacy size={{ xs: 12, md: 14 }}>{title}</TextLegacy>
      </Col>
      <Col>
        <TextLegacy size={{ xs: 12, md: 14 }}>{value}</TextLegacy>
      </Col>
    </Row>
  )
}

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.color.grayWhite};
  padding: 16px;
  border-radius: 4px;
  margin: 0 20px 16px 20px;
`

export default OrderDetailSectionAccountInfo
