import { FC, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { SelectBox } from '@/components/common/layouts'
import ModalSelectInquiryPeriod from '@/components/domains/modals/ModalSelectInquiryPeriod'
import { PERIOD_LIST } from '@/constants/legacy/constData'
import { useModal } from '@/containers/hooks'
import { addDay, DATE_FORMAT_YYYY_MM_DD, diffDateNow } from '@/utils/utilDate'

type ListOrdersPeriodFilterProps = {
  filterPeriod: string | undefined
  changePeriod: (date: string | undefined) => void
}

const ListOrdersPeriodFilter: FC<ListOrdersPeriodFilterProps> = ({ changePeriod, filterPeriod }) => {
  const defaultPeriod = _.find(PERIOD_LIST, { days: diffDateNow(filterPeriod!) * -1 }) || PERIOD_LIST[2]
  const [selectedPeriod, setSelectedPeriod] = useState<string>(defaultPeriod.text)
  const { useModalProps } = useModal()

  const handlePeriodClick = (selected: string) => {
    setSelectedPeriod(selected)

    const period = _.find(PERIOD_LIST, { text: selected })!
    if (period.days === -1) {
      changePeriod(undefined)
      return
    }
    changePeriod(addDay(period.days, undefined, DATE_FORMAT_YYYY_MM_DD))
  }

  return (
    <>
      <StyledWrapper>
        <SelectBox
          value={selectedPeriod}
          onClick={() => {
            useModalProps.showModal()
          }}
        />
      </StyledWrapper>
      <ModalSelectInquiryPeriod
        handleClick={handlePeriodClick}
        useModalProps={useModalProps}
        selectedPeriod={selectedPeriod}
      />
    </>
  )
}

const StyledWrapper = styled.div`
  padding: 16px;
`

export default ListOrdersPeriodFilter
