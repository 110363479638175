import React, { FC } from 'react'
import styled from 'styled-components'
import { ImageAsset, TextLegacy } from '@/components/common'
import { Button } from '@/components/common/buttons'
import { GlobalColorEnum } from '@/constants/global-color.enum'

type Props = {
  disabled: boolean
  onClick: () => void
}

const AddCartButton: FC<Props> = ({ disabled, onClick }) => {
  return (
    <StyledWrapper>
      <Button.Tertiary disabled={disabled} onClick={onClick} width={'116px'} height={'33px'}>
        <ImageAsset name={disabled ? 'ic_cart_gray' : 'ic_cart'} size={16} svg />
        <TextLegacy
          weight={400}
          size={13}
          color={disabled ? GlobalColorEnum.Gray200 : GlobalColorEnum.Gray900}
          customStyle={`margin-left: 2px;`}
        >
          장바구니 담기
        </TextLegacy>
      </Button.Tertiary>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  min-width: 116px;
`

export default AddCartButton
