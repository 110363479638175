import { FC, useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { QueryParamsProps } from '@/components/api/ApiQuery'
import { ListInfiniteScroll } from '@/components/common/list'
import { ListOrderEmpty } from '@/components/common/list/empty'
import { ListItemOrder } from '@/components/domains/listItems'
import { ListOrderType } from '@/constants/legacy/constType'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { doSetToggleRefetchOrderList } from '@/stores/reduxData'
import { RootState } from '@/stores/store'
import { addDay, DATE_FORMAT_YYYY_MM_DD } from '@/utils/utilDate'

type ListOrdersProps = {
  type: ListOrderType
}

const ListOrders: FC<ListOrdersProps> = ({ type }) => {
  const refetchRef = useRef<any>()
  const [forceRerender, toggleForceRerender] = useState<boolean>(false)
  const [filterPeriod, setfilterPeriod] = useState<string | undefined>(addDay(-183, undefined, DATE_FORMAT_YYYY_MM_DD))
  const dispatch = useDispatch()
  const shouldRefetch = useSelector((rootState: RootState) => rootState.reduxDataReducers.shouldRefetchOrderList)

  useEffect(() => {
    if (forceRerender) {
      toggleForceRerender(false)
    }
  }, [forceRerender])

  useEffect(() => {
    toggleForceRerender(true)
  }, [filterPeriod])

  useEffect(() => {
    if (shouldRefetch) {
      dispatch(doSetToggleRefetchOrderList(false))
      toggleForceRerender(true)
    }
  }, [shouldRefetch])

  const getIncludeOrderStatus = (): OrderStatusTypeEnum[] | undefined => {
    if (type === 'cancel-return-exchange') {
      return [
        OrderStatusTypeEnum.CancelRequested,
        OrderStatusTypeEnum.ReturnRequested,
        OrderStatusTypeEnum.ReturnUnderReturn,
        OrderStatusTypeEnum.ReturnConfirmed,
        OrderStatusTypeEnum.ExchangeRequested,
        OrderStatusTypeEnum.ExchangeUnderReturn,
        OrderStatusTypeEnum.Canceled,
        OrderStatusTypeEnum.ReturnReturned,
        OrderStatusTypeEnum.ExchangeConfirmed,
      ]
    }

    if (type === 'cancel-return-exchange-processing') {
      return [
        OrderStatusTypeEnum.CancelRequested,
        OrderStatusTypeEnum.ReturnRequested,
        OrderStatusTypeEnum.ReturnUnderReturn,
        OrderStatusTypeEnum.ReturnConfirmed,
        OrderStatusTypeEnum.ExchangeUnderReturn,
      ]
    }

    if (type === 'cancel-return-exchange-processed') {
      return [OrderStatusTypeEnum.Canceled, OrderStatusTypeEnum.ReturnReturned, OrderStatusTypeEnum.ExchangeConfirmed]
    }
  }

  const filterOrderStatus = getIncludeOrderStatus()
  const queryParams: QueryParamsProps = {
    gqlKey: 'orders',
    dataKey: 'orders',
    variables: {
      filter: {
        orderStatus: filterOrderStatus && { include: filterOrderStatus },
        orderBy: '-createdAt',
        createdAtAfter: filterPeriod,
      },
    },
  }

  return (
    <div>
      {!forceRerender && (
        <StyledWrapper>
          <ListInfiniteScroll
            noCache
            refetchRef={refetchRef}
            defaultPageSize={PageSizeEnum.PageSize10}
            queryParams={queryParams}
            grid={1}
            filterPeriod={filterPeriod}
            changePeriod={setfilterPeriod}
            renderItem={(item) => <ListItemOrder order={item} includeOrderStatus={filterOrderStatus} />}
            emptyText={<ListOrderEmpty type={type} />}
          />
        </StyledWrapper>
      )}
    </div>
  )
}

const StyledWrapper = styled.div``

export default ListOrders
