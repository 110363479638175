import { FC } from 'react'
import styled from 'styled-components'
import { match } from 'ts-pattern'
import { ImageAsset, TextLegacy } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import ListOrderItemCard from '@/components/domains/order/ListOrderItemCard'
import ActionPowerListOrderItemCard from '@/components/domains/order/ListOrderItemCard/ActionPowerListOrderItemCard'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { useOrderCancel } from '@/containers/hooks'
import { IModelOrder } from '@/containers/models/modelOrder'
import { ProductProductType } from '@/graphql/generated/schemas'

type Props = {
  order: IModelOrder
  includeOrderStatus?: OrderStatusTypeEnum[]
  handleAddCartOrderItem?: (quantity: number, productOptionId: string, productPromotionId?: string) => Promise<void>
}

const ListItemOrder: FC<Props> = ({ order, includeOrderStatus, handleAddCartOrderItem }) => {
  const { confirmCancelAllUnpaidOrderItems } = useOrderCancel()
  const { id, orderOrderItems, getAllOrderItemIds, getFormattedOrderDate, membership } = order

  return (
    <StyledWrapper>
      <StyledTopSection>
        <TextLegacy weight="bold" size={16}>
          {getFormattedOrderDate()}
        </TextLegacy>
        <LinkButton
          url={{
            pathname: ROUTES.MYPAGE.ORDER_DETAIL,
            query: {
              orderId: id,
            },
          }}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <TextLegacy size={13} color={GlobalColorEnum.Gray500}>
            주문상세
          </TextLegacy>
          <ImageAsset name="ic_left_gray500_w6" svg />
        </LinkButton>
      </StyledTopSection>

      {orderOrderItems.map((orderItem) => {
        const { orderStatus } = orderItem
        // @ts-ignore
        if (includeOrderStatus && !includeOrderStatus.includes(orderStatus)) {
          return null
        }

        return (
          <StyledOrderItemCardSection key={orderItem._id}>
            {match(orderItem.productOption.product.productType)
              .with(ProductProductType.ActionPower, () => (
                <ActionPowerListOrderItemCard
                  orderId={id}
                  orderItem={orderItem}
                  confirmCancelAll={() => {
                    confirmCancelAllUnpaidOrderItems(getAllOrderItemIds())
                  }}
                  handleAddCartOrderItem={handleAddCartOrderItem}
                />
              ))
              .with(ProductProductType.Normal, () => (
                <ListOrderItemCard
                  orderId={id}
                  orderItem={orderItem}
                  confirmCancelAll={() => {
                    confirmCancelAllUnpaidOrderItems(getAllOrderItemIds())
                  }}
                  handleAddCartOrderItem={handleAddCartOrderItem}
                  isMembershipBenefited={!!membership?.id}
                />
              ))
              .exhaustive()}
          </StyledOrderItemCardSection>
        )
      })}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin-bottom: 10px;
`

const StyledTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0px 16px 0px 16px;
  background-color: ${(props) => props.theme.color.grayWhite};
  border-bottom: 1px solid ${GlobalColorEnum.Gray70};
`

const StyledOrderItemCardSection = styled.div`
  border-bottom: 1px solid ${GlobalColorEnum.Gray70};
`

export default ListItemOrder
