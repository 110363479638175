import { FC, useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { QueryParamsProps } from '@/components/api/ApiQuery'
import { TextLegacy } from '@/components/common'
import { InputCheckbox } from '@/components/common/inputs'
import { ListInfiniteScroll } from '@/components/common/list'
import { ListItemProductReview } from '@/components/domains/listItems'
import { ModalReviewImageFullScreen } from '@/components/domains/modals'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import { OrderBy } from '@/constants/order-by.enum'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { IModelReview } from '@/containers/models/modelReview'

type ListProductReviewsProps = {
  productId: string
}

const ListProductReviews: FC<ListProductReviewsProps> = ({ productId }) => {
  const [forceRerender, setForceRerender] = useState<boolean>(false)
  const [filterPhotoOnly, setFilterPhotoOnly] = useState<boolean>(false)
  const [filterOrderBy, setFilterOrderBy] = useState<OrderBy>(OrderBy.Weight)

  useEffect(() => {
    setForceRerender(true)
  }, [filterOrderBy, filterPhotoOnly])

  useEffect(() => {
    if (forceRerender) {
      setForceRerender(false)
    }
  }, [forceRerender])

  const getOrderBy = () => {
    if (OrderBy.Weight === filterOrderBy) return '-weight,-score,-createdAt'
    if (OrderBy.Score === filterOrderBy) return '-score,-createdAt'
    return '-createdAt'
  }

  const queryParams: QueryParamsProps = {
    gqlKey: 'reviewList',
    dataKey: 'reviews',
    variables: {
      filter: {
        product: productId,
        isPhotoReview: filterPhotoOnly || undefined,
        isDelete: false,
        orderBy: getOrderBy(),
      },
    },
  }

  return (
    <div>
      <RenderFilter
        orderBy={filterOrderBy}
        setOrderBy={setFilterOrderBy}
        photoReviewOnly={filterPhotoOnly}
        togglePhotoReviewOnly={setFilterPhotoOnly}
      />

      {!forceRerender && (
        <ListInfiniteScroll
          defaultPageSize={PageSizeEnum.PageSize30}
          grid={1}
          noCache
          queryParams={queryParams}
          renderItem={(item: IModelReview) => <ListItemProductReview key={item.id} review={item} />}
        />
      )}
      <ModalReviewImageFullScreen />
    </div>
  )
}

const RenderFilter = ({
  orderBy,
  setOrderBy,
  photoReviewOnly,
  togglePhotoReviewOnly,
}: {
  orderBy: OrderBy
  setOrderBy: (orderBy: OrderBy) => void
  photoReviewOnly: boolean
  togglePhotoReviewOnly: (toggle: boolean) => void
}) => {
  return (
    <FilterRow justify="space-between" align="middle">
      <Col>
        <Row gutter={15}>
          <RenderFilterCol text="추천순" filter={OrderBy.Weight} selectedFilter={orderBy} onClick={setOrderBy} />
          <RenderFilterCol text="최신순" filter={OrderBy.CreatedAt} selectedFilter={orderBy} onClick={setOrderBy} />
          <RenderFilterCol text="평점순" filter={OrderBy.Score} selectedFilter={orderBy} onClick={setOrderBy} />
        </Row>
      </Col>
      <Col>
        <InputCheckbox
          id="only-photo-check"
          checked={photoReviewOnly}
          onChange={(e) => togglePhotoReviewOnly(e.target.checked)}
        >
          <StyledLabel>포토리뷰만 보기</StyledLabel>
        </InputCheckbox>
      </Col>
    </FilterRow>
  )
}

const RenderFilterCol = ({
  text,
  filter,
  selectedFilter,
  onClick,
}: {
  text: string
  filter: OrderBy
  selectedFilter: OrderBy
  onClick: (filter: OrderBy) => void
}) => {
  const isSelected = selectedFilter === filter
  const fontWeight = isSelected ? 'bold' : 'normal'
  const fontColor = isSelected ? 'default' : 'gray400'

  return (
    <FilterCol onClick={() => onClick(filter)} selected={isSelected}>
      <TextLegacy size={{ xs: 14, md: 16 }} weight={fontWeight} color={fontColor}>
        {text}
      </TextLegacy>
    </FilterCol>
  )
}

const FilterRow = styled(Row)`
  padding: 0 12px 0 20px;
  border-bottom: 0.5px solid rgb(239, 239, 239);
`

const FilterCol = styled(Col)<{ selected: boolean }>`
  border-bottom: ${({ selected }) => (selected ? `1px solid #000` : 'none')};
  padding: 10px 0;
  cursor: pointer;
`

const StyledLabel = styled.span`
  margin: 0;
  padding-top: 0.6px;
  padding-left: 2px;
  font-size: 14.5px;
  line-height: 1.4;
  font-weight: 400;
  text-align: left;
  color: ${(props) => props.theme.color.grayBlack};

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: 13px;
  }
`

export default ListProductReviews
