import { FC, useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import _ from 'lodash'
import styled, { useTheme } from 'styled-components'
import { ImageAsset, TextLegacy } from '@/components/common'
import { RectangleButtonLegacy } from '@/components/common/buttons'
import { ModalSlider } from '@/components/common/modals'
import { ModalBaseProps } from '@/components/common/modals/ModalBase'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { LAYOUT_UNITS, Z_INDEXES } from '@/constants/legacy/constLayout'

type ModalListTextSelectProps = {
  title: string
  listText: string[]
  onFinish: (selectedValue: string) => void
  defaultValue?: string
} & ModalBaseProps<any>

const ModalListTextSelect: FC<ModalListTextSelectProps> = ({
  useModalProps,
  title,
  listText,
  defaultValue,
  onFinish,
}) => {
  // const [ selected, setSelected ] = useState<string | undefined>( defaultValue )
  const [selected, setSelected] = useState<string | undefined>()
  const { color } = useTheme()

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue)
    }
  }, [defaultValue])

  const onSelect = (message: string) => {
    setSelected(message)
  }

  const _onFinish = () => {
    onFinish(selected!)
    useModalProps.hideModal()
  }

  return (
    <ModalSlider title={title} useModalProps={useModalProps}>
      <ContentContainer>
        {listText.map((text, index) => (
          <RenderRow key={index} text={text} selected={selected === text} onSelect={onSelect} />
        ))}
      </ContentContainer>

      <BottomButtonContainer>
        <RectangleButtonLegacy
          colorTheme={color.blue500}
          height={60}
          radius={8}
          text="완료"
          disabled={_.isEmpty(selected)}
          textStyle={{
            size: {
              xs: 16,
              md: 18,
            },
            weight: 'bold',
          }}
          onClick={_onFinish}
        />
      </BottomButtonContainer>
    </ModalSlider>
  )
}

type RenderRowType = {
  text: string
  selected: boolean
  onSelect: (message: string) => void
}

const RenderRow = ({ text, selected, onSelect }: RenderRowType) => {
  const fontWeight = selected ? 'bold' : 'normal'
  const fontColor = selected ? 'blue500' : 'default'

  return (
    <StyledRow
      align="middle"
      justify="space-between"
      selected={selected ? 1 : 0}
      className="button"
      onClick={() => onSelect(text)}
    >
      <Col>
        <TextLegacy weight={fontWeight} color={fontColor}>
          {text}
        </TextLegacy>
      </Col>
      {selected && (
        <Col>
          <ImageAsset name="check_circle_blue_new" size={20} svg />
        </Col>
      )}
    </StyledRow>
  )
}

const StyledRow = styled(Row)<{ selected: number }>`
  padding: 20px;
  border-bottom: 1px solid ${GlobalColorEnum.Gray70};
  background-color: ${({ selected }) => (selected ? GlobalColorEnum.Blue50 : GlobalColorEnum.GrayWhite)};
`

const ContentContainer = styled.div`
  max-height: calc(90vh - 158px); /* 상단 모달 헤더 58px + 하단 버튼 container 100px */
  overflow-y: scroll;

  /* remove scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const BottomButtonContainer = styled.div`
  position: sticky;
  bottom: 0px;
  width: 100%;
  z-index: ${Z_INDEXES.BOTTOM_SLIDE_POPUP + 1};
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  margin: auto 0;
  padding: 20px;
  background-color: ${(props) => props.theme.color.grayWhite};
`

export default ModalListTextSelect
