import { ValueOf } from 'type-fest'
import { ProductPromotionScheme } from '@/apis/rest/generated/types'
import { TypoVariant } from '@/components/common/typography'
import { GlobalTheme } from '@/styles/globalTheme'

export const PRODUCT_CARD_SIZES = {
  md: 'md',
  lg: 'lg',
  half: 'half',
  full: 'full',
} as const

export const THUMBNAIL_SIZES = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  half: 'half',
  full: 'full',
} as const

export const THUMBNAIL_STATES = {
  loading: 'loading',
  imageOnly: 'imageOnly',
  soldOut: 'soldOut',
  notAvailable: 'notAvailable',
  basicLabel: 'basicLabel',
  rankingLabel: 'rankingLabel',
} as const

export type ThumbnailTrackingData = {
  price: number
  discountRate: number
  discountPrice: number
  reviewCount: number
  reviewScore: number
}

export interface ThumbnailType {
  productId: number
  productName: string
  productPromotionScheme?: ProductPromotionScheme
  size: ThumbnailSize
  state: ThumbnailState
  imageUrl: string
  isLiked: boolean
  isRealSoldOut: boolean
  isNotAvailable?: boolean
  rankingNumber?: number
  basicLabel?: BasicLabelType
  trackingData: ThumbnailTrackingData
  callbackLike?: () => void
}

export const CARD_BODY_VERTICAL_SIZES = {
  md: 'md',
  lg: 'lg',
  half: 'half',
  full: 'full',
} as const

export interface BasicLabelType {
  value: string | number
  fontColor?: ValueOf<typeof GlobalTheme.color>
  variant?: TypoVariant
  backgroundColor?: ValueOf<typeof GlobalTheme.color>
}

export const CARD_BODY_PRICE_INFO_TYPES = {
  DEFAULT: 'DEFAULT',
  COUPON: 'COUPON',
} as const

export type CardBodyPriceInfoType = keyof typeof CARD_BODY_PRICE_INFO_TYPES

export type ProductCardSize = keyof typeof PRODUCT_CARD_SIZES
export type ThumbnailSize = keyof typeof THUMBNAIL_SIZES
export type ThumbnailState = 'loading' | 'imageOnly' | 'soldOut' | 'notAvailable' | 'basicLabel' | 'rankingLabel'
export type CardBodyVerticalSize = 'md' | 'lg' | 'half' | 'full'
