import { FC, useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import ApiQuery, { QueryParamsProps } from '@/components/api/ApiQuery'
import { ImageAsset, TextLegacy } from '@/components/common'
import { ListInfiniteScroll } from '@/components/common/list'
import { ListWritableReviewEmpty } from '@/components/domains/list/empty'
import { ListItemReviewWritableOrderItem } from '@/components/domains/listItems'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { currencyText } from '@/utils/utilNumber'

type ListReviewWritableOrderItemsProps = {}

const ListReviewWritableOrderItems: FC<ListReviewWritableOrderItemsProps> = () => {
  const [showTopBar, toggleShowTabBar] = useState<boolean>(false)
  const tracker = useTracker()

  const queryParams: QueryParamsProps = {
    gqlKey: 'reviewWritableOrderItems',
    variables: {
      filter: {
        orderBy: 'confirmedAt',
      },
    },
  }

  return (
    <>
      {showTopBar && <RenderTopBar />}
      <ListInfiniteScroll
        noCache
        defaultPageSize={PageSizeEnum.PageSize30}
        grid={1}
        queryParams={queryParams}
        renderItem={(item: IModelOrderItem) => <ListItemReviewWritableOrderItem key={item.id} orderItem={item} />}
        setListTotalCount={(totalCount) => {
          if (totalCount <= 0) {
            tracker.triggerCustomEvent(FitpetMallEventEnum.ViewReviewlist, {
              mileageExpectedSum: 0,
            })
          }
          toggleShowTabBar(totalCount > 0)
        }}
        emptyText={<ListWritableReviewEmpty />}
      />
    </>
  )
}

const RenderTopBar = () => {
  const tracker = useTracker()
  return (
    <ApiQuery
      queryParams={{
        gqlKey: 'reviewPromotionInfo',
      }}
      noCache
    >
      {({ data }) => {
        let writableReviewCount: number = 0
        let willRewardPoint: number = 0

        if (data) {
          const reviewInfo = JSON.parse(data.reviewPromotionInfo.data)
          writableReviewCount = reviewInfo.writableReviewCount
          willRewardPoint = reviewInfo.willRewardPoint
          tracker.triggerCustomEvent(FitpetMallEventEnum.ViewReviewlist, {
            mileageExpectedSum: willRewardPoint,
          })
        }

        return (
          <TopBar align="middle">
            <Col style={{ marginRight: 6 }}>
              <ImageAsset name="ico_write_review" size={24} />
            </Col>
            <Col>
              <TextLegacy weight="bold">
                {`${writableReviewCount}개 리뷰 작성하고 `}
                <TextLegacy weight="bold" color="blue500">
                  {`최대 ${currencyText(willRewardPoint)}`}
                </TextLegacy>
                {` 받으세요!`}
              </TextLegacy>
            </Col>
          </TopBar>
        )
      }}
    </ApiQuery>
  )
}

const TopBar = styled(Row)`
  background-color: ${(props) => props.theme.color.blue30};
  padding: 20px;
`
export default ListReviewWritableOrderItems
