import { FC, useEffect } from 'react'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Modal, TextLegacy } from '@/components/common'
import { AccountLastLoginInfo } from '@/components/domains/etc'
import ROUTES from '@/constants/legacy/constRoutes'
import { SignupLinkAccountType, SocialServiceUserProfileType } from '@/constants/legacy/constType'
import { useSignupEventTracker } from '@/containers/event-tracker/signup.event-tracker'
import { useAccountAuth, useCustomRouter, useModal } from '@/containers/hooks'
import { SocialServiceSocialType } from '@/graphql/generated/schemas'
import { doSetSignupLinkAccount } from '@/stores/reduxData'
import { RootState } from '@/stores/store'
import { deepcopy } from '@/utils/utilData'
import { localTempSocialUserProfile } from '@/utils/utilLocalStorage'

type ModalAccountExistedProps = {
  socialType: string
}

const ModalAccountExisted: FC<ModalAccountExistedProps> = ({ socialType }) => {
  const { useModalProps } = useModal()
  const dispatch = useDispatch()
  const { push } = useCustomRouter()
  const signupLinkAccount: SignupLinkAccountType | undefined = useSelector(
    (rootState: RootState) => rootState.reduxDataReducers.signupLinkAccount
  )

  const { useSocialAuth } = useAccountAuth
  const { linkEmailAccount, linkSocialAccount } = useSocialAuth()
  const { trackerViewSignupInfoPopUpEvent, trackerClickSignupInfoPopUpEvent } = useSignupEventTracker()

  const userSocialProfile: SocialServiceUserProfileType = localTempSocialUserProfile.load()
  const isEmailSignup = !userSocialProfile
  const modalText = isEmailSignup
    ? `이미 가입된 계정입니다.\n계정을 확인하고 로그인해 주세요.`
    : `이미 가입된 계정입니다.\n기존 가입 계정과 연동하시겠어요?`

  useEffect(() => {
    if (!signupLinkAccount) {
      return
    }
    useModalProps.showModal()
  }, [signupLinkAccount])

  const onOk = () => {
    trackerClickSignupInfoPopUpEvent({
      signupType: socialType,
      popupText: modalText,
      buttonText: isEmailSignup ? '로그인' : '계정 연동',
    })

    if (isEmailSignup) {
      dispatch(doSetSignupLinkAccount(undefined))
      push(ROUTES.ACCOUNTS.LOGIN)
      return
    }

    const { type, emailAccountInfo, socialUserProfileInfo, userCertificationId, existedUserInfo } = deepcopy(
      signupLinkAccount
    ) as SignupLinkAccountType

    dispatch(doSetSignupLinkAccount(undefined))
    window.scrollTo({ top: 0, behavior: 'smooth' })

    if (type === SocialServiceSocialType.Email) {
      linkEmailAccount({
        existedUserId: existedUserInfo.id || '',
        userCertificationId,
        ...emailAccountInfo!,
      })
    } else {
      linkSocialAccount(socialUserProfileInfo!, existedUserInfo.id, userCertificationId)
    }
  }

  const onCancel = () => {
    trackerClickSignupInfoPopUpEvent({
      signupType: socialType,
      popupText: modalText,
      buttonText: '닫기',
    })
    dispatch(doSetSignupLinkAccount(undefined))
  }

  useEffect(() => {
    if (!useModalProps.visible) return
    trackerViewSignupInfoPopUpEvent({
      signupType: socialType,
      popupText: modalText,
    })
  }, [useModalProps.visible])

  return (
    <Modal
      useModalProps={useModalProps}
      buttonType="ACTION_CANCEL"
      okText={isEmailSignup ? '로그인' : '계정 연동'}
      cancelText="닫기"
      onOk={onOk}
      onCancel={onCancel}
      width={320}
    >
      <ModalBody>
        <Row justify="center">
          <TextCol>
            <TextLegacy size={{ xs: 16, md: 18 }} weight="bold">
              {modalText}
            </TextLegacy>
          </TextCol>
        </Row>

        <AccountLastLoginInfo userInfo={signupLinkAccount?.existedUserInfo} />
      </ModalBody>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 20px 0px;
`

const TextCol = styled(Col)`
  text-align: center;
`

export default ModalAccountExisted
