import { FC } from 'react'
import { useDispatch } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { Text } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { LocationEnum } from '@/constants/location.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter } from '@/containers/hooks'
import useEventsListContainer from '@/containers/hooks/events-list.container.hook'
import { AdType } from '@/graphql/generated/schemas'
import { doSetHeaderTitle } from '@/stores/reduxUI'
import { decodeId } from '@/utils/utilApi'
import { formatDate } from '@/utils/utilDate'
import { getImageUrl } from '@/utils/utilURL'

const ListEvents: FC = () => {
  const { triggerCustomEvent } = useTracker()
  const dispatch = useDispatch()
  const { basePath, asPath } = useCustomRouter()
  const { color } = useTheme()

  const { eventList, elementRef } = useEventsListContainer()

  const currentPath = basePath + asPath

  const trackingEvent = (ad: AdType, index: number) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickEventsalepageListItem, {
      eventsaleSlug: ad.slug,
      eventsaleId: decodeId(ad.id).id,
      eventsaleName: ad.name,
      bannerIndex: index,
    })
    dispatch(doSetHeaderTitle(ad.name))
  }

  return (
    <>
      {eventList.map((ad, index) => (
        <div key={ad.id} ref={index === eventList.length - 1 ? elementRef : undefined} data-cy="events-item">
          <LinkButton
            url={{
              pathname: ROUTES.EVENT_DETAIL,
              query: { adSlug: ad.slug, location: LocationEnum.EventList, [QueryStringKeyEnum.PrevPath]: currentPath },
            }}
            onClick={() => trackingEvent(ad, index)}
          >
            <Image src={getImageUrl(ad.mainImage?.image)} alt={`${ad.slug} 기획전 배너 이미지`} />
            <StyledEndDate>
              {ad.endedAt && <Text.Body5 fontColor={color.gray500}>종료일 : {formatDate(ad.endedAt)}</Text.Body5>}
            </StyledEndDate>
          </LinkButton>
        </div>
      ))}
    </>
  )
}

const Image = styled.img`
  width: 100%;
  object-fit: contain;
`

const StyledEndDate = styled.div`
  padding: 0.4rem 1.6rem 1.2rem;
  min-height: 3.3rem;
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: ${({ theme: { color } }) => color.grayWhite};
`

export default ListEvents
