import { FC } from 'react'
import { Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Image } from '@/components/common'
import { ButtonCircleClose, LinkButton } from '@/components/common/buttons'
import { Z_INDEXES, LAYOUT_UNITS } from '@/constants/legacy/constLayout'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { doSetHomePopupBanners } from '@/stores/reduxData'
import { RootState } from '@/stores/store'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { enableScroll } from '@/utils/utilCommon'

const ListHomeEventsPopup: FC = () => {
  const banners = useSelector((state: RootState) => state.reduxDataReducers.homeTopRollBanners)
  const tracker = useTracker()
  const dispatch = useDispatch()
  if (!banners) {
    return null
  }

  const close = () => {
    dispatch(doSetHomePopupBanners(undefined))
    enableScroll(true)
  }

  return (
    <StyledContainer justify="center">
      <ListContainer justify="center">
        {banners.map((banner) => {
          // @ts-ignore
          const { link, image } = banner.bannerInfo.mobileImage
          // TODO typescript 오류 수정 필요
          // @ts-ignore
          const { _id: eventsaleId, name: eventsaleName, adSlug: eventsaleSlug } = banner
          return (
            <ImageContainer key={banner._id}>
              <LinkButton
                url={link}
                onClick={() => {
                  tracker.triggerCustomEvent(FitpetMallEventEnum.ClickEventsaleListItem, {
                    eventsaleId,
                    eventsaleName,
                    eventsaleSlug,
                  })
                  close()
                }}
              >
                <Image src={image} alt="" layout="responsive" width={460} height={310} />
              </LinkButton>
            </ImageContainer>
          )
        })}
      </ListContainer>
      <CloseButtonContainer justify="center">
        <ButtonCircleClose onClick={close} />
      </CloseButtonContainer>
    </StyledContainer>
  )
}

const StyledContainer = styled(Row)`
  position: fixed;
  top: 0px;
  z-index: ${Z_INDEXES.HOME_EVENT_POPUP};
  height: 100%;
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  background-color: rgba(82, 82, 82, 0.7);
  overflow: scroll;
  padding: 40px 0px;
`

const ListContainer = styled(Row)`
  width: 90%;
  max-width: 500px;
`

const ImageContainer = styled.div`
  width: 100%;
`

const CloseButtonContainer = styled(Row)`
  position: fixed;
  bottom: 30px;
`

export default ListHomeEventsPopup
