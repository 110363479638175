export enum IconNameEnum {
  IcHeartOutlined = 'ic_heart_outlined',
  IcHeartFilled = 'ic_heart_filled',
  IcHeartDimmedThumbnail = 'ic_heart_dimmed_thumbnail',
  IcChange = 'ic_change',
  IcSearch = 'ic_search',
  IcSetting = 'ic_setting',
  IcTabShoppingOutlined = 'ic_tab_shopping_outlined',
  IcTabShoppingFilled = 'ic_tab_shopping_filled',
  IcTabMyOutlined = 'ic_tab_my_outlined',
  IcTabMyFilled = 'ic_tab_my_filled',
  IcCart = 'ic_cart',
  IcAdd = 'ic_add',
  IcAddSmall = 'ic_add_small',
  IcArrowRight = 'ic_arrow_right',
  IcArrowUpSmall = 'ic_arrow_up_12',
  IcArrowDownSmall = 'ic_arrow_down_12',
  IcArrowLeft = 'ic_arrow_left',
  IcArrowRightThin = 'ic_arrow_right_thin',
  IcArrowUp = 'ic_arrow_up',
  IcArrowDown = 'ic_arrow_down',
  IcCaution = 'ic_caution',
  IcChevronDown = 'ic_chevron_down',
  IcChevronRight = 'ic_chevron_right',
  IcRecently = 'ic_recently',
  IcClose = 'ic_close',
  IcHome = 'ic_home',
  IcBack = 'ic_back',
  IcShare = 'ic_share',
  IcStar = 'ic_star',
  IcTip = 'ic_tip',
  IcInfo = 'ic_info',
  IcInfoFilled = 'ic_info_filled',
  IcTop = 'ic_top',
  IcBottom = 'ic_bottom',
  IcSearchDelete = 'ic_search_delete',
  IcCheck = 'ic_check',
  IcTabCareOutlined = 'ic_tab_care_outlined',
  IcTabCareFilled = 'ic_tab_care_filled',
  IcTabHospitalOutlined = 'ic_tab_hospital_outlined',
  IcTabHospitalFilled = 'ic_tab_hospital_filled',
  IcTabHealthOutlined = 'ic_tab_health_outlined',
  IcTabHealthFilled = 'ic_tab_health_filled',
  IcRankingUp = 'ic_ranking_up',
  IcRankingDown = 'ic_ranking_down',
  IcRankingNone = 'ic_ranking_none',
  IcEventFlat = 'ic_event_flat',
  IcDownload = 'ic_download',
  IcDownloadRadius = 'ic_download_radius',
  IcOrderCoupon = 'ic_order_coupon',
  IcOwner = 'ic_owner',
  IcCoinFlat = 'ic_coin_flat',
  IcEmpty = 'ic_empty',
}
