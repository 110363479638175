import { FC } from 'react'
import styled from 'styled-components'
import { ImageAsset, TextLegacy } from '@/components/common'

const ListRecentlyEmpty: FC = () => {
  return (
    <StyledContainer>
      <StyledContent>
        <ImageWrapper>
          <ImageAsset name="recently_list_empty" size={80} svg />
        </ImageWrapper>
        <TextWrapper>
          <TextLegacy>최근 본 상품이 없습니다.</TextLegacy>
        </TextWrapper>
      </StyledContent>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`

export default ListRecentlyEmpty
