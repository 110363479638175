import { FC } from 'react'
import { Row } from 'antd'
import { useDispatch } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { ImageAsset, Text } from '@/components/common'
import { Button } from '@/components/common/buttons'
import { MoreButtonType } from '@/components/domains/modals/ModalMoreOrdersButtons'
import ROUTES from '@/constants/legacy/constRoutes'
import { OrderCancelTypeEnum } from '@/constants/order-cancel-type.enum'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { OrderButtonText } from '@/constants/order-type.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, useModal, useOrderConfirm } from '@/containers/hooks'
import { OrderButtonsTrackInfoType } from '@/containers/hooks/order/useOrderConfirm'
import { UseModalProps } from '@/containers/hooks/useModal'
import { doSetBeforeHistoryLength } from '@/stores/reduxUI'
import { convertGoReviewFromOrdersParams } from '@/utils/event-tracker/convertParams/convertProductParams'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { enableScroll } from '@/utils/utilCommon'
import { currencyText } from '@/utils/utilNumber'

type OrderItemActionButtonsProps = {
  orderId: string
  orderButtonsTrackInfo: OrderButtonsTrackInfoType
  orderItemId: string
  orderItemStatus: OrderStatusTypeEnum
  canWriteReview: boolean
  orderConfirmMileage: number
  // eslint-disable-next-line react/no-unused-prop-types
  confirmCancelAll: () => void
  handleShippingTrack: () => void
  useSelectMoreButtonsModalProps?: UseModalProps<MoreButtonType[]>
  refreshConfirmButtonClick?: () => void
  isPurchaseMileage?: boolean
}

const OrderItemActionButtons: FC<OrderItemActionButtonsProps> = ({
  orderId,
  orderItemId,
  orderButtonsTrackInfo,
  orderItemStatus,
  canWriteReview,
  orderConfirmMileage,
  handleShippingTrack,
  useSelectMoreButtonsModalProps,
  refreshConfirmButtonClick,
  isPurchaseMileage,
}) => {
  const { color, fontWeight, spacing } = useTheme()
  const { push } = useCustomRouter()
  const { confirmConfirmOrderItem } = useOrderConfirm({ refreshConfirmButtonClick })
  const { useModalProps } = useModal()
  const tracker = useTracker()
  const dispatch = useDispatch()

  const onCancel = (orderCancelType: OrderCancelTypeEnum) => {
    useModalProps.hideModal()
    window.history.pushState(undefined, '', window.location.href)
    dispatch(doSetBeforeHistoryLength(window.history.length - 1))
    push({
      pathname: ROUTES.MYPAGE.ORDER_CANCEL.BASE,
      query: {
        cancelType: orderCancelType,
        orderId,
        orderItemId,
      },
    })
  }

  const openDetail = () => {
    push({
      pathname: ROUTES.MYPAGE.ORDER_DETAIL,
      query: {
        orderId,
        orderItemId,
        status: orderItemStatus,
      },
    })
  }

  const goReview = () => {
    tracker.triggerCustomEvent(
      FitpetMallEventEnum.ClickOrderlistReviewButton,
      convertGoReviewFromOrdersParams(orderButtonsTrackInfo)
    )
    push({
      pathname: ROUTES.MYPAGE.REVIEWS.WRITE,
      query: {
        orderItemId,
        prePath: ROUTES.MYPAGE.ORDERS,
      },
    })
  }

  const moreButtons: {
    [index: string]: MoreButtonType
  } = {
    shippingTrack: {
      name: OrderButtonText.ShippingTracking,
      handleClick: handleShippingTrack,
    },
    requestReturn: {
      name: '반품신청',
      handleClick: () => onCancel(OrderCancelTypeEnum.Return),
    },
    requestExchange: {
      name: '교환신청',
      handleClick: () => {
        enableScroll(true)
        onCancel(OrderCancelTypeEnum.Exchange)
      },
    },
  }

  const getMoreButtons = (orderStatus: OrderStatusTypeEnum) => {
    switch (orderStatus) {
      case OrderStatusTypeEnum.Shipped:
        return [moreButtons.shippingTrack, moreButtons.requestReturn, moreButtons.requestExchange]
      case OrderStatusTypeEnum.Shipping:
        return [moreButtons.requestReturn, moreButtons.requestExchange]
      case OrderStatusTypeEnum.Confirmed:
        return [moreButtons.shippingTrack]
      default:
        return []
    }
  }

  const handleMoreButtonClick = (orderStatus: OrderStatusTypeEnum) => {
    const buttons = getMoreButtons(orderStatus)
    if (buttons.length < 1) {
      return
    }
    useSelectMoreButtonsModalProps?.setData(buttons)
    useSelectMoreButtonsModalProps?.showModal()
  }

  const showRequestCancel = () => {
    switch (orderItemStatus) {
      case OrderStatusTypeEnum.UnconfirmedPayment:
      case OrderStatusTypeEnum.NewOrder:
        return true
      default:
        return false
    }
  }

  const showCancelDisabled = () => {
    return OrderStatusTypeEnum.ReadyForShipping === orderItemStatus
  }

  const showTrackShipping = () => {
    if (OrderStatusTypeEnum.Shipping === orderItemStatus) {
      return true
    }
    return OrderStatusTypeEnum.Confirmed === orderItemStatus && !canWriteReview
  }

  const showCancelInfo = () => {
    switch (orderItemStatus) {
      case OrderStatusTypeEnum.CancelRequested:
      case OrderStatusTypeEnum.Canceled:
        return true
      default:
        return false
    }
  }

  const showReturnInfo = () => {
    switch (orderItemStatus) {
      case OrderStatusTypeEnum.ReturnRequested:
      case OrderStatusTypeEnum.ReturnUnderReturn:
      case OrderStatusTypeEnum.ReturnConfirmed:
      case OrderStatusTypeEnum.ReturnReturned:
        return true
    }
    return false
  }

  const showExchangeInfo = () => {
    switch (orderItemStatus) {
      case OrderStatusTypeEnum.ExchangeConfirmed:
      case OrderStatusTypeEnum.ExchangeRequested:
      case OrderStatusTypeEnum.ExchangeUnderReturn:
        return true
    }
    return false
  }

  const showConfirmReviewMore = () => {
    return orderItemStatus === OrderStatusTypeEnum.Shipped
  }

  const showReviewMore = () => {
    return orderItemStatus === OrderStatusTypeEnum.Confirmed && canWriteReview
  }

  const OrderCancelButton = () => {
    return (
      <Button.GrayFill
        onClick={() => onCancel(OrderCancelTypeEnum.Cancel)}
        width={spacing.per100}
        height={spacing.size46}
      >
        <Text.Body3 fontWeight={fontWeight.medium} fontColor={color.gray700}>
          주문 취소
        </Text.Body3>
      </Button.GrayFill>
    )
  }

  const DisableOrderCancelButton = () => {
    return (
      <Button.GrayFill disabled={true} onClick={() => {}} width={spacing.per100} height={spacing.size46}>
        <Text.Body3 fontWeight={fontWeight.medium} fontColor={color.gray200}>
          주문 취소
        </Text.Body3>
      </Button.GrayFill>
    )
  }

  const InquiryShippingButton = () => {
    return (
      <Button.Tertiary onClick={() => handleShippingTrack()} width={spacing.per100} height={spacing.size46}>
        <Text.Body3 fontWeight={fontWeight.medium} fontColor={color.gray900}>
          {OrderButtonText.ShippingTracking}
        </Text.Body3>
      </Button.Tertiary>
    )
  }

  const MoreButton = () => {
    return (
      <StyledMoreButtonBox>
        <Button.Tertiary
          onClick={() => {
            handleMoreButtonClick(orderItemStatus)
          }}
          width={spacing.size46}
          height={spacing.size46}
        >
          <ImageAsset name="ic_more_gray900_24" svg size={24} />
        </Button.Tertiary>
      </StyledMoreButtonBox>
    )
  }

  const CancelInfoButton = () => {
    return (
      <Button.Tertiary onClick={openDetail} width={spacing.per100} height={spacing.size46}>
        <Text.Body3 fontWeight={fontWeight.medium} fontColor={color.gray900}>
          취소정보
        </Text.Body3>
      </Button.Tertiary>
    )
  }

  const ExchangeInfoButton = () => {
    return (
      <Button.Tertiary onClick={openDetail} width={spacing.per100} height={spacing.size46}>
        <Text.Body3 fontWeight={fontWeight.medium} fontColor={color.gray900}>
          교환정보
        </Text.Body3>
      </Button.Tertiary>
    )
  }

  const ReturnInfoButton = () => {
    return (
      <Button.Tertiary onClick={openDetail} width={spacing.per100} height={spacing.size46}>
        <Text.Body3 fontWeight={fontWeight.medium} fontColor={color.gray900}>
          반품정보
        </Text.Body3>
      </Button.Tertiary>
    )
  }

  const ConfirmButton = () => {
    return (
      <StyledConfirmButtonBox>
        <Button.Tertiary
          onClick={() =>
            confirmConfirmOrderItem(orderItemId, orderConfirmMileage, orderButtonsTrackInfo, isPurchaseMileage || false)
          }
          width={spacing.per100}
          height={spacing.size46}
        >
          <Text.Body3 fontWeight={fontWeight.medium} fontColor={color.gray900}>
            구매확정
          </Text.Body3>
        </Button.Tertiary>
        {orderConfirmMileage > 0 && (
          <ConfirmButtonTip>
            <Text.Body5 fontWeight={fontWeight.medium} fontColor={color.grayWhite}>
              {currencyText(orderConfirmMileage)} 적립
            </Text.Body5>
          </ConfirmButtonTip>
        )}
      </StyledConfirmButtonBox>
    )
  }

  const ReviewButton = () => {
    return (
      <Button.Secondary onClick={() => goReview()} width={spacing.per100} height={spacing.size46}>
        <Text.Body3 fontColor={color.blue500} fontWeight={fontWeight.bold}>
          리뷰쓰기
        </Text.Body3>
      </Button.Secondary>
    )
  }

  return (
    <>
      <StyledWrapper>
        {showRequestCancel() && <OrderCancelButton />}
        {showCancelDisabled() && <DisableOrderCancelButton />}
        {showTrackShipping() && (
          <StyledMoreButtonSection>
            <InquiryShippingButton />
            {orderItemStatus === OrderStatusTypeEnum.Shipping && <MoreButton />}
          </StyledMoreButtonSection>
        )}
        {showCancelInfo() && <CancelInfoButton />}
        {showExchangeInfo() && <ExchangeInfoButton />}
        {showReturnInfo() && <ReturnInfoButton />}
        {showConfirmReviewMore() && (
          <StyledMoreButtonSection>
            <ConfirmButton />
            <ReviewButton />
            <MoreButton />
          </StyledMoreButtonSection>
        )}
        {showReviewMore() && (
          <StyledMoreButtonSection>
            <ReviewButton />
            <MoreButton />
          </StyledMoreButtonSection>
        )}
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled(Row)`
  margin-top: 1.2rem;
`

const StyledMoreButtonSection = styled.div`
  display: flex;
  width: 100%;
  gap: 0.7rem;
`

const StyledConfirmButtonBox = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`

const ConfirmButtonTip = styled.div`
  position: absolute;
  top: -1.6rem;
  left: 0;
  padding: 0.4rem 0.8rem;
  text-align: center;
  border-radius: 1rem 1rem 0.2rem 1rem;
  background: ${({ theme: { color } }) => color.gray800};
`

const StyledMoreButtonBox = styled.div`
  min-width: 4.6rem;
`

export default OrderItemActionButtons
