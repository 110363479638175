import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'

const partnersEventProperties = {
  eventsale_slug: 'partners',
  eventsale_name: '핏펫 파트너스',
}

const usePartnersPageEventTracker = () => {
  const tracker = useTracker()

  const trackViewEventSalePartnersEvent = ({ isLogin }: { isLogin: boolean }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewEventSalePartners, {
      is_login: isLogin,
      ...partnersEventProperties,
    })
  }

  const trackClickEventSalePartnersRegisterEvent = ({
    isLogin,
    submitText,
  }: {
    isLogin: boolean
    submitText: string
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickEventSalePartnersRegister, {
      is_login: isLogin,
      button_text: submitText,
      ...partnersEventProperties,
    })
  }

  const trackClickEventSalePartnersShareEvent = ({ isLogin }: { isLogin: boolean }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickEventSalePartnersShare, {
      is_login: isLogin,
      ...partnersEventProperties,
    })
  }

  const trackClickPartnersRegisterRegisterEvent = ({ isIndividual }: { isIndividual: boolean }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickPartnersRegisterRegister, {
      is_individual: isIndividual,
    })
  }

  const trackViewPartnersDetailEvent = () => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewPartnersDetail)
  }

  const trackClickPartnersDetailMakeCouponEvent = ({ couponKeyword }: { couponKeyword: string }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickPartnersDetailMakeCoupon, {
      coupon_keyword: couponKeyword,
    })
  }

  const trackViewPartnersDetailMyRewardEvent = () => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewPartnersDetailMyReward)
  }

  const trackClickMypagePartnersEvent = ({ buttonText }: { buttonText: string }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickMypagePartners, {
      button_text: buttonText,
    })
  }

  return {
    trackViewEventSalePartnersEvent,
    trackClickEventSalePartnersRegisterEvent,
    trackClickEventSalePartnersShareEvent,
    trackClickPartnersRegisterRegisterEvent,
    trackViewPartnersDetailEvent,
    trackClickPartnersDetailMakeCouponEvent,
    trackViewPartnersDetailMyRewardEvent,
    trackClickMypagePartnersEvent,
  }
}

export { usePartnersPageEventTracker }
