/*
 * A/B 테스트 정책
 * @link https://www.notion.so/industrious-ethernet-850/A-B-f492875f56314f108c541b9d32402ccd
 */
import { ABFlagEnum } from '@/constants/ab-flag.enum'
import { LocalABFlagEnum } from '@/constants/local-abflag.enum'
import { KEY } from '@/utils/utilLocalStorage'
import { getHalfProbability } from '@/utils/utilNumber'

const isServer = typeof localStorage === 'undefined'

// TODO: Array<{key: string, value: 'A' | 'B'}> 구조로 변경필요
const utilABTest = () => {
  const getLocalStorageAB = () => localStorage.getItem(KEY.AB_TEST_FLAG)
  const setLocalStorageAB = (value: string) => localStorage.setItem(KEY.AB_TEST_FLAG, value)
  const getParsedLocalStorageAB = () => {
    try {
      const localStorageAB = JSON.parse(getLocalStorageAB() ?? '{}')
      return !localStorageAB || Array.isArray(localStorageAB) || typeof localStorageAB !== 'object'
        ? {}
        : localStorageAB
    } catch (e) {
      return {}
    }
  }

  // 로컬 스토리지 초기값 설정
  const initAB = () => {
    if (isServer) return
    if (!getLocalStorageAB()) setLocalStorageAB('{}') // 없으면 빈 객체를 넣어준다.

    // 로컬 스토리지에 저장된 값이 없는 경우에만 새로운 값을 생성
    Object.values(LocalABFlagEnum).forEach((name) => {
      if (!getAB(name)) setAB(name)
    })
  }

  // 로컬 스토리지 AB 삭제
  const clearAB = () => {
    if (isServer) return
    setLocalStorageAB('{}')
  }

  // 로컬 스토리지에서 AB 값 가져오기
  const getAB = (name: LocalABFlagEnum): ABFlagEnum | undefined => {
    if (isServer) return

    return getParsedLocalStorageAB()[name]
  }

  // 이벤트 트래커에 AB 값을 넘겨주기 위해서 사용
  const getABTestGroup = () => {
    if (isServer) return

    // key가 LocalABFlagEnum에 있는 값만 추출
    return Object.entries(getParsedLocalStorageAB())
      .filter(([key]) => Object.values(LocalABFlagEnum).includes(key as LocalABFlagEnum))
      .map(([key, value]) => `${key}_${(value as string).toLowerCase()}`)
  }

  // 로컬 스토리에 저장
  const setAB = (name: LocalABFlagEnum, type?: ABFlagEnum) => {
    if (isServer) return

    return setLocalStorageAB(
      JSON.stringify({
        ...getParsedLocalStorageAB(),
        [name]: type ?? (getHalfProbability() ? ABFlagEnum.A : ABFlagEnum.B),
      })
    )
  }

  // 테스트 케이스를 같은 값으로 세팅
  const setAllAB = (type: ABFlagEnum) => {
    if (isServer) return

    Object.values(LocalABFlagEnum).forEach((name) => {
      setAB(name, type)
    })
  }

  const hasAFlag = (name: LocalABFlagEnum) => getAB(name) === ABFlagEnum.A
  const hasBFlag = (name: LocalABFlagEnum) => getAB(name) === ABFlagEnum.B

  return {
    initAB,
    hasAFlag,
    hasBFlag,
    setAllAB,
    getAB,
    getABTestGroup,
    clearAB,
  }
}

export default utilABTest
