import { useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { ScrollContainer } from '@/components/domains/etc'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import {
  PRODUCT_CARD_SIZES,
  THUMBNAIL_STATES,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { ProductLocation } from '@/constants/product-location.const'
import { useBottomSheetEventTracker } from '@/containers/event-tracker/bottom-sheet.event.tracker'
import { useCustomRouter } from '@/containers/hooks'
import { useCartBottomSheetRestReduxData } from '@/containers/hooks/cart/useCartBottomSheetRestReduxData'
import { GraphQLProductType, transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar } from '@/utils/utilCSS'

type RecentlyProductListProps = {
  data: GraphQLProductType[]
  setStorageSync: () => void
}

const RecentlyProductListTitle = '최근 본 상품'

export const RecentlyProductListInBottomSheet = ({ data, setStorageSync }: RecentlyProductListProps) => {
  const { push, pathname, reload } = useCustomRouter()
  const { color } = useTheme()
  const { resetCartBottomSheetReduxData } = useCartBottomSheetRestReduxData()

  const { trackClickAddCartBottomUpProductEvent } = useBottomSheetEventTracker()

  const handleMoreClick = () => {
    resetCartBottomSheetReduxData()
    if (pathname.startsWith(ROUTES.MYPAGE.RECENTLY_LIST)) {
      reload()
    } else {
      push(ROUTES.MYPAGE.RECENTLY_LIST)
    }
  }

  const handleClickProductCardNew = ({ sectionName }: { sectionName: string }) => {
    trackClickAddCartBottomUpProductEvent({ sectionName })
  }

  useEffect(() => {
    setStorageSync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!data.length) {
    return null
  }

  return (
    <RecentlyProductListWrapper className="recently-product-list">
      <div className="recently-product-list__header">
        <Typo variant={TypoVariant.Body1Bold} color={color.gray900}>
          {RecentlyProductListTitle}
        </Typo>
        <button type="button" className="recently-product-list__link" onClick={handleMoreClick}>
          <Typo variant={TypoVariant.Body3Regular} color={color.gray500}>
            더보기
          </Typo>
          <FlatIcon type={IconNameEnum.IcArrowRight} color={color.gray500} size={IconSizeEnum.Size16} />
        </button>
      </div>
      <StyledContainer>
        <ScrollContainer hideRightButton={data.length < 3} buttonPositionY={30}>
          <StyledListContainer>
            {data.map((product, index) => {
              const productData = transformProductTypeDefinedByFrontend(product as unknown as GraphQLProductType)
              return (
                <StyledProductCardContainer key={product.id}>
                  <NewProductCard
                    product={productData}
                    size={PRODUCT_CARD_SIZES.lg}
                    thumbnailState={THUMBNAIL_STATES.basicLabel}
                    rankingNumber={index}
                    hasCartButton
                    trackerData={{
                      addLocation: ProductLocation.BottomSheet,
                      sectionName: RecentlyProductListTitle,
                      listIndex: index,
                    }}
                    onClickProductCard={() => handleClickProductCardNew({ sectionName: RecentlyProductListTitle })}
                  />
                </StyledProductCardContainer>
              )
            })}
          </StyledListContainer>
        </ScrollContainer>
      </StyledContainer>
    </RecentlyProductListWrapper>
  )
}

const RecentlyProductListWrapper = styled.div`
  .recently-product-list {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.6rem;
    }
    &__link {
      border: none;
      padding: 0;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
`

const StyledContainer = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.color.grayWhite};
`

const StyledListContainer = styled.div`
  ${removeScrollbar};
  display: flex;
  gap: 0.8rem;
  margin-bottom: 2.4rem;

  & > *:first-child {
    margin-left: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }
`

const StyledProductCardContainer = styled.div`
  position: relative;
`
