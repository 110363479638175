import { FC, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { ImageAsset, TextLegacy } from '@/components/common'
import { ModalSlider } from '@/components/common/modals'
import { ModalBaseProps } from '@/components/common/modals/ModalBase'
import { GlobalColorEnum } from '@/constants/global-color.enum'

type Props<T> = {
  handleClick: (selected: string) => void
  selectedPeriod: string
} & ModalBaseProps<T>

const ModalSelectInquiryPeriod: FC<Props<any>> = ({ useModalProps, handleClick, selectedPeriod }) => {
  const { hideModal } = useModalProps
  const periodLists = ['최근 6개월', '최근 1년', '전체']
  const [selectPeriod, setSelectPeriod] = useState<string>(selectedPeriod)

  return (
    <ModalSlider title="조회 기간 선택" centerTitle titleNoBorder useModalProps={useModalProps}>
      <StyledWrapper>
        {periodLists.map((period, index) => {
          return (
            <StyledButtonSection
              key={period}
              onClick={() => {
                handleClick(period)
                setSelectPeriod(period)
                hideModal()
              }}
            >
              {period === selectPeriod ? <SelectedButton period={period} /> : <NotSelectedButton period={period} />}
            </StyledButtonSection>
          )
        })}
      </StyledWrapper>
    </ModalSlider>
  )
}

const SelectedButton: FC<{ period: string }> = ({ period }) => {
  return (
    <StyledSelectedBox>
      <TextLegacy size={15} weight={600} color={GlobalColorEnum.Blue500} customStyle={'margin-right: auto;'}>
        {period}
      </TextLegacy>
      <ImageAsset name={'ic_check_16'} size={24} svg style={{ display: 'block', marginLeft: 'auto' }} />
    </StyledSelectedBox>
  )
}

const NotSelectedButton: FC<{ period: string }> = ({ period }) => {
  return (
    <TextLegacy size={15} weight={400}>
      {period}
    </TextLegacy>
  )
}

const StyledWrapper = styled.div`
  display: grid;
  gap: 8px;
  background-color: ${(props) => props.theme.color.grayWhite};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - 60px);
  ::-webkit-scrollbar {
    display: block;
  }
  padding: 16px;
`

const StyledButtonSection = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  span {
    :active {
      color: ${(props) => props.theme.color.blue500};
    }
  }
`

const StyledSelectedBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export default ModalSelectInquiryPeriod
