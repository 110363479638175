import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useCustomRouter } from '@/containers/hooks/index'
import { LikeProductType } from '@/containers/hooks/useLikeProduct'
import { doInitLikeSync } from '@/stores/reduxData'
import { AppStorageDataType, Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider from '@/utils/utilBridge'
import { isInApp } from '@/utils/utilCommon'

const convertToKeyObjectByProductId = (data: LikeProductType[]): { [key: string]: LikeProductType } => {
  return data.reduce((acc, item) => {
    acc[item.productId] = item
    return acc
  }, {} as { [key: string]: LikeProductType })
}

export const useGetLikeFromApp = () => {
  const { pathname } = useCustomRouter()

  const isHome = pathname.startsWith('/home') || pathname === '/'
  const dispatch = useDispatch()

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        appBridgeProvider(async (bridge: Bridge) => {
          bridge.storageGetItem('likeProductList').then((data: AppStorageDataType<LikeProductType[]>) => {
            dispatch(doInitLikeSync(convertToKeyObjectByProductId(data.data)))
          })
        })
      }
    }

    if (isHome && isInApp()) {
      document.addEventListener('visibilitychange', handleVisibilityChange)
    }
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])
  return {}
}
