import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'

const useActionPowerEventTracker = () => {
  const { triggerCustomEvent } = useTracker()

  const trackClickOrderListOtherInfoButtonEvent = ({ buttonText }: { buttonText: string }) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickOrderListOtherInfoButton, {
      button_text: buttonText,
    })
  }

  return {
    trackClickOrderListOtherInfoButtonEvent,
  }
}

export { useActionPowerEventTracker }
