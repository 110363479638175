import {
  ModelBase,
  ModelAd,
  ModelAdImage,
  ModelAsInfo,
  ModelBank,
  ModelBanner,
  ModelBannerImage,
  ModelBannerInfo,
  ModelBillingItem,
  ModelBrand,
  ModelCacheProduct,
  ModelCancelReason,
  ModelCancelTransaction,
  ModelCartItem,
  ModelCartItemHistory,
  ModelCategory,
  ModelCheckTokenAssociation,
  ModelCoupon,
  ModelCouponByProduct,
  ModelCouponGroup,
  ModelCouponKakaoTemplate,
  ModelCoverflow,
  ModelCoverflowBannerInfo,
  ModelCustomerAddress,
  ModelCustomerGrade,
  ModelCustomerTemplateAddress,
  ModelDashboard,
  ModelDashboardBilling,
  ModelDashboardBoard,
  ModelDashboardNotice,
  ModelDashboardOrder,
  ModelDashboardOrderTimeseries,
  ModelDashboardProduct,
  ModelDashboardUser,
  ModelDefaultReturnChangeGuide,
  ModelDesignersBannerInfo,
  ModelDevice,
  ModelDisplayCollection,
  ModelDisplayProductInfo,
  ModelExcelExportRequest,
  ModelFaq,
  ModelFitpetLink,
  ModelGnb,
  ModelHome,
  ModelInactiveUser,
  ModelInfluencer,
  ModelInfluencerAddress,
  ModelInfluencerStatisticColumn,
  ModelInfluencerStatusCount,
  ModelInformEmailAttachment,
  ModelInformEmailQueue,
  ModelInformKakaoQueue,
  ModelInformPushQueue,
  ModelInformSmsHistory,
  ModelInformSmsQueue,
  ModelInitData,
  ModelLeaveReason,
  ModelMileage,
  ModelMileageHistory,
  ModelMileagePolicy,
  ModelMileageRewardPolicy,
  ModelMinAppVersion,
  ModelNotice,
  ModelOrder,
  ModelOrderItem,
  ModelOrderItemAdminNote,
  ModelOrderItemCancelReason,
  ModelOrderItemCount,
  ModelOrderItemReturnReason,
  ModelOrderRefundInfo,
  ModelPopularSearchTerm,
  ModelPopupBannerInfo,
  ModelProduct,
  ModelProductCategory,
  ModelProductImage,
  ModelProductOption,
  ModelProductOptionGroup,
  ModelProductOptionKind,
  ModelProductOptionSku,
  ModelProductOptionValue,
  ModelProductPromotion,
  ModelProductQna,
  ModelProductStatisticByInfluencerColumn,
  ModelProductStatisticBySellerColumn,
  ModelProductStatusCount,
  ModelPush,
  ModelPushUserHistory,
  ModelRefundInfo,
  ModelRefundShippingInfo,
  ModelRemoteArea,
  ModelRestockProductOptionUser,
  ModelReturnInfo,
  ModelReturnInfoImage,
  ModelReturnReason,
  ModelReview,
  ModelReviewGroup,
  ModelReviewImage,
  ModelSearchHistory,
  ModelSeller,
  ModelSellerAddress,
  ModelSellerBilling,
  ModelSellerStatisticColumn,
  ModelSellerStatusCount,
  ModelSellerTemplateAddress,
  ModelSellerUser,
  ModelServerConfig,
  ModelServiceQna,
  ModelServiceQnaImage,
  ModelShipping,
  ModelShippingCompany,
  ModelShippingPreset,
  ModelShippingTrack,
  ModelSku,
  ModelSkuHistory,
  ModelSlimBannerInfo,
  ModelSocialService,
  ModelStatus,
  ModelStatusHistory,
  ModelSubRollBannerInfo,
  ModelTermStatisticColumn,
  ModelTimeseriesValue,
  ModelTopRollBannerInfo,
  ModelTotalInfluencerStatistic,
  ModelTotalSalesStatistic,
  ModelTotalSellerStatistic,
  ModelTransaction,
  ModelUpdatedBrand,
  ModelUpdatedProduct,
  ModelUpdatedSeller,
  ModelUser,
  ModelUserCertification,
  ModelUserCount,
  ModelUserInfoAccount,
  ModelUserSubscription,
  ModelWeeklyBestBannerInfo,
} from '@/containers/models'

export const MODEL_TYPE = {
  AD_AD: 'AdAdType',
  AD_IMAGE_DISPLAY: 'AdImageDisplayType',
  AD_IMAGE: 'AdImageType',
  AD_PET: 'AdPetType',
  AD: 'AdType',
  AS_INFO: 'AsInfoType',
  BANK: 'BankType',
  BANNER_BANNER: 'BannerBannerType',
  BANNER_IMAGE: 'BannerImageType',
  BANNER_INFO: 'BannerInfoType',
  BANNER: 'BannerType',
  BILLING_BILLING: 'BillingBillingType',
  BILLING_ITEM_BILLING: 'BillingItemBillingType',
  BILLING_ITEM: 'BillingItemType',
  BRAND: 'BrandType',
  CACHE_PRODUCT: 'CacheProductType',
  CANCEL_REASON: 'CancelReasonType',
  CANCEL_TRANSACTION: 'CancelTransactionType',
  CART_ITEM_HISTORY: 'CartItemHistoryType',
  CART_ITEM: 'CartItemType',
  CATEGORY: 'CategoryType',
  CHECK_TOKEN_ASSOCIATION: 'CheckTokenAssociationType',
  COUPON_BY_PRODUCT: 'CouponByProductType',
  COUPON_GROUP_COUPON: 'CouponGroupCouponType',
  COUPON_GROUP_COVERAGE_OS: 'CouponGroupCoverageOsType',
  COUPON_GROUP_COVERAGE: 'CouponGroupCoverageType',
  COUPON_GROUP_DISCOUNT: 'CouponGroupDiscountType',
  COUPON_GROUP_EVENT: 'CouponGroupEventType',
  COUPON_GROUP_ISSUE: 'CouponGroupIssueType',
  COUPON_GROUP: 'CouponGroupType',
  COUPON_KAKAO_TEMPLATE: 'CouponKakaoTemplateType',
  COUPON: 'CouponType',
  COVERFLOW_BANNER_INFO: 'CoverflowBannerInfoType',
  COVERFLOW: 'CoverflowType',
  CUSTOMER_ADDRESS: 'CustomerAddressType',
  CUSTOMER_GRADE_GRADE: 'CustomerGradeGradeType',
  CUSTOMER_GRADE: 'CustomerGradeType',
  CUSTOMER_TEMPLATE_ADDRESS: 'CustomerTemplateAddressType',
  DASHBOARD_BILLING: 'DashboardBillingType',
  DASHBOARD_BOARD: 'DashboardBoardType',
  DASHBOARD_NOTICE: 'DashboardNoticeType',
  DASHBOARD_ORDER_TIMESERIES: 'DashboardOrderTimeseriesType',
  DASHBOARD_ORDER: 'DashboardOrderType',
  DASHBOARD_PRODUCT: 'DashboardProductType',
  DASHBOARD: 'DashboardType',
  DASHBOARD_USER: 'DashboardUserType',
  DEFAULT_RETURN_CHANGE_GUIDE: 'DefaultReturnChangeGuideType',
  DESIGNERS_BANNER_INFO: 'DesignersBannerInfoType',
  DEVICE_PLATFORM: 'DevicePlatformType',
  DEVICE: 'DeviceType',
  DISPLAY_COLLECTION_COLLECTION: 'DisplayCollectionCollectionType',
  DISPLAY_COLLECTION: 'DisplayCollectionType',
  DISPLAY_PRODUCT_INFO: 'DisplayProductInfoType',
  EXCEL_EXPORT_REQUEST_REQUEST_USER: 'ExcelExportRequestRequestUserType',
  EXCEL_EXPORT_REQUEST: 'ExcelExportRequestType',
  FAQ_RECEIVER: 'FaqReceiverType',
  FAQ: 'FaqType',
  FITPET_LINK: 'FitpetLinkType',
  GNB_GNB: 'GnbGnbType',
  GNB: 'GnbType',
  HOME: 'HomeType',
  INACTIVE_USER_INACTIVE: 'InactiveUserInactiveType',
  INACTIVE_USER: 'InactiveUserType',
  INFLUENCER_ADDRESS: 'InfluencerAddressType',
  INFLUENCER_INFLUENCER: 'InfluencerInfluencerType',
  INFLUENCER_STATISTIC_COLUMN: 'InfluencerStatisticColumnType',
  INFLUENCER_STATUS_COUNT: 'InfluencerStatusCountType',
  INFLUENCER: 'InfluencerType',
  INFORM_EMAIL_ATTACHMENT: 'InformEmailAttachmentType',
  INFORM_EMAIL_QUEUE: 'InformEmailQueueType',
  INFORM_KAKAO_QUEUE: 'InformKakaoQueueType',
  INFORM_PUSH_QUEUE: 'InformPushQueueType',
  INFORM_SMS_HISTORY: 'InformSmsHistoryType',
  INFORM_SMS_QUEUE: 'InformSmsQueueType',
  INIT_DATA: 'InitDataType',
  LEAVE_REASON: 'LeaveReasonType',
  MILEAGE_HISTORY_MILEAGE: 'MileageHistoryMileageType',
  MILEAGE_HISTORY: 'MileageHistoryType',
  MILEAGE_POLICY: 'MileagePolicyType',
  MILEAGE_REWARD_POLICY: 'MileageRewardPolicyType',
  MILEAGE: 'MileageType',
  MIN_APP_VERSION: 'MinAppVersionType',
  NOTICE_RECEIVER: 'NoticeReceiverType',
  NOTICE: 'NoticeType',
  ORDER_ITEM_ADMIN_NOTE: 'OrderItemAdminNoteType',
  ORDER_ITEM_CANCEL_REASON: 'OrderItemCancelReasonType',
  ORDER_ITEM_CANCEL_REQUEST: 'OrderItemCancelRequestType',
  ORDER_ITEM_COUNT: 'OrderItemCountType',
  ORDER_ITEM_RETURN_REASON: 'OrderItemReturnReasonType',
  ORDER_ITEM: 'OrderItemType',
  ORDER_REFUND_INFO: 'OrderRefundInfoType',
  ORDER: 'OrderType',
  PAGE_INFO_INPUT: 'PageInfoInputType',
  POPULAR_SEARCH_TERM: 'PopularSearchTermType',
  POPUP_BANNER_INFO: 'PopupBannerInfoType',
  PRODUCT_CATEGORY: 'ProductCategoryType',
  PRODUCT_IMAGE_DISPLAY: 'ProductImageDisplayType',
  PRODUCT_IMAGE: 'ProductImageType',
  PRODUCT_OPTION_GROUP: 'ProductOptionGroupType',
  PRODUCT_OPTION_KIND: 'ProductOptionKindType',
  PRODUCT_OPTION_SKU: 'ProductOptionSkuType',
  PRODUCT_OPTION: 'ProductOptionType',
  PRODUCT_OPTION_VALUE: 'ProductOptionValueType',
  PRODUCT_PRODUCT_OPTION: 'ProductProductOptionType',
  PRODUCT_PROMOTION_PRODUCT_PROMOTION: 'ProductPromotionProductPromotionType',
  PRODUCT_PROMOTION: 'ProductPromotionType',
  PRODUCT_QNA_ANSWERED_USER: 'ProductQnaAnsweredUserType',
  PRODUCT_QNA: 'ProductQnaType',
  PRODUCT_STATISTIC_BY_INFLUENCER_COLUMN: 'ProductStatisticByInfluencerColumnType',
  PRODUCT_STATISTIC_BY_SELLER_COLUMN: 'ProductStatisticBySellerColumnType',
  PRODUCT_STATUS_COUNT: 'ProductStatusCountType',
  PRODUCT_SUPPLY: 'ProductSupplyType',
  PRODUCT: 'ProductType',
  PUSH_PUSH: 'PushPushType',
  PUSH: 'PushType',
  PUSH_USER_HISTORY: 'PushUserHistoryType',
  REFUND_INFO: 'RefundInfoType',
  REFUND_SHIPPING_INFO: 'RefundShippingInfoType',
  REMOTE_AREA: 'RemoteAreaType',
  RESTOCK_PRODUCT_OPTION_USER: 'RestockProductOptionUserType',
  RETURN_INFO_IMAGE: 'ReturnInfoImageType',
  RETURN_INFO_REQUEST: 'ReturnInfoRequestType',
  RETURN_INFO: 'ReturnInfoType',
  RETURN_REASON: 'ReturnReasonType',
  REVIEW_GROUP: 'ReviewGroupType',
  REVIEW_IMAGE: 'ReviewImageType',
  REVIEW: 'ReviewType',
  SEARCH_HISTORY: 'SearchHistoryType',
  SELLER_ADDRESS: 'SellerAddressType',
  SELLER_BILLING: 'SellerBillingType',
  SELLER_STATISTIC_COLUMN: 'SellerStatisticColumnType',
  SELLER_STATUS_COUNT: 'SellerStatusCountType',
  SELLER_TEMPLATE_ADDRESS: 'SellerTemplateAddressType',
  SELLER: 'SellerType',
  SELLER_USER_AUTH: 'SellerUserAuthType',
  SELLER_USER: 'SellerUserType',
  SERVER_CONFIG: 'ServerConfigType',
  SERVICE_QNA_ANSWERED_USER: 'ServiceQnaAnsweredUserType',
  SERVICE_QNA_IMAGE: 'ServiceQnaImageType',
  SERVICE_QNA_QUESTION: 'ServiceQnaQuestionType',
  SERVICE_QNA: 'ServiceQnaType',
  SHIPPING_COMPANY: 'ShippingCompanyType',
  SHIPPING_PRESET: 'ShippingPresetType',
  SHIPPING_SHIPPING: 'ShippingShippingType',
  SHIPPING_TRACK: 'ShippingTrackType',
  SHIPPING: 'ShippingType',
  SKU_HISTORY: 'SkuHistoryType',
  SKU: 'SkuType',
  SLIM_BANNER_INFO: 'SlimBannerInfoType',
  SOCIAL_SERVICE_SOCIAL: 'SocialServiceSocialType',
  SOCIAL_SERVICE: 'SocialServiceType',
  STATUS_HISTORY_REQUEST: 'StatusHistoryRequestType',
  STATUS_HISTORY: 'StatusHistoryType',
  STATUS_REQUEST: 'StatusRequestType',
  STATUS: 'StatusType',
  SUB_ROLL_BANNER_INFO: 'SubRollBannerInfoType',
  TERM_STATISTIC_COLUMN: 'TermStatisticColumnType',
  TIMESERIES_VALUE: 'TimeseriesValueType',
  TOP_ROLL_BANNER_INFO: 'TopRollBannerInfoType',
  TOTAL_INFLUENCER_STATISTIC: 'TotalInfluencerStatisticType',
  TOTAL_SALES_STATISTIC: 'TotalSalesStatisticType',
  TOTAL_SELLER_STATISTIC: 'TotalSellerStatisticType',
  TRANSACTION_PAY_APP: 'TransactionPayAppType',
  TRANSACTION_PG_PROVIDER: 'TransactionPgProviderType',
  TRANSACTION: 'TransactionType',
  UPDATED_BRAND: 'UpdatedBrandType',
  UPDATED_PRODUCT: 'UpdatedProductType',
  UPDATED_SELLER: 'UpdatedSellerType',
  USER_CERTIFICATION_CERT: 'UserCertificationCertType',
  USER_CERTIFICATION: 'UserCertificationType',
  USER_COUNT: 'UserCountType',
  USER_DISPLAY_PET: 'UserDisplayPetType',
  USER_INFO_ACCOUNT: 'UserInfoAccountType',
  USER_SUBSCRIPTION: 'UserSubscriptionType',
  USER: 'UserType',
  WEEKLY_BEST_BANNER_INFO: 'WeeklyBestBannerInfoType',
}

export class Modelize {
  static modelize(data: any) {
    if (!data) return {}
    const { __typename } = data
    switch (__typename) {
      case MODEL_TYPE.AD_IMAGE:
        return new ModelAdImage(data)
      case MODEL_TYPE.AD:
        return new ModelAd(data)
      case MODEL_TYPE.AS_INFO:
        return new ModelAsInfo(data)
      case MODEL_TYPE.BANK:
        return new ModelBank(data)
      case MODEL_TYPE.BANNER_IMAGE:
        return new ModelBannerImage(data)
      case MODEL_TYPE.BANNER_INFO:
        return new ModelBannerInfo(data)
      case MODEL_TYPE.BANNER:
        return new ModelBanner(data)
      case MODEL_TYPE.BILLING_ITEM:
        return new ModelBillingItem(data)
      case MODEL_TYPE.BRAND:
        return new ModelBrand(data)
      case MODEL_TYPE.CACHE_PRODUCT:
        return new ModelCacheProduct(data)
      case MODEL_TYPE.CANCEL_REASON:
        return new ModelCancelReason(data)
      case MODEL_TYPE.CANCEL_TRANSACTION:
        return new ModelCancelTransaction(data)
      case MODEL_TYPE.CART_ITEM_HISTORY:
        return new ModelCartItemHistory(data)
      case MODEL_TYPE.CART_ITEM:
        return new ModelCartItem(data)
      case MODEL_TYPE.CATEGORY:
        return new ModelCategory(data)
      case MODEL_TYPE.CHECK_TOKEN_ASSOCIATION:
        return new ModelCheckTokenAssociation(data)
      case MODEL_TYPE.COUPON_BY_PRODUCT:
        return new ModelCouponByProduct(data)
      case MODEL_TYPE.COUPON_GROUP:
        return new ModelCouponGroup(data)
      case MODEL_TYPE.COUPON_KAKAO_TEMPLATE:
        return new ModelCouponKakaoTemplate(data)
      case MODEL_TYPE.COUPON:
        return new ModelCoupon(data)
      case MODEL_TYPE.COVERFLOW_BANNER_INFO:
        return new ModelCoverflowBannerInfo(data)
      case MODEL_TYPE.COVERFLOW:
        return new ModelCoverflow(data)
      case MODEL_TYPE.CUSTOMER_ADDRESS:
        return new ModelCustomerAddress(data)
      case MODEL_TYPE.CUSTOMER_GRADE:
        return new ModelCustomerGrade(data)
      case MODEL_TYPE.CUSTOMER_TEMPLATE_ADDRESS:
        return new ModelCustomerTemplateAddress(data)
      case MODEL_TYPE.DASHBOARD_BILLING:
        return new ModelDashboardBilling(data)
      case MODEL_TYPE.DASHBOARD_BOARD:
        return new ModelDashboardBoard(data)
      case MODEL_TYPE.DASHBOARD_NOTICE:
        return new ModelDashboardNotice(data)
      case MODEL_TYPE.DASHBOARD_ORDER_TIMESERIES:
        return new ModelDashboardOrderTimeseries(data)
      case MODEL_TYPE.DASHBOARD_ORDER:
        return new ModelDashboardOrder(data)
      case MODEL_TYPE.DASHBOARD_PRODUCT:
        return new ModelDashboardProduct(data)
      case MODEL_TYPE.DASHBOARD:
        return new ModelDashboard(data)
      case MODEL_TYPE.DASHBOARD_USER:
        return new ModelDashboardUser(data)
      case MODEL_TYPE.DEFAULT_RETURN_CHANGE_GUIDE:
        return new ModelDefaultReturnChangeGuide(data)
      case MODEL_TYPE.DESIGNERS_BANNER_INFO:
        return new ModelDesignersBannerInfo(data)
      case MODEL_TYPE.DEVICE:
        return new ModelDevice(data)
      case MODEL_TYPE.DISPLAY_COLLECTION:
        return new ModelDisplayCollection(data)
      case MODEL_TYPE.DISPLAY_PRODUCT_INFO:
        return new ModelDisplayProductInfo(data)
      case MODEL_TYPE.EXCEL_EXPORT_REQUEST:
        return new ModelExcelExportRequest(data)
      case MODEL_TYPE.FAQ:
        return new ModelFaq(data)
      case MODEL_TYPE.FITPET_LINK:
        return new ModelFitpetLink(data)
      case MODEL_TYPE.GNB:
        return new ModelGnb(data)
      case MODEL_TYPE.HOME:
        return new ModelHome(data)
      case MODEL_TYPE.INACTIVE_USER:
        return new ModelInactiveUser(data)
      case MODEL_TYPE.INFLUENCER_ADDRESS:
        return new ModelInfluencerAddress(data)
      case MODEL_TYPE.INFLUENCER_STATISTIC_COLUMN:
        return new ModelInfluencerStatisticColumn(data)
      case MODEL_TYPE.INFLUENCER_STATUS_COUNT:
        return new ModelInfluencerStatusCount(data)
      case MODEL_TYPE.INFLUENCER:
        return new ModelInfluencer(data)
      case MODEL_TYPE.INFORM_EMAIL_ATTACHMENT:
        return new ModelInformEmailAttachment(data)
      case MODEL_TYPE.INFORM_EMAIL_QUEUE:
        return new ModelInformEmailQueue(data)
      case MODEL_TYPE.INFORM_KAKAO_QUEUE:
        return new ModelInformKakaoQueue(data)
      case MODEL_TYPE.INFORM_PUSH_QUEUE:
        return new ModelInformPushQueue(data)
      case MODEL_TYPE.INFORM_SMS_HISTORY:
        return new ModelInformSmsHistory(data)
      case MODEL_TYPE.INFORM_SMS_QUEUE:
        return new ModelInformSmsQueue(data)
      case MODEL_TYPE.INIT_DATA:
        return new ModelInitData(data)
      case MODEL_TYPE.LEAVE_REASON:
        return new ModelLeaveReason(data)
      case MODEL_TYPE.MILEAGE_HISTORY:
        return new ModelMileageHistory(data)
      case MODEL_TYPE.MILEAGE_POLICY:
        return new ModelMileagePolicy(data)
      case MODEL_TYPE.MILEAGE_REWARD_POLICY:
        return new ModelMileageRewardPolicy(data)
      case MODEL_TYPE.MILEAGE:
        return new ModelMileage(data)
      case MODEL_TYPE.MIN_APP_VERSION:
        return new ModelMinAppVersion(data)
      case MODEL_TYPE.NOTICE:
        return new ModelNotice(data)
      case MODEL_TYPE.ORDER_ITEM_ADMIN_NOTE:
        return new ModelOrderItemAdminNote(data)
      case MODEL_TYPE.ORDER_ITEM_CANCEL_REASON:
        return new ModelOrderItemCancelReason(data)
      case MODEL_TYPE.ORDER_ITEM_COUNT:
        return new ModelOrderItemCount(data)
      case MODEL_TYPE.ORDER_ITEM_RETURN_REASON:
        return new ModelOrderItemReturnReason(data)
      case MODEL_TYPE.ORDER_ITEM:
        return new ModelOrderItem(data)
      case MODEL_TYPE.ORDER_REFUND_INFO:
        return new ModelOrderRefundInfo(data)
      case MODEL_TYPE.ORDER:
        return new ModelOrder(data)
      case MODEL_TYPE.POPULAR_SEARCH_TERM:
        return new ModelPopularSearchTerm(data)
      case MODEL_TYPE.POPUP_BANNER_INFO:
        return new ModelPopupBannerInfo(data)
      case MODEL_TYPE.PRODUCT_CATEGORY:
        return new ModelProductCategory(data)
      case MODEL_TYPE.PRODUCT_IMAGE:
        return new ModelProductImage(data)
      case MODEL_TYPE.PRODUCT_OPTION_GROUP:
        return new ModelProductOptionGroup(data)
      case MODEL_TYPE.PRODUCT_OPTION_KIND:
        return new ModelProductOptionKind(data)
      case MODEL_TYPE.PRODUCT_OPTION_SKU:
        return new ModelProductOptionSku(data)
      case MODEL_TYPE.PRODUCT_OPTION:
        return new ModelProductOption(data)
      case MODEL_TYPE.PRODUCT_OPTION_VALUE:
        return new ModelProductOptionValue(data)
      case MODEL_TYPE.PRODUCT_PROMOTION:
        return new ModelProductPromotion(data)
      case MODEL_TYPE.PRODUCT_QNA:
        return new ModelProductQna(data)
      case MODEL_TYPE.PRODUCT_STATISTIC_BY_INFLUENCER_COLUMN:
        return new ModelProductStatisticByInfluencerColumn(data)
      case MODEL_TYPE.PRODUCT_STATISTIC_BY_SELLER_COLUMN:
        return new ModelProductStatisticBySellerColumn(data)
      case MODEL_TYPE.PRODUCT_STATUS_COUNT:
        return new ModelProductStatusCount(data)
      case MODEL_TYPE.PRODUCT:
        return new ModelProduct(data)
      case MODEL_TYPE.PUSH:
        return new ModelPush(data)
      case MODEL_TYPE.PUSH_USER_HISTORY:
        return new ModelPushUserHistory(data)
      case MODEL_TYPE.REFUND_INFO:
        return new ModelRefundInfo(data)
      case MODEL_TYPE.REFUND_SHIPPING_INFO:
        return new ModelRefundShippingInfo(data)
      case MODEL_TYPE.REMOTE_AREA:
        return new ModelRemoteArea(data)
      case MODEL_TYPE.RESTOCK_PRODUCT_OPTION_USER:
        return new ModelRestockProductOptionUser(data)
      case MODEL_TYPE.RETURN_INFO_IMAGE:
        return new ModelReturnInfoImage(data)
      case MODEL_TYPE.RETURN_INFO:
        return new ModelReturnInfo(data)
      case MODEL_TYPE.RETURN_REASON:
        return new ModelReturnReason(data)
      case MODEL_TYPE.REVIEW_GROUP:
        return new ModelReviewGroup(data)
      case MODEL_TYPE.REVIEW_IMAGE:
        return new ModelReviewImage(data)
      case MODEL_TYPE.REVIEW:
        return new ModelReview(data)
      case MODEL_TYPE.SEARCH_HISTORY:
        return new ModelSearchHistory(data)
      case MODEL_TYPE.SELLER_ADDRESS:
        return new ModelSellerAddress(data)
      case MODEL_TYPE.SELLER_BILLING:
        return new ModelSellerBilling(data)
      case MODEL_TYPE.SELLER_STATISTIC_COLUMN:
        return new ModelSellerStatisticColumn(data)
      case MODEL_TYPE.SELLER_STATUS_COUNT:
        return new ModelSellerStatusCount(data)
      case MODEL_TYPE.SELLER_TEMPLATE_ADDRESS:
        return new ModelSellerTemplateAddress(data)
      case MODEL_TYPE.SELLER:
        return new ModelSeller(data)
      case MODEL_TYPE.SELLER_USER:
        return new ModelSellerUser(data)
      case MODEL_TYPE.SERVER_CONFIG:
        return new ModelServerConfig(data)
      case MODEL_TYPE.SERVICE_QNA_IMAGE:
        return new ModelServiceQnaImage(data)
      case MODEL_TYPE.SERVICE_QNA:
        return new ModelServiceQna(data)
      case MODEL_TYPE.SHIPPING_COMPANY:
        return new ModelShippingCompany(data)
      case MODEL_TYPE.SHIPPING_PRESET:
        return new ModelShippingPreset(data)
      case MODEL_TYPE.SHIPPING_TRACK:
        return new ModelShippingTrack(data)
      case MODEL_TYPE.SHIPPING:
        return new ModelShipping(data)
      case MODEL_TYPE.SKU_HISTORY:
        return new ModelSkuHistory(data)
      case MODEL_TYPE.SKU:
        return new ModelSku(data)
      case MODEL_TYPE.SLIM_BANNER_INFO:
        return new ModelSlimBannerInfo(data)
      case MODEL_TYPE.SOCIAL_SERVICE:
        return new ModelSocialService(data)
      case MODEL_TYPE.STATUS_HISTORY:
        return new ModelStatusHistory(data)
      case MODEL_TYPE.STATUS:
        return new ModelStatus(data)
      case MODEL_TYPE.SUB_ROLL_BANNER_INFO:
        return new ModelSubRollBannerInfo(data)
      case MODEL_TYPE.TERM_STATISTIC_COLUMN:
        return new ModelTermStatisticColumn(data)
      case MODEL_TYPE.TIMESERIES_VALUE:
        return new ModelTimeseriesValue(data)
      case MODEL_TYPE.TOP_ROLL_BANNER_INFO:
        return new ModelTopRollBannerInfo(data)
      case MODEL_TYPE.TOTAL_INFLUENCER_STATISTIC:
        return new ModelTotalInfluencerStatistic(data)
      case MODEL_TYPE.TOTAL_SALES_STATISTIC:
        return new ModelTotalSalesStatistic(data)
      case MODEL_TYPE.TOTAL_SELLER_STATISTIC:
        return new ModelTotalSellerStatistic(data)
      case MODEL_TYPE.TRANSACTION:
        return new ModelTransaction(data)
      case MODEL_TYPE.UPDATED_BRAND:
        return new ModelUpdatedBrand(data)
      case MODEL_TYPE.UPDATED_PRODUCT:
        return new ModelUpdatedProduct(data)
      case MODEL_TYPE.UPDATED_SELLER:
        return new ModelUpdatedSeller(data)
      case MODEL_TYPE.USER_CERTIFICATION:
        return new ModelUserCertification(data)
      case MODEL_TYPE.USER_COUNT:
        return new ModelUserCount(data)
      case MODEL_TYPE.USER_INFO_ACCOUNT:
        return new ModelUserInfoAccount(data)
      case MODEL_TYPE.USER_SUBSCRIPTION:
        return new ModelUserSubscription(data)
      case MODEL_TYPE.USER:
        return new ModelUser(data)
      case MODEL_TYPE.WEEKLY_BEST_BANNER_INFO:
        return new ModelWeeklyBestBannerInfo(data)
      default:
        return new ModelBase(data)
    }
  }
}
