import { FC, useState } from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { ImageAsset, TextLegacy } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { PathToLocation } from '@/constants/location.enum'
import { MembershipStatusEnum } from '@/constants/membership-status.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { usePartnersPageEventTracker } from '@/containers/event-tracker/partners.event-tracker'
import { useCustomRouter } from '@/containers/hooks'
import { IModelUser } from '@/containers/models/modelUser'
import { UserContainer } from '@/containers/users/UserContainer'
import { Bridge } from '@/utils/bridge/bridge'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import appBridgeProvider, { isAppStorageDisabled } from '@/utils/utilBridge'
import { isInApp } from '@/utils/utilCommon'

type MenuItemType = {
  title: string
  subMenus: {
    title: string
    link: string
    subTitle?: string
  }[]
}

type ListAccordionMenuProps = {
  menus: MenuItemType[]
  user: IModelUser
}

const ListAccordionMenu: FC<ListAccordionMenuProps> = ({ menus, user }) => {
  const { likedCount } = user
  return (
    <>
      {menus.map((menu, index) => {
        return <RenderMenuItem key={index} item={menu} likedCount={likedCount} />
      })}
    </>
  )
}

const RenderMenuItem = ({ likedCount, item }: { item: MenuItemType; likedCount: number }) => {
  const [open, toggleOpen] = useState<boolean>(true)
  const tracker = useTracker()
  const { basePath, asPath, pathname } = useCustomRouter()
  const currentPath = `${basePath}${asPath}`
  const { trackClickMypagePartnersEvent } = usePartnersPageEventTracker()

  const { title, subMenus } = item
  const { me } = UserContainer.useContainer()
  return (
    <>
      <MenuRow
        justify="space-between"
        noborder={0}
        onClick={() => {
          if (!open) {
            let enventName
            if (title === '핏펫몰') enventName = FitpetMallEventEnum.ClickMypageFitpetmallSection
            if (title === '병원찾기') enventName = FitpetMallEventEnum.ClickMypageHospitalSection
            if (enventName) tracker.triggerCustomEvent(enventName)
          }
          toggleOpen(!open)
        }}
        paddingleft={20}
      >
        <Col>
          <TextLegacy size={{ xs: 14, md: 16 }} weight="bold">
            {title}
          </TextLegacy>
        </Col>
        <Col>
          <ImageAsset name={open ? 'chevron-up' : 'chevron-down'} />
        </Col>
      </MenuRow>
      {open &&
        subMenus.map(({ title, link, subTitle }, index) => {
          if (isAppStorageDisabled) {
            if (link === ROUTES.MYPAGE.LIKE_LIST || link === ROUTES.MYPAGE.RECENTLY_LIST) {
              return null
            }
          }

          let _link = link
          // 최근 본 상품 페이지 이동 시 현재 페이지 Path 쿼리스트링으로 넣어주어 트래커에 path 전달
          if (link === ROUTES.MYPAGE.RECENTLY_LIST) {
            _link = `${link}?${QueryStringKeyEnum.PrevPath}=${currentPath}`
          }
          // 멤버십 미가입 유저는 click시 안내페이지로 이동
          if (link === ROUTES.MEMBERSHIP.INDEX && me?.membershipStatus === MembershipStatusEnum.None) {
            _link = `${ROUTES.MEMBERSHIP.INTRO}?${QueryStringKeyEnum.Location}=${PathToLocation[pathname]}`
          }

          return (
            <LinkButton
              key={index}
              url={_link}
              onClick={() => {
                if (link.includes('/partners')) {
                  trackClickMypagePartnersEvent({ buttonText: subTitle || '' })
                }
                if (link === ROUTES.MYPAGE.RECENTLY_LIST) {
                  tracker.triggerCustomEvent(FitpetMallEventEnum.ClickMypageRecentproduct)
                }
                if (link === ROUTES.MYPAGE.LIKE_LIST) {
                  tracker.triggerCustomEvent(FitpetMallEventEnum.ClickMypageLikeproduct)
                }

                /**
                 * 인앱 환경에서 "핏펫 어헤드 > 검사 내역 삭제" 터치 시, goView
                 */
                if (isInApp() && title === '검사 내역 삭제') {
                  appBridgeProvider((bridge: Bridge) => bridge.goView({ type: 'inspectionDeleteHistory' }))
                }
              }}
            >
              <MenuRow justify="space-between" paddingleft={34}>
                <div>
                  <TextLegacy size={{ xs: 14, md: 16 }}>{title}</TextLegacy>
                  {link === ROUTES.MYPAGE.LIKE_LIST && (
                    <TextLegacy size={{ xs: 14, md: 16 }} color="blue500" weight="bold" style={{ marginLeft: 7 }}>
                      {likedCount}
                    </TextLegacy>
                  )}
                  {subTitle && (
                    <TextLegacy size={{ xs: 14, md: 16 }} color="blue500" weight="bold" style={{ marginLeft: 7 }}>
                      {subTitle}
                    </TextLegacy>
                  )}
                </div>
              </MenuRow>
            </LinkButton>
          )
        })}
    </>
  )
}

const MenuRow = styled(Row)<{ noborder?: number; paddingleft: number }>`
  padding: 20px;
  padding-left: ${({ paddingleft }) => `${paddingleft}px`};
  cursor: pointer;
  border-bottom: ${({ noborder }) => (noborder ? 'none' : `1px solid ${GlobalColorEnum.Gray70}`)};
`

export default ListAccordionMenu
